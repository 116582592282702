import { FC } from "react";
import { Box, Link, useTheme } from "@mui/material";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";

interface ChatItemContentFileProps extends ChatItemContentBaseProps {}

export const ChatItemContentFile: FC<ChatItemContentFileProps> = ({ content, isLoggedUser }) => {
  const { palette } = useTheme();
  const segments: Array<string> = content.url.split("/");
  const fileName = segments[segments.length - 1];
  return (
    <Box>
      <Link
        href={content.url}
        download
        sx={{
          color: !isLoggedUser ? palette.background.paper : palette.primary.main
        }}
      >
        {fileName}
      </Link>
    </Box>
  );
};
