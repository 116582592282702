import { FC } from "react";
import { Conversation } from "../domain/conversation";
import { Badge, Grid2, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import {
  Chip,
  formatDate,
  formatTime,
  ParagraphSmall,
  ParagraphSmallBold
} from "@likemagic-tech/sv-magic-library";
import { useProperty } from "src/hooks/use-property";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { channelIconMap, getMessageText, mapMessageConditionColors } from "../utils/utils";
import { getI18nSelectedLanguage } from "src/utils/language";

export const ConversationRow: FC<{
  data: Conversation;
  onSelect: (conversationId: string) => void;
  isSelected: boolean;
}> = ({ data, onSelect, isSelected }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const lastMessage = data?.lastMessage?.[0];
  const ChannelIcon = channelIconMap(lastMessage?.channel?.platform);
  const { selectedProperty, getProperty } = useProperty();
  const isMobile = useIsMobile();
  const language = getI18nSelectedLanguage();

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={data.conversationId}
      onClick={() => onSelect(data.conversationId)}
      sx={{
        cursor: "pointer",
        backgroundColor: isSelected ? palette.background.default : null
      }}
    >
      <TableCell />
      <TableCell
        sx={{
          minWidth: {
            xs: 200,
            md: 350,
            lg: 400
          }
        }}
      >
        <Grid2
          container
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            gap: 2.5
          }}
        >
          <Badge
            sx={{
              "& .MuiBadge-badge": { top: -2, right: -2, backgroundColor: palette.primary.main }
            }}
            badgeContent={data.numberOfUnreadMessages}
          >
            <ChannelIcon />
          </Badge>
          <Grid2>
            <ParagraphSmallBold
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "35vw" : "25vw"
              }}
              pb={0.5}
            >
              {data?.guest?.displayName}
            </ParagraphSmallBold>
            <ParagraphSmall
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "35vw" : "25vw"
              }}
              color={palette.text.secondary}
            >
              {getMessageText(lastMessage)}
            </ParagraphSmall>
          </Grid2>
        </Grid2>
      </TableCell>
      {!isMobile && (
        <TableCell>
          <ParagraphSmall>
            {data?.pmsPropertyId && getProperty(data?.pmsPropertyId)
              ? getProperty(data?.pmsPropertyId)?.details?.name
              : t("labels__no_property")}
          </ParagraphSmall>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell>
          <ParagraphSmall>{data?.assignee?.displayName ?? t("labels__unassigned")}</ParagraphSmall>
        </TableCell>
      )}
      {!isMobile && (
        <TableCell>
          <Chip
            color={mapMessageConditionColors(data.status)}
            label={t(`labels__conversation_status_${data.status}`)}
          />
        </TableCell>
      )}
      <TableCell>
        <Grid2 container direction="column">
          <ParagraphSmall>
            {formatDate(data?.lastReceivedMessage, language, selectedProperty?.details.timeZone)}
          </ParagraphSmall>
          <ParagraphSmall sx={{ color: palette.text.secondary }}>
            {formatTime(data?.lastReceivedMessage, language, selectedProperty?.details.timeZone)}
          </ParagraphSmall>
        </Grid2>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
