import { FC, MutableRefObject, useEffect, useRef } from "react";

export const AlwaysScrollToBottom: FC<{ id: string }> = ({ id }) => {
  const elementRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  useEffect(() => {
    //We need to wait of the images to be loaded.
    setTimeout(() => {
      elementRef?.current?.scrollIntoView({ inline: "end", behavior: "smooth" });
    }, 500);
  }, [id]);
  // We need to trigger scroll on each id change
  return <div ref={elementRef} />;
};
