import {
  Caption,
  Checkbox,
  Chip,
  Heading3,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  ParagraphSmallBold,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { Box, Grid2, Paper } from "@mui/material";
import React, { FC, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import PersonInfo from "./person-info";
import { GuestPreferences } from "src/domain/reservation-table-dto";
import {
  GuestType,
  guestTypeLabelsMap
} from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { IdCheckStatus } from "src/domain/reservation";
import { DisplayPreference } from "../overview/rows-for-table-guest-overview/display-preference";
import { useUnits } from "src/hooks/use-unit-by-id";
import { GuestNote, PersonGender } from "src/domain/person";
import { MembershipAccount } from "../../../../../graphql/generated/graphql";
import { ProfileActions } from "src/features/profiles/profile-drawer/profile-actions";
import {
  genderPronounsLabel,
  GuestInsightInfoTypes
} from "./reservation-details-guest-insight-utils";

interface PersonInfoType {
  name: string;
  role: GuestType;
  gender?: PersonGender;
  recurring?: boolean;
  isBlacklisted: boolean;
  id: number;
  account?: boolean;
  guestNotes?: GuestNote[];
  preferencesText?: string;
  preferences?: GuestPreferences;
  contact: Array<any>;
  legal?: Array<string>;
  privateAddress?: Array<string>;
  billingAddress?: Array<string>;
  verified?: IdCheckStatus;
  emergencyEvacuationHelpNeeded?: boolean;
  emergencyEvacuationHelpNotes?: string;
  enrolledInLoyaltyProgram?: boolean;
  frequentFlyerPrograms?: MembershipAccount[];
  licensePlate?: string;
}

interface PersonCardProps {
  personInfo: PersonInfoType;
  propertyId: string;
}

const PersonCard: FC<PersonCardProps> = ({ personInfo, propertyId }) => {
  const theme = useTheme();
  const { t, exists } = useTranslationWrapper();
  const { data: units } = useUnits({
    propertyId
  });
  const { features } = usePropertyConfig({
    propertyId
  });

  const preferencesNullSafe = useMemo(() => personInfo.preferences || {}, [personInfo.preferences]);

  const PreferencesComponent = useMemo(() => {
    return (
      <>
        {Object.keys(preferencesNullSafe).length > 0 ? (
          Object.keys(preferencesNullSafe).map((preference) => (
            <DisplayPreference
              preference={preference}
              propertyId={propertyId}
              preferences={preferencesNullSafe}
              key={preference}
              units={units ?? []}
            />
          ))
        ) : (
          <Grid2
            sx={{
              paddingTop: 1
            }}
          >
            <Paragraph sx={{ color: theme.palette.text.primary }}>
              {t("labels__no__preferences__stored")}
            </Paragraph>
          </Grid2>
        )}
      </>
    );
  }, [preferencesNullSafe, propertyId, t, theme.palette.text.primary, units]);

  const PreferencesTextComponent = useMemo(() => {
    return (
      <>
        {personInfo.preferencesText && (
          <Paragraph sx={{ color: theme.palette.text.primary, whiteSpace: "pre-wrap" }}>
            {personInfo.preferencesText}
          </Paragraph>
        )}
        {personInfo.guestNotes &&
          personInfo?.guestNotes?.map((note) => (
            <Grid2
              size={{ xs: 11 }}
              key={note.pmsId}
              sx={{
                py: 1
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`
                }}
                elevation={0}
              >
                <Grid2 container direction="column">
                  <Grid2
                    sx={{
                      pb: 1
                    }}
                  >
                    <Grid2
                      container
                      direction="row"
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      {note.title && (
                        <Grid2>
                          <ParagraphBold>{note.title}</ParagraphBold>
                        </Grid2>
                      )}
                      <Grid2>
                        <Chip
                          label={t(`labels__notes_category_${note.pmsProfileNoteCategoryId}`)}
                          size="large"
                          color="info"
                        />
                      </Grid2>
                    </Grid2>
                  </Grid2>
                  <Grid2>
                    <Paragraph>{note.text}</Paragraph>
                  </Grid2>
                </Grid2>
              </Paper>
            </Grid2>
          ))}
        {(!personInfo?.guestNotes || personInfo?.guestNotes?.length === 0) &&
          !personInfo.preferencesText && (
            <Paragraph sx={{ color: theme.palette.text.primary, whiteSpace: "pre-wrap" }}>
              {t("labels__no__preferences_text__stored")}
            </Paragraph>
          )}
      </>
    );
  }, [personInfo, theme, t]);

  return (
    <Paper elevation={0}>
      <Grid2
        container
        sx={{
          p: 3
        }}
      >
        <Grid2 container sx={{ width: "100%" }}>
          <Grid2 container sx={{ width: "100%" }} alignItems="center">
            <Grid2 size={{ xs: 11 }}>
              <Grid2 container direction="row" alignItems="center">
                {personInfo?.gender && (
                  <ParagraphSmallBold color={theme.palette.text.primary} paddingRight={1}>
                    {genderPronounsLabel(personInfo.gender, t)}
                  </ParagraphSmallBold>
                )}
                <Heading3 sx={{ color: theme.palette.secondary.main, paddingRight: 3 }}>
                  {personInfo.name}
                </Heading3>
                <Chip
                  color="default"
                  size="small"
                  label={t(`${guestTypeLabelsMap[personInfo.role]}`)}
                  sx={{ marginRight: 1 }}
                />
                {(personInfo.role === GuestType.SAME_GUEST ||
                  personInfo.role === GuestType.PRIMARY_GUEST) && (
                  <Chip
                    size="small"
                    color="info"
                    label={
                      personInfo.recurring
                        ? t("labels__reservation__details__overview__guests__recurring")
                        : t("labels__reservation__details__overview__guests__new")
                    }
                    sx={{ marginRight: 1 }}
                  />
                )}
                {personInfo?.isBlacklisted && (
                  <Chip size="small" color="error" label={t("labels__profile_blacklisted")} />
                )}
              </Grid2>
            </Grid2>
            {personInfo.id > 0 && (
              <Grid2 size={{ xs: 1 }}>
                <Grid2
                  container
                  sx={{
                    justifyContent: "end"
                  }}
                >
                  <ProfileActions
                    isBlacklisted={personInfo?.isBlacklisted}
                    profileId={personInfo.id}
                    menuPlacement="bottom-start"
                  />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
          <Grid2
            container
            rowSpacing={1}
            sx={{
              paddingTop: 3
            }}
          >
            {(personInfo?.contact || features?.tfeFeatureEnabled) && (
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{
                  paddingBottom: personInfo.role !== GuestType.TRAVEL_BUDDY ? 4 : 0
                }}
              >
                {personInfo?.contact && (
                  <PersonInfo
                    guestInsightInfoType={GuestInsightInfoTypes.CONTACT}
                    title={t("labels__guest__insight__CONTACT")}
                    //edit
                    show={personInfo.contact.length > 0}
                    info={personInfo.contact}
                  />
                )}

                <Grid2
                  container
                  direction="column"
                  sx={{
                    gap: 2,
                    mt: 2
                  }}
                >
                  {features?.licensePlateCheckinWizardEnabled && personInfo.licensePlate && (
                    <Box sx={{ display: "flex", gap: 0.5 }}>
                      <Caption sx={{ display: "block" }}>{t("labels__license_plate")}:</Caption>
                      <ParagraphSmall>{personInfo.licensePlate}</ParagraphSmall>
                    </Box>
                  )}
                  {features?.tfeFeatureEnabled && !!personInfo.frequentFlyerPrograms?.length && (
                    <Grid2
                      container
                      sx={{
                        width: "100%"
                      }}
                    >
                      <Grid2
                        size={{ xs: 12 }}
                        sx={{
                          flexDirection: "column"
                        }}
                      >
                        <Caption sx={{ display: "block" }}>
                          {`${t("labels__frequent_flyer_program")} & ${t(
                            "labels__membership_number"
                          )}:`}
                        </Caption>
                        {personInfo.frequentFlyerPrograms?.map((program) => {
                          return (
                            <ParagraphSmall>
                              {program?.membershipTypeCode}: {program.membershipId}
                            </ParagraphSmall>
                          );
                        })}
                      </Grid2>
                    </Grid2>
                  )}
                  {features?.tfeFeatureEnabled && features.loyaltyProgramEnabled && (
                    <Grid2
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Checkbox
                        id={"enrolledInLoyaltyProgram"}
                        checked={!!personInfo.enrolledInLoyaltyProgram}
                        onChange={() => {}}
                        disabled
                        title={
                          exists(`labels__enrolled_loyalty_program_${propertyId}`)
                            ? t(`labels__enrolled_loyalty_program_${propertyId}`)
                            : t("labels__enrolled_loyalty_program")
                        }
                      />
                    </Grid2>
                  )}
                  {features?.tfeFeatureEnabled && (
                    <Grid2
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Checkbox
                        id={"emergencyEvacuationHelpNeeded"}
                        checked={!!personInfo.emergencyEvacuationHelpNeeded}
                        onChange={() => {}}
                        disabled
                        title={t("labels__special_assistance")}
                        text={
                          personInfo.emergencyEvacuationHelpNotes
                            ? `${t("labels__guest_input")} ${
                                personInfo.emergencyEvacuationHelpNotes
                              }`
                            : t("labels__require_special_assistance")
                        }
                      />
                    </Grid2>
                  )}
                </Grid2>
              </Grid2>
            )}
            {personInfo?.legal && personInfo?.legal?.length > 0 && (
              <Grid2
                size={{ xs: 12, md: 6 }}
                sx={{
                  paddingBottom: 4
                }}
              >
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.LEGAL_DATA}
                  title={t("labels__guest__insight__LEGAL_DATA")}
                  //edit
                  show
                  info={personInfo.legal}
                  verified={
                    personInfo.role === GuestType.PRIMARY_GUEST ||
                    personInfo.role === GuestType.SAME_GUEST
                      ? personInfo.verified
                      : undefined
                  }
                />
              </Grid2>
            )}
            {personInfo?.privateAddress && (
              <Grid2
                size={{ xs: 6 }}
                sx={{
                  paddingBottom: 4
                }}
              >
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.PRIVATE_ADDRESS}
                  title={t("labels__guest__insight__PRIVATE_ADDRESS")}
                  //edit
                  show={
                    personInfo.role !== GuestType.TRAVEL_BUDDY &&
                    personInfo.privateAddress?.length > 0
                  }
                  info={personInfo.privateAddress}
                />
              </Grid2>
            )}
            {personInfo?.billingAddress && (
              <Grid2
                size={{ xs: 6 }}
                sx={{
                  paddingBottom: 4
                }}
              >
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.BILLING_ADDRESS}
                  title={t("labels__guest__insight__BILLING_ADDRESS")}
                  //edit
                  show={
                    personInfo.role !== GuestType.TRAVEL_BUDDY &&
                    personInfo.billingAddress?.length > 0
                  }
                  info={personInfo?.billingAddress}
                />
              </Grid2>
            )}
            <Grid2
              size={{ xs: 6 }}
              sx={{
                paddingBottom: personInfo.role !== GuestType.TRAVEL_BUDDY ? 4 : 0
              }}
            >
              <PersonInfo
                guestInsightInfoType={GuestInsightInfoTypes.PREFERENCES}
                title={t("labels__guest__insight__PREFERENCES")}
                //edit
                //add
                show={personInfo.role !== GuestType.TRAVEL_BUDDY}
                info={PreferencesComponent}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <PersonInfo
                guestInsightInfoType={GuestInsightInfoTypes.NOTES}
                title={t("labels__guest__insight__NOTES")}
                //edit
                //add
                show={personInfo.role !== GuestType.TRAVEL_BUDDY}
                info={PreferencesTextComponent}
              />
            </Grid2>
            {personInfo.account !== null && personInfo.account !== undefined && (
              <Grid2 size={{ xs: 6 }}>
                <PersonInfo
                  guestInsightInfoType={GuestInsightInfoTypes.ACCOUNT}
                  title={t("labels__guest__insight__ACCOUNT")}
                  //edit
                  show={
                    personInfo.role !== GuestType.TRAVEL_BUDDY &&
                    personInfo.account !== null &&
                    personInfo.account !== undefined
                  }
                  info={personInfo.account ? t("labels__yes") : t("labels__no")}
                />
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </Paper>
  );
};

export default PersonCard;
