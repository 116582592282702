import {
  Caption,
  formatDateTime,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { Grid2, IconButton, Tooltip, useTheme } from "@mui/material";
import { FC, useState } from "react";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { DisplayNotificationContact, DisplayResendButton } from "./notification-card-info-utils";
import { NotificationResendModal } from "./notification-resend-modal";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { AllowedActionEnum, SentNotifications } from "src/domain/reservation-table-dto";
import { ReservationDetailsDTO } from "../../../../../../../store/endpoints/reservation-table.endpoints";
import { useConversationSelectId } from "../../../../../../../messaging/use-conversation-select-id";
import { useProperty } from "src/hooks/use-property";
import {
  getI18nSelectedLanguage,
  getTranslatedLanguageName
} from "../../../../../../../utils/language";

export const NotificationCardInfo: FC<{
  notificationInfo: SentNotifications;
  reservation?: ReservationDetailsDTO;
}> = ({ notificationInfo, reservation }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useIsMobile();
  const { openDrawer } = useConversationSelectId();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const handleOpenConversation = (conversationId: string, messageId: string) => {
    openDrawer(conversationId, messageId);
  };

  const handleOpen = () => {
    setOpenModal(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const isResendNotificationAllowed = reservation?.allowedActions?.includes(
    AllowedActionEnum.RESEND_NOTIFICATION
  );

  return (
    <>
      <Grid2
        container
        direction={isMobile ? "column" : "row"}
        sx={{
          p: 3,
          alignItems: isMobile ? "baseline" : "center"
        }}
      >
        <Grid2
          size={{ xs: 3 }}
          sx={{
            paddingBottom: isMobile ? 2 : 0
          }}
        >
          <ParagraphBold>
            {t(`labels__notification__template__${notificationInfo.template}`)}
          </ParagraphBold>
        </Grid2>
        <Grid2
          size={{ xs: isMobile ? 12 : 3 }}
          sx={{
            paddingRight: 2
          }}
        >
          <Grid2 container>
            <Grid2 size={{ xs: 12 }}>
              <DisplayNotificationContact
                channel={notificationInfo.channel}
                recipient={notificationInfo.recipient}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: isMobile ? 3 : 1 }}>
          <Grid2
            container
            sx={{
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Grid2 size={{ xs: 6 }}>
              <Tooltip
                title={getTranslatedLanguageName({
                  code: notificationInfo.language,
                  locale: language
                })}
              >
                <div>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {notificationInfo.language}
                  </ParagraphSmall>
                </div>
              </Tooltip>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <IconButton
                onClick={() => {
                  handleOpenConversation(notificationInfo.conversationId, notificationInfo.id);
                }}
              >
                <Tooltip
                  title={t(
                    notificationInfo.contentLink
                      ? "labels__notification__tab__link"
                      : "labels__notification__tab__link_unavailable"
                  )}
                >
                  <ForwardToInboxIcon
                    color={notificationInfo.contentLink ? "primary" : "disabled"}
                  />
                </Tooltip>
              </IconButton>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={{ xs: isMobile ? 3 : 5 }}>
          <Grid2
            container
            sx={{
              alignItems: "center"
            }}
          >
            <Grid2 size={{ xs: 4 }}>
              <ParagraphSmall>
                {formatDateTime(
                  notificationInfo.timestamp,
                  language,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>
            </Grid2>
            <Grid2 size={{ xs: isMobile ? 0 : 2 }}>
              {!isMobile && (
                <Grid2
                  container
                  sx={{
                    justifyContent: "center"
                  }}
                >
                  <Tooltip
                    title={t("labels__notification__status", {
                      status: t(`labels__notification__status__${notificationInfo.status}`)
                    })}
                  >
                    <div>
                      <Caption sx={{ color: theme.palette.text.primary }}>
                        {t(`labels__notification__status__${notificationInfo.status}`)}
                      </Caption>
                    </div>
                  </Tooltip>
                </Grid2>
              )}
            </Grid2>
            {isResendNotificationAllowed && (
              <Grid2 size={{ xs: isMobile ? 8 : 6 }}>
                <Grid2
                  container
                  sx={{
                    justifyContent: "flex-end"
                  }}
                >
                  <DisplayResendButton handleOpen={handleOpen} />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
      {openModal && (
        <NotificationResendModal
          reservation={reservation}
          onClose={handleClose}
          notification={notificationInfo}
        />
      )}
    </>
  );
};
