import { Autocomplete, TextField } from "@mui/material";
import React, { FC, HTMLAttributes, ReactElement } from "react";
import { AutocompleteOption } from "../autocomplete-component";

export interface MultipleAutocompleteOption {
  id: string;
  typeId?: string;
  label: string;
}
interface MultipleAutocompleteComponentProps {
  label: string;
  value: MultipleAutocompleteOption[] | null;
  options: Array<MultipleAutocompleteOption>;
  onSelected: (value: MultipleAutocompleteOption[]) => void;
  disabled: boolean;
  displayItem?: (props: HTMLAttributes<HTMLElement>, option: AutocompleteOption) => ReactElement;
}

export const MultipleAutocompleteComponent: FC<MultipleAutocompleteComponentProps> = ({
  label,
  value,
  options,
  onSelected,
  disabled,
  displayItem
}) => {
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      sx={{ borderRadius: 2 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableClearable={true}
      options={options}
      filterSelectedOptions
      renderOption={(props, option) => (
        <li {...props} key={option?.label + " " + option?.id}>
          {displayItem ? displayItem(props, option) : option?.label}
        </li>
      )}
      onChange={(_, newValue) => {
        onSelected(newValue);
      }}
      value={value ?? undefined}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .Mui-disabled": { opacity: "0.8!important" }
          }}
          variant="outlined"
          {...params}
          label={label}
          slotProps={{
            inputLabel: {
              sx: {
                opacity: 0.5
              }
            }
          }}
        />
      )}
    />
  );
};
