import { Chip } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import { FC, ReactElement, useMemo } from "react";
import { GuestPreferences } from "src/domain/reservation-table-dto";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { getCategoryColor } from "./preferences-in-row-preview";
import { PreferenceLabel } from "./preference-label";
import { PreferenceTypes } from "src/domain/reservation";
import { Unit } from "src/domain/Unit";
import { useGuestPreference } from "../../../../../../hooks/use-guest-preference";

interface DisplayPreferenceProps {
  propertyId: string;
  preferences?: GuestPreferences;
  preference: any;
  preferencesText?: string;
  hide?: boolean;
  units?: Unit[];
  icon?: any;
  onIconClick?: any;
}

export const DisplayPreference: FC<DisplayPreferenceProps> = ({
  propertyId,
  preferences,
  preference,
  preferencesText,
  hide,
  units,
  icon,
  onIconClick
}) => {
  const { t } = useTranslationWrapper();
  const data = useGuestPreference({ propertyId });
  const { palette } = useTheme();

  const preferenceLabel: Array<ReactElement> = useMemo(() => {
    if (preference === PreferenceTypes.SPECIFIC_UNIT && units) {
      if (Array.isArray(preferences && preferences[preference])) {
        return ((preferences && preferences[preference]) || []).map((item: GuestPreferences) => {
          return (
            <PreferenceLabel
              key={item?.unitId}
              preference={item}
              preferenceType={preference}
              units={units}
            />
          );
        });
      }
    } else {
      return [
        <>
          {t(`labels__preference_type_${preference}`, {
            note: preferencesText ?? ""
          })}
        </>
      ];
    }
  }, [preference, preferences, units, t, preferencesText]);

  return (
    <>
      {preferenceLabel?.map((item: any, index: number) => (
        <Grid2
          key={`${preference}-${index}-main-container-item`}
          sx={{
            display: hide ? "none" : "flex",
            m: 0.5,
            maxWidth: `calc(100% - 16px)`
          }}
        >
          <Chip
            label={item}
            sx={{
              color: palette.text.primary,
              backgroundColor: getCategoryColor(preference, data)
            }}
            rightIcon={icon}
            onRightIconClick={onIconClick}
            size="small"
          />
        </Grid2>
      ))}
    </>
  );
};
