import { Grid2, useTheme } from "@mui/material";
import React, { FC, useCallback, useMemo, FocusEvent } from "react";
import { Button, Input, NativeSelect, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import AddIcon from "@mui/icons-material/Add";
import { useProperty } from "../../../../../../hooks/use-property";
import { useMembershipTypes } from "../../../../../../hooks/use-membership-types";
import { MemberShipClassEnum } from "../../../../../../graphql/generated/graphql";
import { FrequentFlyerProgram } from "../../../../../../domain/registration-card";
import CloseIcon from "@mui/icons-material/Close";

export const emptyFrequentFlyerProgram: () => FrequentFlyerProgram = () => ({
  frequentFlyerProgram: "",
  frequentFlyerNumber: "",
  existing: false
});

export interface ManualCheckinFrequentFlyerFormProps {
  push: (value: FrequentFlyerProgram) => void;
  remove: (index: number) => void;
  form: any;
  handleBlur: (e: FocusEvent<any>) => void;
  disabled?: boolean;
}

export const ManualCheckinFrequentFlyerForm: FC<ManualCheckinFrequentFlyerFormProps> = ({
  push,
  form,
  handleBlur,
  disabled,
  remove
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const theme = useTheme();

  const { membershipTypes } = useMembershipTypes(
    selectedProperty?.propertyId ?? "",
    MemberShipClassEnum.FrequentFlyer
  );

  const membershipTypesOptions = useMemo(() => {
    return membershipTypes?.map((item) => {
      return {
        label: item.name,
        value: item.code,
        disabled: form.values.mainGuest.frequentFlyerPrograms.some(
          (frequentFlyerProgram: FrequentFlyerProgram) =>
            frequentFlyerProgram.frequentFlyerProgram === item.code
        )
      };
    });
  }, [form.values.mainGuest.frequentFlyerPrograms, membershipTypes]);

  const addFrequentFlyerProgram = useCallback(() => push(emptyFrequentFlyerProgram()), [push]);

  const canAddFrequentFlyer = membershipTypesOptions?.filter((option) => !option.disabled)?.length;

  return (
    <>
      {form.values.mainGuest?.frequentFlyerPrograms?.map((_: any, index: number) => {
        return (
          <React.Fragment key={`frequentFlyerProgram-${index}`}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <NativeSelect
                key={form.values.mainGuest.frequentFlyerPrograms[index].frequentFlyerProgram}
                items={membershipTypesOptions ?? []}
                id={`mainGuest.frequentFlyerPrograms.[${index}].frequentFlyerProgram`}
                name={`mainGuest.frequentFlyerPrograms.[${index}].frequentFlyerProgram`}
                value={
                  form.values.mainGuest.frequentFlyerPrograms[index].frequentFlyerProgram || ""
                }
                label={t("labels__wizard_frequent_flyer_program")}
                onChange={form.handleChange}
                onBlur={handleBlur}
                variant="standard"
                disabled={disabled || form.values.mainGuest.frequentFlyerPrograms[index].existing}
                labelShrink={
                  form.values.mainGuest.frequentFlyerPrograms[index].existing ||
                  form.values.mainGuest.frequentFlyerPrograms[index].frequentFlyerProgram
                }
              />
            </Grid2>
            <Grid2
              size={{ xs: 12, md: 6 }}
              sx={{
                display: "flex"
              }}
            >
              <Input
                name={`mainGuest.frequentFlyerPrograms.[${index}].frequentFlyerNumber`}
                id={`mainGuest.frequentFlyerPrograms.[${index}].frequentFlyerNumber`}
                value={form.values.mainGuest.frequentFlyerPrograms[index].frequentFlyerNumber || ""}
                label={t("labels__wizard_frequent_flyer_number")}
                error={
                  form.touched?.mainGuest?.frequentFlyerPrograms?.length &&
                  form.touched?.mainGuest?.frequentFlyerPrograms[index]?.frequentFlyerNumber &&
                  form.errors?.mainGuest?.frequentFlyerPrograms?.length &&
                  form.errors?.mainGuest?.frequentFlyerPrograms[index]?.frequentFlyerNumber
                }
                onChange={form.handleChange}
                onBlur={handleBlur}
                variant="standard"
                autoComplete="nope"
                disabled={
                  disabled ||
                  !form.values.mainGuest.frequentFlyerPrograms[index].frequentFlyerProgram ||
                  form.values.mainGuest.frequentFlyerPrograms[index].existing
                }
              />
              {!form.values.mainGuest.frequentFlyerPrograms[index].existing && index !== 0 && (
                <Button
                  variant="ghost"
                  onClick={() => remove(index)}
                  startIcon={<CloseIcon />}
                  disabled={disabled}
                >
                  <Paragraph>{t("buttons__wizard_remove")}</Paragraph>
                </Button>
              )}
            </Grid2>
          </React.Fragment>
        );
      })}
      {canAddFrequentFlyer &&
      form.values.mainGuest.frequentFlyerPrograms.length < membershipTypesOptions.length ? (
        <Grid2 size={{ xs: 12 }}>
          <Button
            variant="ghost"
            onClick={addFrequentFlyerProgram}
            startIcon={<AddIcon />}
            disabled={disabled}
            size="medium"
            sx={{ marginLeft: theme.spacing(-2) }}
          >
            {t("buttons__wizard_add_frequent_flyer")}
          </Button>
        </Grid2>
      ) : (
        <></>
      )}
    </>
  );
};
