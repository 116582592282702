import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";

interface ChatItemContentWhatsappProps extends ChatItemContentBaseProps {}

export const ChatItemContentWhatsapp: FC<ChatItemContentWhatsappProps> = ({
  content,
  isLoggedUser
}) => {
  const { palette } = useTheme();
  return (
    <Paragraph
      sx={{
        color: !isLoggedUser ? palette.background.paper : palette.primary.main
      }}
      whiteSpace="pre-wrap"
    >
      {content}
    </Paragraph>
  );
};
