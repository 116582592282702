import * as Types from "../generated/graphql";

import { ActorFragmentDoc } from "./Actor.generated";
import { ActorGroupFragmentDoc } from "./ActorGroup.generated";
export type MessageFragment = {
  __typename?: "Message";
  conversationId: string;
  messageBirdVersion?: string | null;
  messageId: string;
  content: any;
  direction: Types.Direction;
  read: boolean;
  createdAt: any;
  status: Types.MessageStatus;
  to?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorGroupId?: string | null;
    displayName?: string | null;
    actorGroup?: {
      __typename?: "ActorGroup";
      actorGroupType: Types.ActorGroupType;
      name: string;
    } | null;
  } | null;
  from?: {
    __typename?: "Actor";
    actorId?: string | null;
    actorGroupId?: string | null;
    displayName?: string | null;
    actorGroup?: {
      __typename?: "ActorGroup";
      actorGroupType: Types.ActorGroupType;
      name: string;
    } | null;
  } | null;
  channel: { __typename?: "Channel"; platform: Types.Platform; name?: string | null };
};

export const MessageFragmentDoc = `
    fragment Message on Message {
  conversationId
  messageBirdVersion
  messageId
  to {
    ...Actor
  }
  from {
    ...Actor
  }
  content
  direction
  read
  channel {
    platform
    name
  }
  createdAt
  status
}
    `;
