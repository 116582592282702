import { Grid2 } from "@mui/material";
import { FC } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Chip, Heading4, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { IdCheckStatus } from "src/domain/reservation";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import {
  getGuestInsightInfoIcon,
  GuestInsightInfoTypes
} from "./reservation-details-guest-insight-utils";

interface PersonInfoProps {
  guestInsightInfoType: GuestInsightInfoTypes;
  title: string;
  edit?: boolean;
  add?: boolean;
  show?: boolean;
  info: any;
  verified?: IdCheckStatus;
}

const PersonInfo: FC<PersonInfoProps> = ({
  guestInsightInfoType,
  title,
  edit,
  add,
  show,
  info,
  verified
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();

  const verifiedLabelStyling = (status: IdCheckStatus) => {
    switch (status) {
      case "CONFIRMED":
        return "success";
      case "UNCONFIRMED":
        return "warning";
      case "DECLINED":
        return "error";
    }
  };

  return (
    <>
      {show && (
        <>
          <Grid2
            container
            sx={{
              justifyContent: "left",
              alignItems: "center"
            }}
          >
            <Grid2
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingRight: 2
              }}
            >
              {getGuestInsightInfoIcon(guestInsightInfoType)}
            </Grid2>
            <Grid2
              sx={{
                paddingRight: 3
              }}
            >
              <Heading4 sx={{ color: theme.palette.text.primary }}>{title}</Heading4>
            </Grid2>
            {edit && (
              <Grid2
                sx={{
                  paddingRight: 2
                }}
              >
                <EditIcon />
              </Grid2>
            )}
            {add && (
              <Grid2>
                <AddIcon />
              </Grid2>
            )}
            {verified && (
              <Grid2>
                <Chip
                  label={t("labels__guest__verified__" + verified)}
                  color={verifiedLabelStyling(verified)}
                  size="small"
                />
              </Grid2>
            )}
          </Grid2>
          <Grid2 container>
            {guestInsightInfoType !== GuestInsightInfoTypes.PREFERENCES &&
              guestInsightInfoType !== GuestInsightInfoTypes.NOTES &&
              guestInsightInfoType !== GuestInsightInfoTypes.ACCOUNT &&
              info?.map(
                (dataInfo: string) =>
                  dataInfo && (
                    <Grid2
                      key={dataInfo}
                      sx={{
                        paddingRight: 5,
                        paddingTop: 1
                      }}
                    >
                      <Paragraph sx={{ color: theme.palette.text.primary }}>{dataInfo}</Paragraph>
                    </Grid2>
                  )
              )}
            {(guestInsightInfoType === GuestInsightInfoTypes.PREFERENCES ||
              guestInsightInfoType === GuestInsightInfoTypes.NOTES) && <>{info}</>}
            {guestInsightInfoType === GuestInsightInfoTypes.ACCOUNT && (
              <Grid2
                sx={{
                  paddingTop: 1
                }}
              >
                <Paragraph sx={{ color: theme.palette.text.primary }}>{info}</Paragraph>
              </Grid2>
            )}
          </Grid2>
        </>
      )}
    </>
  );
};
export default PersonInfo;
