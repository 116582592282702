import { api } from "../ToggleChatBot.generated";

const getConversationDetailsEnhanced = api.enhanceEndpoints({
  addTagTypes: ["ConversationDetails"],
  endpoints: {
    ToggleChatBot: {
      invalidatesTags: (result, error, arg) => [
        { id: arg.conversationId, type: "ConversationDetails" }
      ]
    }
  }
});

export const { useToggleChatBotMutation: useToggleChatBotMutationEnhanced } =
  getConversationDetailsEnhanced;
