import { Grid2, Paper } from "@mui/material";
import { EmptyState, ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Notes } from "src/domain/reservation-table-dto";
import { grey } from "@mui/material/colors";
import { concat } from "lodash";
import { FC } from "react";

/**
 * Returns a card with the first comment (if it exists) of which
 * type of comment.
 */

export interface CardCommentsInterface {
  notes: Notes;
  numberOfComments: number;
}

export interface Comments {
  comment: string | string[];
  label: string;
}

const numberOfCommentsToShow = 3;

export const CardComments: FC<CardCommentsInterface> = ({ notes, numberOfComments }) => {
  const { t } = useTranslationWrapper();

  const commentsToShow = (type: string) => {
    const NotesType = notes[type as keyof typeof notes] as Array<string>;
    const comments = NotesType?.slice(0, numberOfCommentsToShow).join("\r\n");
    if (NotesType?.length > 3) {
      return concat(comments, "\r\n...");
    } else {
      return comments;
    }
  };

  const commentsToShowObject = [
    {
      comment: commentsToShow("guestComment"),
      label: "labels__notes__guest__comments"
    },
    {
      comment: commentsToShow("bookerComment"),
      label: "labels__notes__booker__comment"
    },
    {
      comment: commentsToShow("comment"),
      label: "labels__notes__reservation__comment"
    },
    {
      comment: commentsToShow("bookingComment"),
      label: "labels__notes__booking__comment"
    },
    {
      comment: commentsToShow("rateBreakdownComment"),
      label: "labels__notes__rateBreakdownComment"
    },
    {
      comment: commentsToShow("beddingComment"),
      label: "labels__notes__beddingComment"
    }
  ];

  return (
    <>
      {numberOfComments > 0 ? (
        commentsToShowObject.map(
          (comment: Comments) =>
            comment.comment && (
              <Grid2 size={{ xs: 12 }} key={comment.label}>
                <Paper
                  elevation={0}
                  sx={{
                    background: grey[100],
                    borderColor: grey[100],
                    borderRadius: "5px"
                  }}
                  variant="outlined"
                >
                  <Grid2
                    container
                    direction="column"
                    sx={{
                      p: 2
                    }}
                  >
                    <Grid2>
                      <ParagraphBold>{t(comment.label)}</ParagraphBold>
                    </Grid2>
                    <Grid2
                      sx={{
                        marginTop: 1
                      }}
                    >
                      <ParagraphSmall sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                        {comment.comment}
                      </ParagraphSmall>
                    </Grid2>
                  </Grid2>
                </Paper>
              </Grid2>
            )
        )
      ) : (
        <EmptyState title={t("labels__no__comments__in__reservation")} />
      )}
    </>
  );
};
