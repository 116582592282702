import { Divider, Grid2, useTheme } from "@mui/material";
import { DatePickerFilter } from "../../components/date-picker-filter/date-picker-filter";
import * as React from "react";
import { FC, useMemo } from "react";
import { Property } from "../../domain/property";
import { groupBy } from "lodash";
import { HousekeepingCardsFloor, HousekeepingCardsTypeEnum } from "./housekeeping-cards-floor";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { HousekeepingCards } from "../../features/tasks/domain/task";
import {
  FilterObjectType,
  serializeIdOfFilter
} from "../../components/table/filters/table-filters";
import { FilterOperator } from "../../types/filters/filters";
import { useSearchFilterParams } from "../../components/table/hooks/use-search-filter-params";
import { TaskType } from "../../graphql-tasks/generated/graphql";
import { housekeepingFilterParamSelector } from "../../utils/housekeeping";
import { HousekeepingTaskOwnershipFilters } from "../../domain/housekeeping-task-ownership-filters";
import { useCurrentActor } from "../../hooks/use-current-actor";
import { HousekeepingFilters } from "./housekeeping-filters";
import { EmptyState } from "@likemagic-tech/sv-magic-library";

interface UnitsToCleanProps {
  selectedProperty?: Property;
  housekeepingCards: HousekeepingCards;
  isLoading: boolean;
}

const assignedToMeFilter = serializeIdOfFilter({
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality,
  name: housekeepingFilterParamSelector(HousekeepingTaskOwnershipFilters.ASSIGNED_TO_ME)
});

const assignedToTeamFilter = serializeIdOfFilter({
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality,
  name: housekeepingFilterParamSelector(HousekeepingTaskOwnershipFilters.ASSIGNED_TO_TEAM)
});

export const HousekeepingUnitsToClean: FC<UnitsToCleanProps> = ({
  selectedProperty,
  housekeepingCards,
  isLoading
}) => {
  const units = groupBy(housekeepingCards.units ?? [], "floor");
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { currentActorId } = useCurrentActor();
  const { filterMap } = useSearchFilterParams();

  const currentActorTasks = useMemo(() => {
    return groupBy(
      housekeepingCards.units?.filter(
        (unit) =>
          unit?.housekeepingTasks?.find(
            (task) =>
              (task.type === TaskType.DepartureCleaning ||
                task.type === TaskType.StayoverCleaning) &&
              task.assignee?.actorId === currentActorId
          )
      ),
      "floor"
    );
  }, [housekeepingCards.units, currentActorId]);

  const teamTasks = useMemo(() => {
    return groupBy(
      housekeepingCards.units?.filter(
        (unit) =>
          unit?.housekeepingTasks?.find(
            (task) =>
              (task.type === TaskType.DepartureCleaning ||
                task.type === TaskType.StayoverCleaning) &&
              task.assignee === null
          )
      ),
      "floor"
    );
  }, [housekeepingCards.units]);

  const filteredUnits = useMemo(() => {
    const isActorTasksFilterActive = !!filterMap[assignedToMeFilter]?.value;
    const isTeamTasksFilterActive = !!filterMap[assignedToTeamFilter]?.value;

    switch (true) {
      case isActorTasksFilterActive && isTeamTasksFilterActive:
        return units;
      case isActorTasksFilterActive:
        return currentActorTasks;
      case isTeamTasksFilterActive:
        return teamTasks;
      default:
        return units;
    }
  }, [filterMap, currentActorTasks, teamTasks, units]);

  return (
    <Grid2
      container
      sx={{
        gap: 4
      }}
    >
      <Grid2
        container
        spacing={2}
        sx={{
          alignItems: "center",
          width: "100%"
        }}
      >
        {(!!Object.values(currentActorTasks).length || !!Object.values(teamTasks).length) && (
          <>
            <HousekeepingFilters
              currentActorFilterCount={
                Object.values(currentActorTasks).flatMap((unit) => unit).length
              }
              teamFilterCount={Object.values(teamTasks).flatMap((unit) => unit).length}
            />
            <Grid2>
              <Divider
                orientation="vertical"
                sx={{
                  color: theme.palette.text.secondary,
                  height: theme.spacing(3)
                }}
              />
            </Grid2>
          </>
        )}
        <Grid2>
          <DatePickerFilter
            timeZone={selectedProperty?.details.timeZone ?? ""}
            disablePast={true}
          />
        </Grid2>
      </Grid2>
      {!isLoading && (
        <Grid2 container sx={{ width: "100%" }}>
          {!Object.keys(filteredUnits).length && !housekeepingCards.reservations?.length && (
            <EmptyState title={t("labels__all_units_cleaned")} />
          )}
          {Object.keys(filteredUnits).map((key) => (
            <HousekeepingCardsFloor
              key={`cards-flor-${key}`}
              floorKey={key}
              units={filteredUnits[key]}
              housekeepingCardsType={HousekeepingCardsTypeEnum.UNITS}
              propertyId={selectedProperty?.propertyId ?? ""}
            />
          ))}
          {!!housekeepingCards?.reservations.length && (
            <HousekeepingCardsFloor
              reservations={housekeepingCards.reservations ?? []}
              housekeepingCardsType={HousekeepingCardsTypeEnum.RESERVATIONS}
              propertyId={selectedProperty?.propertyId ?? ""}
            />
          )}
        </Grid2>
      )}
    </Grid2>
  );
};
