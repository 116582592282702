import * as React from "react";
import { FC, PropsWithChildren } from "react";
import { Box, Grid2, Paper, useTheme } from "@mui/material";
import { Heading3, Paragraph } from "@likemagic-tech/sv-magic-library";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Step, StepStatus } from "../../../../components/step/step";

interface ManualWizardContentProps {
  steps: Array<string>;
  currentStep: string;
  title: string;
}
export const ManualWizardContent: FC<PropsWithChildren<ManualWizardContentProps>> = ({
  steps,
  currentStep,
  children,
  title
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const showOnlyOneStep = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        mt: 2,
        mb: 8
      }}
    >
      <Paper sx={{ borderRadius: 2 }} elevation={0}>
        <Grid2
          container
          direction="row"
          sx={{
            p: 3,
            alignItems: "center"
          }}
        >
          <Grid2
            size={{ xs: 5, md: 3 }}
            sx={{
              alignItems: "center"
            }}
          >
            <Heading3 mr={1} style={{ wordBreak: "break-word" }}>
              {title}
            </Heading3>
          </Grid2>
          <Grid2
            size={{ xs: 7, md: 9 }}
            sx={{
              justifyContent: "center"
            }}
          >
            <Grid2
              container
              direction="row"
              sx={{
                justifyContent: "center"
              }}
            >
              {steps.map((step, index) => (
                <Step
                  key={step}
                  keepSecondaryText
                  stepName={step}
                  stepWidth={`${100 / steps.length}%`}
                  status={
                    step === currentStep
                      ? StepStatus.CURRENT
                      : // TODO - this is a hack to have completed steps, need to refactor once we have the real data
                        index < steps.findIndex((s) => s === currentStep)
                        ? StepStatus.PASSED
                        : StepStatus.IDLE
                  }
                />
              ))}
            </Grid2>
            {showOnlyOneStep && (
              <Paragraph p={1} pb={0} textAlign="center">
                {t(`labels__step_data_${currentStep}`)}
              </Paragraph>
            )}
          </Grid2>
        </Grid2>
      </Paper>
      {children}
    </Box>
  );
};
