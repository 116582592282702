import { Box, useTheme } from "@mui/material";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { ActionMenu } from "src/components/action-menu/action-menu";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { SettleFolioModal } from "../reservation-details/reservation-details-tabs/folios/settle-folio-modal";
import { useSettleFolio } from "src/hooks/use-settle-folio";
import { useProperty } from "src/hooks/use-property";
import { Params, useSelectId } from "src/hooks/use-select-id";
import { FolioPreview } from "src/store/endpoints/reservation-table.endpoints";
import { isFolioClosed, isFolioDownloadable } from "./payment-utils";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { useDownloadInvoice } from "src/hooks/use-download-invoice";
import { TenantContext } from "@likemagic-tech/sv-magic-library";

enum FoliosMoreButtonOptions {
  PDF_DOWNLOAD = "PDF_DOWNLOAD",
  SETTLE_FOLIO = "SETTLE_FOLIO"
}

interface PaymentFoliosActionsProps {
  folio: FolioPreview;
}

export const PaymentFoliosActions: FC<PaymentFoliosActionsProps> = ({ folio }) => {
  const { t } = useTranslationWrapper();
  const [openSettleFolioModal, setOpenSettleFolioModal] = useState(false);
  const [performSettleFolio] = useSettleFolio();
  const { selectedProperty } = useProperty();
  const { selectedId } = useSelectId({
    fieldName: Params.SELECTED_RESERVATION_ID
  });
  const dispatch = useDispatch();
  const theme = useTheme();
  const [downloadInvoice] = useDownloadInvoice();
  const { pms } = useContext(TenantContext);

  const moreButtonOptions = useMemo(
    () =>
      [
        {
          id: FoliosMoreButtonOptions.PDF_DOWNLOAD,
          label: t(`buttons__${FoliosMoreButtonOptions.PDF_DOWNLOAD}`)
        },
        {
          id: FoliosMoreButtonOptions.SETTLE_FOLIO,
          label: t(`buttons__${FoliosMoreButtonOptions.SETTLE_FOLIO}`)
        }
      ]
        .filter(
          (item) =>
            item.id !== FoliosMoreButtonOptions.SETTLE_FOLIO ||
            (folio.balance.amount === 0 && !folio.closed)
        )
        .filter((item) =>
          item.id === FoliosMoreButtonOptions.PDF_DOWNLOAD ? isFolioDownloadable(pms, folio) : true
        ),
    [t, folio, pms]
  );

  const downloadFolio = useCallback(
    async (
      pmsPropertyId: string,
      pmsFolioId: string,
      pmsReservationId: string,
      isClosed: boolean
    ) => {
      downloadInvoice({ pmsPropertyId, pmsFolioId, pmsReservationId, isClosed });
    },
    [downloadInvoice]
  );

  const handleMenuClick = useCallback(
    (option: FoliosMoreButtonOptions) => {
      switch (option) {
        case FoliosMoreButtonOptions.PDF_DOWNLOAD:
          return downloadFolio(
            selectedProperty?.propertyId,
            isFolioClosed(folio) ? folio.id : folio.number,
            selectedId ?? "",
            isFolioClosed(folio)
          );
        case FoliosMoreButtonOptions.SETTLE_FOLIO:
          return setOpenSettleFolioModal(true);
        default:
          return undefined;
      }
    },
    [downloadFolio, folio, selectedId, selectedProperty.propertyId]
  );

  const handleSubmitSettleFolio = useCallback(() => {
    performSettleFolio({
      pmsPropertyId: selectedProperty?.propertyId,
      pmsReservationId: selectedId ?? "",
      folioMetadata: folio.metadata
    })
      .unwrap()
      .then(() => {
        dispatch(
          openBanner({
            type: "success",
            title: t("labels__action_successfully_performed")
          })
        );
      });
    setOpenSettleFolioModal(false);
  }, [performSettleFolio, selectedId, selectedProperty?.propertyId, folio.metadata, dispatch, t]);

  return (
    <>
      <Box>
        {moreButtonOptions.length > 0 && (
          <ActionMenu
            items={moreButtonOptions}
            handleAction={(selected) => {
              handleMenuClick(selected as FoliosMoreButtonOptions);
            }}
            color={theme.palette.accent.main}
          />
        )}
      </Box>
      {openSettleFolioModal && (
        <SettleFolioModal
          onCancel={() => setOpenSettleFolioModal(false)}
          isOpen={openSettleFolioModal}
          onSubmit={handleSubmitSettleFolio}
        />
      )}
    </>
  );
};
