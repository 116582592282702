import { formatDateTime, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Task } from "./domain/task";
import { useProperty } from "src/hooks/use-property";
import { useUnits } from "src/hooks/use-unit-by-id";
import { Status } from "src/graphql-tasks/generated/graphql";
import { DisplayTaskIcon } from "./components/display-task-icon";
import { statusColor } from "./components/status-cell";
import { taskDateOverdue } from "../reservations/reservation-details/reservation-details-tabs/overview/card-tasks/card-tasks-info";
import { useTaskModal } from "./use-task-select-id";
import { getI18nSelectedLanguage } from "src/utils/language";
import { ButtonWithChevron } from "../../components/button/button-with-chevron";

interface TaskPreviewProps {
  task: Task;
  displayReservationId?: boolean;
}

export const TaskPreview: FC<TaskPreviewProps> = ({ task, displayReservationId = true }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const { data: units } = useUnits({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const unitId = task.links?.pmsUnitIds?.[0] ?? "";
  const findUnitNumber = units?.find((unit) => unit.id === unitId)?.name;
  const { openEditTask } = useTaskModal({});

  return (
    <ButtonWithChevron
      onClick={() => {
        openEditTask(task.id.toString());
      }}
    >
      <Grid2
        container
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="nowrap"
        direction="column"
        width="100%"
      >
        <Grid2 container direction="column" width="100%">
          <Grid2 size={{ xs: 12 }}>
            <Paragraph sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              {task.title}
            </Paragraph>
          </Grid2>
          <Grid2>
            <Grid2 container direction="row" sx={{ gap: 0.5 }}>
              {findUnitNumber && (
                <Grid2>
                  <ParagraphSmall color="text.secondary">
                    {t("labels__unit")}: {findUnitNumber}
                  </ParagraphSmall>
                </Grid2>
              )}
              {displayReservationId &&
                findUnitNumber &&
                task.links?.reservations?.[0]?.displayableReservationId && (
                  <Grid2>
                    <ParagraphSmall color="text.secondary">|</ParagraphSmall>
                  </Grid2>
                )}
              {displayReservationId && task.links?.reservations?.[0]?.displayableReservationId && (
                <Grid2>
                  <ParagraphSmall color="text.secondary">
                    {`${t("labels__res_ID")}: ${task.links?.reservations?.[0]
                      ?.displayableReservationId}`}
                  </ParagraphSmall>
                </Grid2>
              )}
            </Grid2>
            <Grid2>
              {task.due && (
                <Grid2 container direction="row" sx={{ gap: 0.5 }}>
                  <Grid2>
                    <ParagraphSmall color="text.secondary">{t("labels__due_date")}</ParagraphSmall>
                  </Grid2>
                  <Grid2>
                    <ParagraphSmall
                      color={
                        task.status !== Status.Done && taskDateOverdue(task?.due)
                          ? palette.error.main
                          : "inherit"
                      }
                    >
                      {formatDateTime(task.due, language, selectedProperty?.details.timeZone)}
                    </ParagraphSmall>
                  </Grid2>
                </Grid2>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 container wrap="nowrap" mt={0.75}>
          <DisplayTaskIcon status={task.status} />
          <Paragraph sx={{ color: statusColor(task.status, palette) }}>
            {t(`labels__task_status_${task.status}`)}
          </Paragraph>
        </Grid2>
      </Grid2>
    </ButtonWithChevron>
  );
};
