import { ReservationStatus } from "../../domain/reservation-status";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HomeIcon from "@mui/icons-material/Home";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CancelIcon from "@mui/icons-material/Cancel";
import { QuestionMark } from "@mui/icons-material";
import * as React from "react";
import { FC } from "react";
import { SvgIconOwnProps } from "@mui/material/SvgIcon/SvgIcon";

interface ReservationStatusIconProps {
  status: ReservationStatus;
  size?: SvgIconOwnProps["fontSize"];
}

export const ReservationStatusIcon: FC<ReservationStatusIconProps> = ({
  status,
  size = "medium"
}) => {
  switch (status) {
    case ReservationStatus.CONFIRMED:
      return <CheckCircleIcon fontSize={size} />;
    case ReservationStatus.IN_HOUSE:
      return <HomeIcon fontSize={size} />;
    case ReservationStatus.NO_SHOW:
      return <VisibilityOffIcon fontSize={size} />;
    case ReservationStatus.CHECKED_OUT:
      return <DirectionsRunIcon fontSize={size} />;
    case ReservationStatus.DELETED:
      return <CancelIcon fontSize={size} />;
    default:
      return <QuestionMark fontSize={size} />;
  }
};
