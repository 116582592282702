import * as React from "react";
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { Box, Fade } from "@mui/material";
import { ReservationDetailsHeader } from "../reservation-details/components/reservation-details-header";
import { useDispatch, useSelector } from "src/store";
import { setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { DrawerDetails } from "src/components/drawer-details/drawer-details";
import { useReservationDetails } from "src/hooks/use-reservation-details";
import { useManualCheckinId } from "./use-manual-checkin-id";
import { ManualWizardContent } from "../components/manual-wizard/manual-wizard-content";
import { ManualCheckinStepsEnum } from "../domain/enums";
import { ManualCheckinStepRegistrationCard } from "./components/manual-checkin-step-registration-card";
import { ManualCheckinStepServices } from "./components/manual-checkin-step-services";
import { ManualCheckinStepPayment } from "./components/manual-checkin-step-payment";
import { ManualCheckinStepConfirmation } from "./components/manual-checkin-step-confirmation";
import { useManualCheckinSteps } from "../../../hooks/use-manual-checkin-steps";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { ManualCheckinStepRateBreakdown } from "./components/manual-checkin-step-rate-breakdown.tsx";
import { SendLinkModal } from "../payment/payment-component-handlers/send-link-modal";
import { paymentLinkSelector } from "../../../slices/send-payment-link-modal.slice";
import { ManualCheckinStepUpsell } from "./components/manual-checkin-step-upsell/manual-checkin-step-upsell";
import { Params } from "../../../hooks/use-select-id";
import { useTerminalPayment } from "../payment/hooks/use-terminal-payment";
import { TerminalPaymentModal } from "../payment/payment-component-handlers/terminal-payment-modal";

export const ManualCheckin: FC<PropsWithChildren> = () => {
  const { setManualCheckinId, manualCheckinId } = useManualCheckinId();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const reservationDetails = useReservationDetails({ reservationId: manualCheckinId ?? "" });
  const { currentStep, steps } = useManualCheckinSteps({
    propertyId: reservationDetails?.propertyId
  });
  const { openModal: terminalPaymentModal } = useTerminalPayment();

  const { t } = useTranslationWrapper();
  const paymentLink = useSelector(paymentLinkSelector);

  const currentStepComponent = useMemo(() => {
    switch (currentStep) {
      case ManualCheckinStepsEnum.REGISTRATION_CARD:
        return <ManualCheckinStepRegistrationCard reservationDetails={reservationDetails} />;
      case ManualCheckinStepsEnum.SERVICES:
        return <ManualCheckinStepServices reservationDetails={reservationDetails} />;
      case ManualCheckinStepsEnum.RATE_BREAKDOWN:
        return <ManualCheckinStepRateBreakdown reservationDetails={reservationDetails} />;
      case ManualCheckinStepsEnum.PAYMENT:
        return <ManualCheckinStepPayment reservationDetails={reservationDetails} />;
      case ManualCheckinStepsEnum.CONFIRMATION:
        return <ManualCheckinStepConfirmation reservationDetails={reservationDetails} />;
      case ManualCheckinStepsEnum.UPSELL_UNIT_GROUP:
        return <ManualCheckinStepUpsell reservationDetails={reservationDetails} />;
    }
  }, [currentStep, reservationDetails]);

  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (manualCheckinId || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [manualCheckinId, setOpenSideNavigation, isMobile]);

  return (
    <>
      <DrawerDetails
        isOpen={!!manualCheckinId}
        drawerId={Params.SELECTED_MANUAL_CHECKIN_RESERVATION_ID}
        onClose={() => {
          setManualCheckinId(
            undefined,
            new URLSearchParams({
              MANUAL_CHECKIN: ""
            })
          );
        }}
      >
        {reservationDetails && (
          <Box sx={{ overflowY: "scroll" }}>
            <Fade in={!!reservationDetails?.id}>
              <Box
                sx={{
                  p: 2
                }}
              >
                <ReservationDetailsHeader reservation={reservationDetails} />
                <ManualWizardContent
                  steps={steps}
                  currentStep={currentStep ?? ""}
                  title={t("labels__checkin_wizard")}
                >
                  {currentStepComponent}
                </ManualWizardContent>
              </Box>
            </Fade>
          </Box>
        )}
      </DrawerDetails>
      {!!paymentLink && <SendLinkModal reservation={reservationDetails} />}
      {terminalPaymentModal && <TerminalPaymentModal />}
    </>
  );
};
