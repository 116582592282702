import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Button, ButtonProps } from "@likemagic-tech/sv-magic-library";
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AutocompleteOption } from "../autocomplete-component";
import { ArrowDropUp } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

interface SplitButtonProps {
  options: Array<AutocompleteOption>;
  onClick: (selectedId: string) => void;
  startIcon?: ReactNode;
  defaultSelectedOption?: AutocompleteOption;
  selectedPrefix?: string;
  color?: ButtonProps["color"];
  variant?: ButtonProps["variant"];
}

const useStyles = makeStyles<{ variant: ButtonProps["variant"] }>()((theme, { variant }) => {
  return {
    splitter: {
      "&::after": {
        width: 1,
        content: '""',
        height: "100%",
        position: "absolute",
        right: 0,
        background:
          variant === "primary" ? theme.palette.text.secondary : theme.palette.text.primary
      }
    }
  };
});

export const SplitButton: FC<SplitButtonProps> = ({
  options,
  onClick,
  startIcon,
  defaultSelectedOption,
  selectedPrefix,
  color,
  variant = "secondary"
}) => {
  const { classes } = useStyles({ variant });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<AutocompleteOption | undefined>();

  useEffect(() => {
    if (defaultSelectedOption) {
      setSelectedItem(defaultSelectedOption);
      return;
    }

    if (options[0]) {
      setSelectedItem(options[0]);
    }
  }, [defaultSelectedOption, options]);

  const handleClick = () => {
    if (selectedItem?.id) {
      onClick(selectedItem?.id);
    }
  };

  const handleMenuItemClick = (item: AutocompleteOption) => {
    setSelectedItem(item);
    setOpen(false);
    onClick(item.id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        sx={{ boxShadow: "none" }}
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        color={color as any}
      >
        <Button
          variant={variant}
          className={classes.splitter}
          size="medium"
          onClick={handleClick}
          startIcon={startIcon}
          color={color}
          sx={{
            borderRight: "none",
            "&:hover": { borderRight: "none" }
          }}
        >
          {selectedPrefix} {selectedItem?.label}
        </Button>
        <Button
          variant={variant}
          sx={{
            borderLeft: "none!important",
            "&:hover": { borderLeft: "none" }
          }}
          size="medium"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          color={color}
        >
          {open ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          pt: 0.5,
          zIndex: 100
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option) => (
                    <MenuItem
                      key={option.id}
                      selected={option.id === selectedItem?.id}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
