import { Components, Theme } from "@mui/material";
import { MuiButton, MuiChip } from "@likemagic-tech/sv-magic-library";

export const components: Components<Omit<Theme, "components">> | undefined = {
  MuiButton,
  MuiChip,
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.spacing(2),
        margin: theme.spacing(1)
      })
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[300]
      })
    }
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(2)
      })
    }
  },
  MuiSpeedDial: {
    styleOverrides: {
      fab: ({ theme }) => ({
        borderRadius: theme.spacing(2.5),
        backgroundColor: theme.palette.accent.main
      })
    }
  },
  MuiPopover: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[2]
      })
    }
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme }) => ({
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[2]
      })
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(2),
        "&.Mui-disabled": { background: theme.palette.grey["200"] }
      })
    }
  },

  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1)
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&.Mui-disabled": { opacity: 0.38 }
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1)
      }),
      bar: ({ theme }) => ({
        borderRadius: theme.spacing(1)
      }),
      bar2Buffer: ({ theme }) => ({
        backgroundColor: theme.palette.grey[200]
      })
    }
  },
  MuiBadge: {
    variants: [
      {
        props: { variant: "secondary" },
        style: ({ theme }) => ({
          "& .BaseBadge-badge": {
            color: theme.palette.accent.main,
            border: `1px solid ${theme.palette.accent.main}`,
            textAlign: "center",
            backgroundColor: theme.palette.common.white
          }
        })
      }
    ],
    styleOverrides: {
      badge: ({ theme }) => ({
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 1,
        paddingRight: 1,
        height: 20,
        width: 20,
        borderRadius: 24,
        zIndex: 1051,
        backgroundColor: theme.palette.accent.main,
        color: theme.palette.common.white,
        top: 4,
        right: 4
      })
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.grey[300]
      })
    }
  },
  MuiTypography: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary
      })
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        "&.Mui-disabled": { opacity: 0.38 }
      }
    }
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: `0px 0px 1px 1px ${theme.palette.grey[300]}`
      })
    }
  }
};
