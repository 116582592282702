import { RegistrationCardFormDataType } from "../../domain/registration-card";
import {
  CommunicationChannel as CommunicationChannelV2,
  Gender,
  PersonInput,
  UpdatePersonInput
} from "../generated/graphql";
import { PersonGender } from "../../domain/person";
import { CommunicationChannel } from "@likemagic-tech/sv-magic-library";

export const transformRegistrationCardData = (
  values: RegistrationCardFormDataType,
  pmsReservationId: string
): {
  person: UpdatePersonInput;
  pmsReservationId: string;
  persons: PersonInput[];
  pmsProfileId: string;
} => {
  return {
    person: {
      address: {
        addressLine1: values.mainGuest.addressLine1,
        addressLine2: values.mainGuest.addressLine2,
        city: values.mainGuest.city,
        countryCode: values.mainGuest.country,
        postalCode: values.mainGuest.postcode,
        region: values.mainGuest.region
      },
      gender: mapPersonGenderToGender(values.mainGuest.gender as PersonGender),
      email: values.mainGuest.email,
      firstName: values.mainGuest.firstName,
      lastName: values.mainGuest.lastName,
      nationality: "",
      phone: values.mainGuest.phone,
      pmsId: values.mainGuest.id,
      emergencyEvacuationHelpNeeded: values.mainGuest.emergencyEvacuationHelpNeeded,
      emergencyEvacuationHelpNotes: values.mainGuest.emergencyEvacuationHelpNotes,
      enrolledInLoyaltyProgram: values.mainGuest.enrolledInLoyaltyProgram,
      licensePlate: values.mainGuest.licensePlate,
      preferredCommunicationChannel: transformCommunicationChannel(
        values.mainGuest.preferredCommunicationChannel
      )
    },
    pmsReservationId: pmsReservationId,
    pmsProfileId: values.mainGuest.id,
    persons: values.travelBuddies.map((buddy) => ({
      gender: mapPersonGenderToGender(values.mainGuest.gender as PersonGender),
      email: buddy.email,
      firstName: buddy.firstName,
      lastName: buddy.lastName,
      phone: buddy.phone,
      pmsId: buddy.id
    }))
  };
};

const mapPersonGenderToGender = (gender: PersonGender | null | undefined): Gender | undefined => {
  switch (gender) {
    case PersonGender.MALE:
      return Gender.Male;
    case PersonGender.FEMALE:
      return Gender.Female;
    case PersonGender.OTHER:
      return Gender.Other;
    default:
      return undefined;
  }
};

export const transformCommunicationChannel = (
  channel?: string
): CommunicationChannelV2 | undefined => {
  switch (channel) {
    case CommunicationChannel.EMAIL:
      return CommunicationChannelV2.Email;
    case CommunicationChannel.WHATSAPP:
      return CommunicationChannelV2.Whatsapp;
    case CommunicationChannel.SMS:
      return CommunicationChannelV2.Sms;
    default:
      return undefined;
  }
};
