import React, { FC, useMemo } from "react";
import ActionDropdown from "src/components/action-dropdown/action-dropdown";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { FileDownloadOutlined } from "@mui/icons-material";

interface HousekeepingForecastActionsProps {
  onClick: (actionType: ExportActionType) => void;
}

export enum ExportActionType {
  CSV = "CSV",
  EXCEL = "XLSX"
}
export const HousekeepingForecastActions: FC<HousekeepingForecastActionsProps> = ({ onClick }) => {
  const { t } = useTranslationWrapper();

  const options = useMemo(() => {
    return [
      {
        id: ExportActionType.CSV,
        label: t("labels__csv_file")
      },
      {
        id: ExportActionType.EXCEL,
        label: t("labels__excel_file")
      }
    ];
  }, [t]);

  const handleOption = (optionId: string) => {
    switch (optionId) {
      case ExportActionType.CSV:
      case ExportActionType.EXCEL:
        onClick(optionId);
        break;
    }
  };

  return (
    <ActionDropdown
      handleOption={handleOption}
      options={options}
      startIcon={<FileDownloadOutlined />}
    >
      {t("buttons__actions")}
    </ActionDropdown>
  );
};
