import { FC, useMemo } from "react";
import { Grid2, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { AutocompleteComponent, AutocompleteOption } from "../../../autocomplete-component";
import { Select } from "@likemagic-tech/sv-magic-library";

export interface AutocompleteFilterValue {
  id: string;
  type: AutocompleteFilterType;
}

interface AutocompleteFilterProps {
  value: AutocompleteFilterValue;
  onChange: (value: AutocompleteFilterValue, oldType?: AutocompleteFilterType) => void;
  options: Array<AutocompleteOption>;
  allowedFilters: Array<AutocompleteFilterType>;
}

//Order is important
export enum AutocompleteFilterType {
  "ASSIGNED_TO" = "assignedTo",
  "REPORTED_BY" = "reportedBy",
  "ASSIGNED_TO_TEAMS_OF" = "assignedToTeamsOf"
}

export const AutocompleteFilter: FC<AutocompleteFilterProps> = ({
  value,
  onChange,
  options,
  allowedFilters
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  const onItemSelected = (item: AutocompleteOption) => {
    onChange({
      id: item.id,
      type: value.type
    });
  };

  const selectedItem = useMemo(() => {
    const selectedOption = options.find((item) => item?.id === value?.id);
    return value?.id ? { id: value?.id ?? "", label: selectedOption?.label ?? "" } : null;
  }, [value, options]);
  return (
    <Grid2
      container
      spacing={2}
      sx={{
        maxWidth: theme.spacing(64)
      }}
    >
      <Grid2 size={{ md: 4, xs: 12 }}>
        <Select
          size="small"
          value={value.type}
          variant={"outlined"}
          onChange={(e) => {
            onChange(
              {
                ...value,
                type: e.target.value as AutocompleteFilterType
              },
              value.type
            );
          }}
          options={allowedFilters.map((item) => ({
            label: t(`labels__filter_title_${item}`),
            value: item
          }))}
          label=""
        />
      </Grid2>
      <Grid2 size={{ md: 8, xs: 12 }}>
        <AutocompleteComponent
          size="small"
          label=""
          value={selectedItem}
          options={options}
          onSelected={onItemSelected}
        />
      </Grid2>
    </Grid2>
  );
};
