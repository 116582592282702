import { Input, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  useTheme
} from "@mui/material";
import { FieldArray, Form, Formik, FormikErrors, FormikTouched } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { DialogModal } from "src/components/submit-modal/dialog";
import { DialogActionModal } from "src/components/submit-modal/dialog-actions";
import { DialogTitleModal } from "src/components/submit-modal/dialog-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { AutocompleteComponent } from "../../../../components/autocomplete-component";
import RemoveCircle from "../../../../icons/RemoveCircle";
import Plus from "../../../../icons/Plus";
import { OrderItemTransactionTypeResponse } from "../../../../domain/order-item-transaction-type";
import { useAddArbitraryOrderItemsMutation } from "../../../../graphql/mutations/add-arbitary-order-items.generated";
import { FolioPreview } from "../../../../store/endpoints/reservation-table.endpoints";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { AmountInput } from "../../../../components/price-picker/amount-input";
import { parsePricePickerAmountToAmountInCents } from "../../../../utils/price";

interface AddOrderItemForm {
  orderItem: {
    id: string;
    label: string;
  };
  quantity: number;
  amount: number;
  note: string;
}

const emptyOrderItem: AddOrderItemForm = {
  orderItem: {
    id: "",
    label: ""
  },
  quantity: 1,
  amount: 1,
  note: ""
};

interface AddChargeModalProps {
  onClose: () => void;
  orderItemTransactionTypes?: OrderItemTransactionTypeResponse[];
  reservationId: string;
  folio?: FolioPreview;
  isLoading?: boolean;
}

export const AddChargeModal: FC<AddChargeModalProps> = ({
  onClose,
  orderItemTransactionTypes,
  reservationId,
  folio,
  isLoading
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const [addArbitraryOrderItems] = useAddArbitraryOrderItemsMutation();
  const { addChargeModalValidation } = useFormValidations();

  const addArbitraryOrderItemsOptions = useMemo(
    () =>
      orderItemTransactionTypes?.map((orderItem) => ({
        label: orderItem.name,
        value: orderItem.pmsId,
        id: orderItem.pmsId
      })),
    [orderItemTransactionTypes]
  );

  const handleSubmit = useCallback(
    async (values: { orderItems: Array<AddOrderItemForm> }) => {
      await addArbitraryOrderItems({
        pmsReservationId: reservationId ?? "",
        addArbitraryOrderItemsInput: values.orderItems.map((orderItem) => {
          const currentOrderItem = orderItemTransactionTypes?.find((orderItemOption) => {
            return orderItemOption.pmsId === orderItem.orderItem.id;
          });

          return {
            pmsFolioId: folio?.id ?? "",
            folioMetadata: folio?.metadata,
            transactionTypeCode: currentOrderItem?.transactionTypeCode ?? "",
            transactionTypePmsId: orderItem.orderItem.id,
            quantity: orderItem.quantity,
            price: {
              currency: currentOrderItem?.price?.currency ?? "",
              grossPriceInCents: parsePricePickerAmountToAmountInCents(orderItem.amount)
            },
            note: orderItem.note
          };
        })
      });

      onClose();
    },
    [
      addArbitraryOrderItems,
      folio?.id,
      folio?.metadata,
      onClose,
      orderItemTransactionTypes,
      reservationId
    ]
  );

  const initialValuesModal = {
    orderItems: [emptyOrderItem]
  };

  return (
    <Formik
      initialValues={initialValuesModal}
      onSubmit={async (values, { validateForm }) => {
        validateForm(values).then(() => {
          handleSubmit(values);
        });
      }}
      validationSchema={addChargeModalValidation}
    >
      {(formik) => (
        <DialogModal isOpen handleClose={onClose}>
          <Grid2
            container
            sx={{
              p: 3,
              flexDirection: "column"
            }}
          >
            <DialogTitle sx={{ flex: "1 1 0", padding: "0", marginBottom: 3 }}>
              <DialogTitleModal title={t("labels__folio_add_charge")} onClose={onClose} />
            </DialogTitle>
            <Form noValidate>
              <DialogContent sx={{ padding: "0" }}>
                <FieldArray
                  name="orderItems"
                  render={(props) => {
                    return (
                      <>
                        {props.form.values.orderItems?.map(
                          (orderItem: AddOrderItemForm, index: number) => {
                            return (
                              <Grid2
                                container
                                key={`orderItems.${index}.${orderItem.orderItem.id}`}
                                sx={{
                                  mb: 2,
                                  background: theme.palette.grey.A100,
                                  borderRadius: theme.spacing(1),
                                  paddingX: theme.spacing(1.5),
                                  paddingY: theme.spacing(1)
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%"
                                  }}
                                >
                                  <ParagraphBold>
                                    [{t("labels__charge")} #{index + 1}]
                                  </ParagraphBold>
                                  <Box>
                                    {props.form.values.orderItems.length > 1 && (
                                      <IconButton
                                        onClick={() => props.remove(index)}
                                        sx={{ width: 24, height: 24 }}
                                      >
                                        <RemoveCircle />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                                <Grid2 container sx={{ width: "100%" }}>
                                  <Grid2
                                    size={{ xs: 12, md: 6 }}
                                    sx={{
                                      mt: { xs: 2 }
                                    }}
                                  >
                                    <AutocompleteComponent
                                      required
                                      options={addArbitraryOrderItemsOptions ?? []}
                                      getOptionDisabled={(option) => {
                                        return !!props.form.values.orderItems.find(
                                          (orderItem: AddOrderItemForm) => {
                                            return orderItem.orderItem.id === option.id;
                                          }
                                        );
                                      }}
                                      onSelected={(value) => {
                                        props.form.validateField(`orderItems.[${index}].orderItem`);

                                        props.form.setFieldValue(
                                          `orderItems.[${index}].orderItem`,
                                          value
                                        );
                                        props.form.setFieldTouched(
                                          `orderItems.[${index}].orderItem`,
                                          true
                                        );

                                        const selectedOrderItem = orderItemTransactionTypes?.find(
                                          (orderItem) => orderItem.pmsId === value.id
                                        );

                                        if (selectedOrderItem?.price) {
                                          props.form.setFieldValue(
                                            `orderItems.[${index}].amount`,
                                            selectedOrderItem.price.amount / 100
                                          );
                                        }
                                      }}
                                      label={t("labels__folio_add_charge_service_item")}
                                      value={orderItem.orderItem.id ? orderItem.orderItem : null}
                                      loading={isLoading}
                                      name={`orderItems.[${index}].orderItem`}
                                      id={`orderItems.[${index}].orderItem`}
                                      error={
                                        formik.touched.orderItems &&
                                        (
                                          formik.touched.orderItems[
                                            index
                                          ] as FormikTouched<AddOrderItemForm>
                                        )?.orderItem?.id &&
                                        formik.errors.orderItems
                                          ? (
                                              formik.errors.orderItems[
                                                index
                                              ] as FormikErrors<AddOrderItemForm>
                                            )?.orderItem?.id
                                          : undefined
                                      }
                                    />
                                  </Grid2>
                                  <Grid2
                                    size={{ xs: 12, md: 3 }}
                                    sx={{
                                      pl: { md: 2 },
                                      mt: { xs: 2 }
                                    }}
                                  >
                                    <Input
                                      variant="outlined"
                                      required
                                      label={t("labels__folio_add_charge_quantity")}
                                      type="number"
                                      inputProps={{ min: 1 }}
                                      value={formik.values.orderItems[index].quantity}
                                      onChange={(e) => {
                                        props.form.handleChange(e);
                                      }}
                                      id={`orderItems.[${index}].quantity`}
                                      name={`orderItems.[${index}].quantity`}
                                      error={
                                        formik.errors.orderItems &&
                                        (
                                          formik.errors.orderItems[
                                            index
                                          ] as FormikErrors<AddOrderItemForm>
                                        )?.quantity
                                      }
                                    />
                                  </Grid2>
                                  <Grid2
                                    size={{ xs: 12, md: 3 }}
                                    sx={{
                                      pl: { md: 2 },
                                      mt: { xs: 2 }
                                    }}
                                  >
                                    <AmountInput
                                      required
                                      key={formik.values.orderItems[index].quantity}
                                      id={`orderItems.[${index}].amount`}
                                      name={`orderItems.[${index}].amount`}
                                      label={t("labels__price")}
                                      value={formik.values.orderItems[index].amount}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.orderItems &&
                                        (
                                          formik.touched.orderItems[
                                            index
                                          ] as FormikErrors<AddOrderItemForm>
                                        )?.amount &&
                                        formik.errors.orderItems &&
                                        (
                                          formik.errors.orderItems[
                                            index
                                          ] as FormikErrors<AddOrderItemForm>
                                        )?.amount
                                      }
                                    />
                                  </Grid2>
                                </Grid2>
                                <Grid2
                                  size={{ xs: 12 }}
                                  sx={{
                                    mt: { xs: 2 },
                                    mb: 1
                                  }}
                                >
                                  <Input
                                    variant="outlined"
                                    label={t("labels__charge_note")}
                                    value={orderItem.note}
                                    onChange={formik.handleChange}
                                    id={`orderItems.[${index}].note`}
                                    name={`orderItems.[${index}].note`}
                                    error={
                                      formik.touched.orderItems &&
                                      (
                                        formik.touched.orderItems[
                                          index
                                        ] as FormikErrors<AddOrderItemForm>
                                      )?.note &&
                                      formik.errors.orderItems
                                        ? (
                                            formik.errors.orderItems[
                                              index
                                            ] as FormikErrors<AddOrderItemForm>
                                          )?.note
                                        : undefined
                                    }
                                  />
                                </Grid2>
                              </Grid2>
                            );
                          }
                        )}
                        <Button
                          onClick={() => props.push(emptyOrderItem)}
                          disableRipple
                          disableFocusRipple
                          sx={{ mt: 1, color: theme.palette.accent.main, fontWeight: "bold" }}
                        >
                          <Plus />
                          {t("buttons__add_another_charge")}
                        </Button>
                      </>
                    );
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Grid2 container sx={{ width: "100%" }}>
                  <DialogActionModal title={t("labels__folio_add_charge")} mt={2} />
                  <Button
                    variant="secondary"
                    onClick={onClose}
                    fullWidth
                    sx={{ marginTop: theme.spacing(2) }}
                  >
                    {t("buttons__cancel")}
                  </Button>
                </Grid2>
              </DialogActions>
            </Form>
          </Grid2>
        </DialogModal>
      )}
    </Formik>
  );
};
