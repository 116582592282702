import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";

interface ChatItemContentVideoProps extends ChatItemContentBaseProps {
  caption?: string;
}

export const ChatItemContentVideo: FC<ChatItemContentVideoProps> = ({
  content,
  caption,
  isLoggedUser
}) => {
  const { palette } = useTheme();

  return (
    <>
      <video width="100%" controls>
        <source src={content} />
      </video>
      <Paragraph
        sx={{
          color: !isLoggedUser ? palette.background.paper : palette.primary.main
        }}
      >
        {caption}
      </Paragraph>
    </>
  );
};
