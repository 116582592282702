import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2,
  IconButton,
  useTheme
} from "@mui/material";
import React, { FC, useCallback, useContext } from "react";
import { Heading3, TenantContext } from "@likemagic-tech/sv-magic-library";
import Download from "../../../icons/Download";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaymentStatus, PaymentStatusFlag } from "./payment-status.flag";
import { ReservationDetailsDTO } from "../../../store/endpoints/reservation-table.endpoints";
import { useProperty } from "src/hooks/use-property";
import { isFolioClosed, isFolioDownloadable } from "./payment-utils";
import { FolioItemDetails } from "./folio-item-details";
import { PaymentFoliosActions } from "./payment-folios-actions";
import { useDownloadInvoice } from "src/hooks/use-download-invoice";

interface PaymentFoliosPreviewProps {
  reservation: ReservationDetailsDTO;
  propertyId: string;
}
export const PaymentFoliosPreview: FC<PaymentFoliosPreviewProps> = ({
  reservation,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { selectedProperty } = useProperty();
  const [downloadInvoice] = useDownloadInvoice();
  const { pms } = useContext(TenantContext);

  const downloadFolio = useCallback(
    (pmsPropertyId: string, pmsFolioId: string, pmsReservationId: string, isClosed: boolean) => {
      downloadInvoice({ pmsPropertyId, pmsFolioId, pmsReservationId, isClosed });
    },
    [downloadInvoice]
  );

  return (
    <Box>
      {reservation.folios.map((folio) => (
        <Accordion
          sx={{
            boxShadow: "none",
            borderRadius: 2,
            mb: 2,
            "&:before": {
              display: "none"
            }
          }}
          defaultExpanded={!isFolioClosed(folio)}
          key={folio.id}
          disableGutters
        >
          <AccordionSummary
            sx={{ px: 3, py: 1.5, border: "none" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="last-reservations"
            id="last-reservations"
          >
            <Grid2 container justifyContent="space-between" direction="row" width="100%">
              <Grid2>
                <Grid2 container direction="row" gap={2} alignItems="center">
                  <Grid2>
                    <Heading3>{`${t(isFolioClosed(folio) ? "labels__invoice" : "labels__folio")} ${
                      folio.number
                    }`}</Heading3>
                  </Grid2>
                  <Grid2>
                    <PaymentStatusFlag
                      status={
                        folio.balance.amount * -1 < 0
                          ? PaymentStatus.OPEN
                          : folio.balance.amount === 0
                            ? PaymentStatus.PAID
                            : PaymentStatus.BALANCE
                      }
                    />
                  </Grid2>
                  {isFolioDownloadable(pms, folio) && (
                    <Grid2>
                      <IconButton
                        sx={{ px: 0 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadFolio(
                            propertyId,
                            isFolioClosed(folio) ? folio.id : folio.number,
                            reservation.id,
                            isFolioClosed(folio)
                          );
                        }}
                      >
                        <Download sx={{ color: theme.palette.accent.main }} />
                      </IconButton>
                    </Grid2>
                  )}
                </Grid2>
              </Grid2>
              <Box onClick={(e) => e.stopPropagation()}>
                <Grid2 display="flex-end">
                  <PaymentFoliosActions folio={folio} />
                </Grid2>
              </Box>
            </Grid2>
          </AccordionSummary>

          <AccordionDetails sx={{ p: 3, pt: 0 }}>
            {selectedProperty?.propertyId && selectedProperty?.details.timeZone && (
              <FolioItemDetails
                folio={folio}
                magicId={reservation.magicId}
                propertyId={selectedProperty?.propertyId}
                propertyTimeZone={selectedProperty?.details.timeZone}
                folios={reservation.folios}
                reservation={reservation}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
