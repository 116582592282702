import { FC, PropsWithChildren, ReactNode } from "react";
import { Box, Grid2, useTheme } from "@mui/material";

interface FieldWithIconProps {
  icon: ReactNode;
  alignTop?: boolean;
}

export const FieldWithIcon: FC<PropsWithChildren<FieldWithIconProps>> = ({
  icon,
  children,
  alignTop
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: alignTop ? "flex-start" : "center"
      }}
    >
      <Box
        sx={{
          color: theme.palette.text.secondary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: alignTop ? 0.5 : 0
        }}
      >
        {icon}
      </Box>
      <Grid2
        container
        sx={{
          gap: 1,
          alignItems: alignTop ? "flex-start" : "center",
          width: "100%"
        }}
      >
        {children}
      </Grid2>
    </Box>
  );
};
