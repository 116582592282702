import { FC, useCallback, useRef, useState } from "react";
import { Box, Card, Grid2, useTheme } from "@mui/material";
import {
  Caption,
  formatDateTime,
  Heading3,
  Heading4,
  KeySlider,
  ParagraphBold,
  ParagraphSmall,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { BoxItem, BoxState, openBox, updateBoxState } from "../../slices/box.slice";
import { useDispatch } from "../../store";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { BoxOpenMode } from "./box-events";
import { unwrapResult } from "@reduxjs/toolkit";
import { useProperty } from "../../hooks/use-property";
import { getI18nSelectedLanguage } from "../../utils/language";

const buttonMapper = {
  [BoxState.RETURNED]: "info",
  [BoxState.AVAILABLE]: "success",
  [BoxState.UNAVAILABLE]: "error",
  [BoxState.UNDER_REVIEW]: "warning",
  [BoxState.BOOKED]: "warning"
};

const mapBoxStateToMode = {
  [BoxState.RETURNED]: BoxOpenMode.HOUSEKEEPING_REVIEW,
  [BoxState.AVAILABLE]: BoxOpenMode.HOUSEKEEPING_CHECK,
  [BoxState.UNAVAILABLE]: BoxOpenMode.HOUSEKEEPING_REFILL,
  [BoxState.BOOKED]: BoxOpenMode.HOUSEKEEPING_REFILL,
  [BoxState.UNDER_REVIEW]: BoxOpenMode.HOUSEKEEPING_REVIEWED_REFILL
};

export const BoxCard: FC<{
  box: BoxItem;
  refreshList: () => void;
  propertyId: string;
}> = ({ box, refreshList, propertyId }) => {
  const { t } = useTranslationWrapper();
  const [unlocked, setUnlocked] = useState(false);
  const keyRef = useRef<any>();
  const { open } = useGlobalModal();
  const { boxName, boxState, boxCollection, type, service, rentalDueDate, unitId } = box;
  const theme = useTheme();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const dispatch = useDispatch();
  const onClick = useCallback(async () => {
    const boxOpenMode = mapBoxStateToMode[box.boxState];
    try {
      await dispatch(
        openBox({
          boxId: box.boxId,
          propertyId,
          mode: boxOpenMode,
          onInit: () => setUnlocked(true)
        })
      ).then(unwrapResult);
    } catch (e: any) {
      if (e.name !== "AbortError") {
        throw e;
      }
    }

    if ([BoxOpenMode.HOUSEKEEPING_CHECK, BoxOpenMode.HOUSEKEEPING_REVIEW].includes(boxOpenMode)) {
      const result = await open({
        modalProps: {
          title: box.boxName,
          content: <>{t(`labels__box_state_modal_content_${box.boxState}`)}</>
        },
        modalActions: [BoxState.AVAILABLE, BoxState.UNDER_REVIEW].map((value) => ({
          variant: "primary",
          color: value === BoxState.AVAILABLE ? "success" : "warning",
          label: t(`labels__box_state_${value}`),
          result: value
        }))
      });

      if (result === BoxState.AVAILABLE) {
        await dispatch(updateBoxState({ boxId: box.boxId, state: result, propertyId }));
      }
    }
    refreshList();
    setUnlocked(false);
    keyRef.current?.reset?.();
  }, [refreshList, dispatch, box, propertyId, open, t]);

  return (
    <Card sx={{ p: 3, height: "100%" }}>
      <Grid2
        container
        sx={{
          alignItems: "center"
        }}
      >
        <Grid2
          sx={{
            marginRight: 2
          }}
        >
          <Heading3>{boxName}</Heading3>
        </Grid2>
        <Grid2
          sx={{
            padding: 0.5,
            border: 1,
            borderRadius: 0.5,
            borderColor: `${buttonMapper[boxState]}.light`,
            bgcolor: `${buttonMapper[boxState]}.light`,
            width: "fit-content"
          }}
        >
          <ParagraphSmall color={buttonMapper[boxState] + ".dark"}>
            {t(`labels__box_state_${boxState}`)}
          </ParagraphSmall>
        </Grid2>
      </Grid2>
      <Grid2
        container
        sx={{
          alignItems: "center"
        }}
      >
        <Grid2 size={{ xs: 6 }}>
          <Caption sx={{ color: theme.palette.text.secondary }}>
            {rentalDueDate &&
              t(`labels__box_${type}`).concat(
                ` ${formatDateTime(rentalDueDate, language, selectedProperty.details.timeZone)}`
              )}
          </Caption>
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Heading4 textAlign="right">{boxCollection ? boxCollection : unitId}</Heading4>
        </Grid2>
      </Grid2>
      <Grid2
        container
        sx={{
          alignItems: "center",
          mt: 2
        }}
      >
        <Grid2>
          <ParagraphBold>{service.name}</ParagraphBold>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          mt: 3
        }}
      >
        <KeySlider
          ref={keyRef}
          unlocked={unlocked}
          onSuccess={() => {
            onClick();
          }}
          labels={{
            slideToUnlock: t("labels__slide_to_unlock"),
            unlocked: t("labels__unlocked"),
            error: t("labels__error")
          }}
        />
      </Box>
    </Card>
  );
};
