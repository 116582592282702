import { TabContext, TabList } from "@mui/lab";
import { Badge, Grid2, Tab, useTheme } from "@mui/material";
import { FC, SyntheticEvent, useMemo, useState } from "react";
import { BoxItem, BoxState } from "src/slices/box.slice";
import { BoxCard } from "./box-card";
import { useProperty } from "src/hooks/use-property";
import { ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";
import { boxTabsBadgeColor, boxesTabLabels } from "./box.utils";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

export enum BoxTabAll {
  ALL = "ALL"
}

export const BoxTabs: FC<{
  boxes: BoxItem[];
  refreshList: () => void;
}> = ({ boxes, refreshList }) => {
  const { selectedProperty } = useProperty();
  const [value, setValue] = useState<BoxTabAll | BoxState>(BoxTabAll.ALL);
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue as BoxTabAll);
  };

  const numberOfBoxes = (tab: BoxState) => {
    return boxes.filter((item) => item.boxState === tab).length;
  };

  const filteredBoxes = useMemo(() => {
    let boxesArray = boxes;
    if (value !== BoxTabAll.ALL) {
      boxesArray = boxes.filter((item) => item.boxState === value);
    }
    return boxesArray.map((box) => (
      <Grid2 size={{ xs: 12, md: 4 }} key={`${box.boxId}`}>
        <BoxCard
          box={box}
          refreshList={refreshList}
          propertyId={selectedProperty?.propertyId ?? ""}
        />
      </Grid2>
    ));
  }, [value, boxes, refreshList, selectedProperty?.propertyId]);

  return (
    <Grid2
      container
      sx={{
        mt: 2,
        justifyContent: "space-between"
      }}
    >
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="Boxes tabs" variant="scrollable">
          <Tab
            label={
              <ParagraphBold sx={{ color: grey[600] }}>{t("labels__boxes_tab_ALL")}</ParagraphBold>
            }
            value={BoxTabAll.ALL}
            key={BoxTabAll.ALL}
            sx={{
              textTransform: "none"
            }}
          />
          {Object.values(BoxState).map((item) => (
            <Tab
              label={
                <Badge
                  sx={{
                    "& .MuiBadge-badge": {
                      right: spacing(-2),
                      bgcolor: `${boxTabsBadgeColor(item)}.dark`
                    }
                  }}
                  badgeContent={numberOfBoxes(item)}
                >
                  <ParagraphBold sx={{ color: grey[600] }}>{boxesTabLabels(item, t)}</ParagraphBold>
                </Badge>
              }
              value={item}
              key={item}
              sx={{
                paddingRight: 5,
                textTransform: "none"
              }}
            />
          ))}
        </TabList>
      </TabContext>
      <Grid2
        container
        size={{ xs: 12 }}
        spacing={2}
        sx={{
          pb: 3,
          paddingTop: 3
        }}
      >
        {filteredBoxes}
      </Grid2>
    </Grid2>
  );
};
