import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid2,
  IconButton
} from "@mui/material";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import AddIcon from "@mui/icons-material/Add";
import { TaskPreview } from "src/features/tasks/task-preview";
import { Task } from "src/features/tasks/domain/task";
import { useTaskModal } from "src/features/tasks/use-task-select-id";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TitleHeader } from "src/features/reservations/reservation-details/reservation-details-tabs/overview/card-header/title-header";
import { makeStyles } from "tss-react/mui";
import { Heading3 } from "@likemagic-tech/sv-magic-library";

interface ChatPreviewTasksProps {
  tasks: Task[];
  primaryReservationId: string;
}

const useStyles = makeStyles()(() => ({
  summaryContent: {
    margin: 0
  },
  summaryRoot: {
    minHeight: "auto"
  },
  root: {
    padding: 0
  }
}));

export const ChatPreviewTasks: FC<ChatPreviewTasksProps> = ({ tasks, primaryReservationId }) => {
  const { t } = useTranslationWrapper();
  const { openNewTaskWithParam, openNewTask } = useTaskModal({});
  const { classes } = useStyles();
  return (
    <Accordion sx={{ boxShadow: "none" }} disableGutters defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        classes={{ content: classes.summaryContent, root: classes.summaryRoot }}
        expandIcon={tasks.length > 0 ? <ExpandMoreIcon /> : <></>}
        aria-controls="last-tasks"
        id="last-tasks"
      >
        <Heading3 sx={{ display: "flex", alignItems: "center" }}>
          <TitleHeader title={t("title__tasks")} length={tasks?.length} />
        </Heading3>
        <Box
          sx={{
            px: 2
          }}
        >
          <IconButton
            color="primary"
            onClick={() =>
              primaryReservationId
                ? openNewTaskWithParam(primaryReservationId, "RESERVATION")
                : openNewTask()
            }
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }} classes={{ root: classes.root }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {tasks.map((task) => (
            <Grid2 key={task.id}>
              <TaskPreview task={task} />
            </Grid2>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
