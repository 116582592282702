import React, { FC, ReactElement } from "react";
import { useTheme } from "@mui/material";
import { MagicFileData } from "../../../../types/magic-file";
import { Attachments } from "../../domain/task";
import { MagicFileType } from "../../../../domain/magic-file-type";
import { Chip } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

interface AttachmentsPreviewProps {
  attachment: Attachments[number];
  removeFileWithPreview: (fileName: string) => void;
  onClick?: () => void;
}

export const AttachmentsPreview: FC<AttachmentsPreviewProps> = ({
  attachment,
  removeFileWithPreview,
  onClick
}) => {
  const theme = useTheme();

  const file: MagicFileData = {
    fileName: attachment.originalFileName,
    magicFileType: MagicFileType.ATTACHMENTS,
    fileSrc: attachment.fileName,
    contentType: attachment.contentType
  };

  const getFileIcon = (contentType: string | undefined): ReactElement => {
    switch (contentType) {
      case "application/pdf":
        return <PictureAsPdfOutlinedIcon />;
      case "image/jpeg":
      case "image/png":
      case "image/jpg":
      case "image/gif":
      case "image/svg+xml":
        return <ImageOutlinedIcon />;
      default:
        return <></>;
    }
  };

  return (
    <Chip
      sx={{ maxWidth: theme.spacing(15) }}
      variant="outlined"
      color="default"
      onClick={onClick}
      rightIcon={
        <CloseIcon
          sx={{ fontSize: "14px !important", color: `${theme.palette.grey[500]} !important` }}
        />
      }
      onRightIconClick={() => removeFileWithPreview(attachment?.uuid)}
      leftIcon={getFileIcon(file.contentType)}
      label={file?.fileName}
    />
  );
};
