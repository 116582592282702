import React, { FC, PropsWithChildren } from "react";
import { Grid2 } from "@mui/material";
import { Button } from "@likemagic-tech/sv-magic-library";
import { ChevronRight } from "@mui/icons-material";

interface ButtonWithChevronProps {
  onClick: () => void;
}

export const ButtonWithChevron: FC<PropsWithChildren<ButtonWithChevronProps>> = ({
  onClick,
  children
}) => {
  return (
    <Button
      size="small"
      fullWidth
      sx={{ textAlign: "left", display: "block", pr: 0 }}
      variant="ghost"
      onClick={onClick}
    >
      <Grid2 py={1} container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <Grid2 size={{ xs: 11 }} container alignItems="flex-start" wrap="nowrap" direction="column">
          {children}
        </Grid2>

        <Grid2 size={{ xs: 1 }} textAlign="center">
          <ChevronRight fontSize="small" />
        </Grid2>
      </Grid2>
    </Button>
  );
};
