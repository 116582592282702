import { ChangeEvent, FC, MouseEvent, useMemo, useState } from "react";
import { ProfileDrawerCards } from "./profile-drawer-cards/profile-drawer-cards";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { TransactionsForProfile } from "../types/profile";
import { Grid2, Paper, Table, TableBody, TableContainer, TablePagination } from "@mui/material";
import { EmptyState, EmptyStateSize, Heading4 } from "@likemagic-tech/sv-magic-library";
import { ProfileDrawerTransactionTableRow } from "./profile-drawer-cards/profile-drawer-transaction-table-row";
import { useGetOrderItemsPageQuery } from "src/graphql/queries/GetOrderItemsPage.generated";
import { Order, OrderEnum } from "src/types/pageable";
import { useProperty } from "src/hooks/use-property";
import { EnhancedTableHead, HeadCell } from "src/components/table/enhanced-table-head";

interface ProfileDrawerTransactionProps {
  profileId?: number;
}

const headCells: HeadCell<TransactionsForProfile>[] = [
  {
    id: "pmsReservationId",
    label: "labels__reservationId"
  },
  { id: "name", label: "labels__profiles_item", disableSort: true },
  {
    id: "start",
    label: "labels__profiles_date"
  },
  {
    id: "price",
    label: "labels__reservation__details__overview__services__price"
  }
];

export const ProfileDrawerTransactions: FC<ProfileDrawerTransactionProps> = ({ profileId }) => {
  const { t } = useTranslationWrapper();
  const { selectedPropertyIds } = useProperty();
  const [orderTransactions, setOrderTransactions] = useState<Order>(OrderEnum.DESC);
  const [orderByTransactions, setOrderByTransactions] =
    useState<keyof TransactionsForProfile>("name");
  const [transactionsPage, setTransactionsPage] = useState(0);
  const [transactionsRowsPerPage, setTransactionsRowsPerPage] = useState(25);

  const handleChangePage = (event: unknown, newPage: number) => {
    setTransactionsPage(newPage);
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setTransactionsRowsPerPage(parseInt(event.target.value, 10));
    setTransactionsPage(0);
  };

  const transactionPageable = useMemo(
    () => ({
      pageNumber: transactionsPage,
      pageSize: transactionsRowsPerPage,
      sort: `${orderByTransactions},${orderTransactions}`
    }),
    [orderByTransactions, orderTransactions, transactionsRowsPerPage, transactionsPage]
  );

  const handleTransactionsRequestSort = (
    event: MouseEvent<unknown>,
    orderByTransactions: keyof TransactionsForProfile
  ) => {
    setOrderTransactions(orderTransactions === "desc" ? "asc" : "desc");
    setOrderByTransactions(orderByTransactions);
  };

  const { data: transactionsData } = useGetOrderItemsPageQuery(
    {
      filter: {
        pageable: transactionPageable,
        pmsPropertyIds: selectedPropertyIds,
        userProfileId: profileId
      }
    },
    { skip: !selectedPropertyIds?.length || !profileId }
  );

  const transactionRows = useMemo(
    () => transactionsData?.GetOrderItemsPage.content,
    [transactionsData?.GetOrderItemsPage]
  );

  const transactionTotalElements = useMemo(
    () => transactionsData?.GetOrderItemsPage.totalElements,
    [transactionsData?.GetOrderItemsPage]
  );

  return (
    <ProfileDrawerCards>
      <Grid2 size={{ xs: 12 }}>
        <Paper elevation={0}>
          <Grid2
            container
            direction="column"
            sx={{
              justifyContent: "space-between"
            }}
          >
            <Grid2
              sx={{
                alignItems: "top",
                p: 3
              }}
            >
              <Heading4>{t("labels__profiles_transactions")}</Heading4>
            </Grid2>
            {transactionRows && transactionRows.length > 0 ? (
              <Grid2 container direction="column">
                <TableContainer>
                  <Table>
                    <EnhancedTableHead<TransactionsForProfile>
                      headCells={headCells}
                      order={orderTransactions}
                      orderBy={orderByTransactions}
                      onRequestSort={handleTransactionsRequestSort}
                    />
                    <TableBody>
                      {transactionRows?.map((item) => (
                        <ProfileDrawerTransactionTableRow key={item.pmsId} item={item} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={transactionTotalElements ?? 0}
                  rowsPerPage={transactionsRowsPerPage}
                  page={transactionsPage}
                  labelRowsPerPage={t("labels__rows_per_page")}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t("labels__of")} ${count}`
                  }
                />
              </Grid2>
            ) : (
              <EmptyState
                title={t("labels__profile_no_transaction_information")}
                size={EmptyStateSize.LARGE}
              />
            )}
          </Grid2>
        </Paper>
      </Grid2>
    </ProfileDrawerCards>
  );
};
