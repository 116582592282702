import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import { FC } from "react";
import eachDayOfInterval from "date-fns/eachDayOfInterval";

import { useProperty } from "../../hooks/use-property";
import {
  DeparturesAndCleanings,
  DeparturesAndCleaningsItem
} from "../../domain/departures-and-cleanings-forecast";
import { sub } from "date-fns";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { formatDate, formatDateForBE } from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "src/utils/language";

export interface ForecastProps {
  startDate: Date;
  endDate: Date;
  departuresAndCleaningsForecast: DeparturesAndCleanings;
}

export const Forecast: FC<ForecastProps> = ({
  startDate,
  endDate,
  departuresAndCleaningsForecast
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const theme = useTheme();

  const forecastData: DeparturesAndCleaningsItem[] = getFullForecastData(
    startDate,
    endDate,
    departuresAndCleaningsForecast
  );

  return (
    <TableContainer>
      <Table
        sx={{
          tableLayout: "fixed",
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(2)
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell hidden>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 700
                }}
              >
                {t("labels__housekeeping_forecast_date")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: "bold"
                }}
              >
                {t("labels__housekeeping_forecast_departures")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: "bold"
                }}
              >
                {t("labels__housekeeping_forecast_scheduled_cleaning")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: 700
                }}
              >
                {t("labels__housekeeping_forecast_summary")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecastData.map((row) => (
            <TableRow key={`row-item-${row.date}-${row.departures}`}>
              <TableCell component="th" scope="row">
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold"
                  }}
                >
                  {formatDate(row.date, language, selectedProperty?.details?.timeZone || "CET")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ color: theme.palette.text.primary }} variant="body2">
                  {row.departures}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ color: theme.palette.text.primary }} variant="body2">
                  {row.cleanings}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold"
                  }}
                >
                  {row.summary}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const getFullForecastData = (
  startDate: Date,
  endDate: Date,
  forecastWithEvents: DeparturesAndCleanings
): DeparturesAndCleaningsItem[] => {
  return eachDayOfInterval({
    start: startDate,
    // endDate is exlusive on BE
    end: sub(endDate, { days: 1 })
  }).map((currentDate) => {
    const formattedCurrentDate = formatDateForBE(currentDate);

    return forecastWithEvents[formattedCurrentDate]
      ? {
          date: formattedCurrentDate,
          cleanings: forecastWithEvents[formattedCurrentDate].cleanings,
          departures: forecastWithEvents[formattedCurrentDate].departures,
          summary: forecastWithEvents[formattedCurrentDate].summary
        }
      : {
          date: formattedCurrentDate,
          cleanings: 0,
          departures: 0,
          summary: 0
        };
  });
};
