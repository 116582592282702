import { Box, Button, Grid2, Paper, useTheme } from "@mui/material";
import { FC, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { NotificationCardInfo } from "../overview/components/notifications/notification-card-info";
import { EmptyState, EmptyStateSize } from "@likemagic-tech/sv-magic-library";
import AddIcon from "@mui/icons-material/Add";
import { NotificationSendModal } from "../overview/components/notifications/notification-send-modal";

export const ReservationDetailsNotifications: FC<{
  reservation: ReservationDetailsDTO;
}> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Box sx={{ py: 2 }}>
      <Grid2
        sx={{
          pb: 2,
          display: "flex",
          justifyContent: "end"
        }}
      >
        <Button variant="primary" onClick={handleOpen}>
          <Grid2 container direction="row" alignItems="center">
            <AddIcon
              fontSize="small"
              sx={{
                color: theme.palette.background.paper,
                marginRight: theme.spacing(2)
              }}
            />
            {t("labels__send_new_notification")}
          </Grid2>
        </Button>
      </Grid2>
      <Grid2>
        {reservation.sentNotifications.length > 0 ? (
          <Grid2 container spacing={2}>
            {reservation.sentNotifications.map((notification) => (
              <Grid2 size={{ xs: 12 }} key={notification.id}>
                <Paper>
                  <NotificationCardInfo notificationInfo={notification} reservation={reservation} />
                </Paper>
              </Grid2>
            ))}
          </Grid2>
        ) : (
          <EmptyState
            title={t("labels__reservation__no__notification")}
            size={EmptyStateSize.LARGE}
          />
        )}
      </Grid2>
      {openModal && <NotificationSendModal reservation={reservation} onClose={handleClose} />}
    </Box>
  );
};
