import { PaletteOptions } from "@mui/material/styles";
import { generateErrorWarnInfoColors } from "@likemagic-tech/sv-magic-library";

export const palette: PaletteOptions = {
  primary: {
    main: "#000000"
  },
  secondary: {
    main: "#000000"
  },
  background: {
    default: "#EEEEEE",
    paper: "#FFFFFF"
  },
  text: {
    primary: "#000000",
    secondary: "#9E9E9E"
  },
  ...generateErrorWarnInfoColors(),
  action: {
    disabledOpacity: 0
  },
  grey: {
    "400": "#BDBDBD",
    "600": "#757575"
  },
  accent: {
    main: "#0000DC",
    dark: "#0000DC",
    light: "#EBEBFF"
  }
};
