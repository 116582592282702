import { TypographyOptions } from "@mui/material/styles/createTypography";

const likemagicFontFamilyTitle = "Matter-SemiBold";
const likemagicFontFamilyBody = "Matter-Regular";

export const typography: TypographyOptions = {
  fontFamily: likemagicFontFamilyBody,
  h1: {
    fontStyle: "normal",
    fontFamily: likemagicFontFamilyTitle,
    fontSize: "24px",
    lineHeight: "36px"
  },
  h2: {
    fontStyle: "normal",
    fontFamily: likemagicFontFamilyTitle,
    fontSize: "22px",
    lineHeight: "33px"
  },
  h3: {
    fontStyle: "normal",
    fontFamily: likemagicFontFamilyTitle,
    fontSize: "20px",
    lineHeight: "30px"
  },
  h4: {
    fontStyle: "normal",
    fontFamily: likemagicFontFamilyTitle,
    fontSize: "18px",
    lineHeight: "22px"
  },
  subtitle1: {
    fontStyle: "normal",
    fontFamily: likemagicFontFamilyBody,
    fontSize: "24px",
    lineHeight: "28px"
  },
  body1: {
    fontFamily: likemagicFontFamilyBody,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    paddingBottom: "0px"
  },
  body2: {
    fontFamily: likemagicFontFamilyBody,
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    paddingBottom: "0px"
  },
  caption: {
    fontFamily: likemagicFontFamilyBody,
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    paddingBottom: "0px"
  }
};
