const LOCAL_STORAGE_SHARE_HERE_SECOND_SCREEN_ID = "share_here_second_screen_id";

export const getShareHereSecondScreenId = () => {
  const storageValue = localStorage.getItem(LOCAL_STORAGE_SHARE_HERE_SECOND_SCREEN_ID);

  if (!storageValue) {
    return undefined;
  }

  const parsedStorageValue = JSON.parse(storageValue);

  return (
    Object.keys(storageValue).length !== 0 ? parsedStorageValue.shareHereSecondScreenId : undefined
  ) as string;
};

export const setShareHereSecondScreenId = (shareHereSecondScreenId: string | null) => {
  try {
    if (shareHereSecondScreenId) {
      localStorage.setItem(
        LOCAL_STORAGE_SHARE_HERE_SECOND_SCREEN_ID,
        JSON.stringify({ shareHereSecondScreenId })
      );
    }
  } catch (e) {
    console.error("Failed to set share here second screen id in local storage", e);
  }
};
