import { Box, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import React, { FC, useCallback, useMemo, useState } from "react";
import { Counter, formatToIsoDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { Dialog } from "./dialog";
import { PricePreview } from "../../../../../../components/price-preview/price-preview";
import { ServiceModalsProps } from "./types";
import { multiplyPrices } from "../../../../../../utils/price";
import { DateAndCount } from "../../../../../../domain/service";
import { emptyPrice } from "../../../../../../domain/price";

const useStyles = makeStyles()(() => ({
  dialogContentContainer: {
    alignSelf: "center"
  },
  counter: {
    "& div": {
      "& p": {
        fontSize: 28
      }
    },
    "& button": {
      padding: 0,
      borderRadius: 40,
      height: 40,
      width: 40,
      "& svg": {
        width: "100%",
        height: "100%"
      }
    }
  }
}));

export const CounterPickerDialog: FC<ServiceModalsProps> = ({
  onClose,
  onChange,
  reservation,
  service
}) => {
  const { t } = useTranslationWrapper();
  const availableDays: Date[] = (service.bookableDates || []).map(
    (dateAndCount: DateAndCount) => new Date(dateAndCount.date) // TODO LM-374 use proper object
  );
  const countFirstDay = useMemo(
    () => (reservation?.adultsCount ?? 0) + (reservation?.childrenCount ?? 0),
    [reservation?.adultsCount, reservation?.childrenCount]
  );
  const [count, setCount] = useState<number>(countFirstDay);

  const { classes } = useStyles();

  const handleChange = useCallback(() => {
    if (count === 0) {
      onChange({ values: [] });
      return;
    }
    const values = availableDays.map((date) => ({
      date: formatToIsoDate(date),
      count: count
    }));
    onChange({ values });
  }, [onChange, count, availableDays]);

  return (
    <Dialog
      open
      onConfirm={handleChange}
      onDismiss={onClose}
      title={t("labels__wizard_counter_picker_title")}
      content={
        <Box className={classes.dialogContentContainer}>
          <Box
            sx={{
              py: 2
            }}
          >
            <Paragraph textAlign={"center"} pb={1}>
              {t("labels__wizard_counter_label")}
            </Paragraph>
            <Counter
              classes={{ root: classes.counter }}
              initialCount={countFirstDay}
              // user has to be able to go to 0, in order to clear the cart
              minValue={0}
              maxValue={service.maximum}
              onCountChange={setCount}
            />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
              px: 2.5,
              py: 2
            }}
          >
            <PricePreview
              price={multiplyPrices(service?.price ?? emptyPrice(), availableDays.length * count)}
            />
          </Box>
        </Box>
      }
      buttonLabel={t("labels__wizard_confirm")}
    />
  );
};
