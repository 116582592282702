import { Unit, UnitCondition } from "src/domain/Unit";
import { Grid2, useTheme } from "@mui/material";
import { maintenanceTypeColorScheme, maintenanceTypeIcon } from "./maintenance-utils";
import { grey } from "@mui/material/colors";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import React, { FC } from "react";
import { MaintenanceSlotStatus, MaintenanceSlotType } from "../graphql-tasks/generated/graphql";

const prepareUnitsArrayToDisplay = (units: Unit[], attributesOption: string[]) =>
  units
    ? units.filter((unit) => {
        const unitAttributes = (unit.attributes ?? []).reduce<string[]>(
          (acc, cur) => [...acc, cur.id],
          []
        );
        return attributesOption
          ? attributesOption.every((attribute) => unitAttributes?.includes(attribute))
          : true;
      })
    : [];

export const mapLabelsToUnitsForAssignRoom = (
  units: Unit[],
  t: (s: string) => string,
  attributesOption: string[] = []
) =>
  prepareUnitsArrayToDisplay(units, attributesOption)
    .map((unit) => ({
      id: unit.id,
      label: t("labels__unit") + " " + unit.name,
      maintenance: unit.maintenanceSlots?.filter(
        (maintenance) => maintenance.status === MaintenanceSlotStatus.Ongoing
      )[0]?.type,
      status: { condition: unit.status.condition, occupied: unit.status.occupied }
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const findUnitWithLabel = (units: Unit[], unitId: string, t: (s: string) => string) => {
  const unit = units.find((unit) => unit.id === unitId);
  return {
    id: unit?.id ?? "",
    label:
      unit?.name +
      " " +
      t(`labels__unit_condition_${unit?.status.condition}`) +
      " " +
      t(`labels__unit_${unit?.status.occupied ? "occupied" : "free"}`)
  };
};

interface BoxLabelForMaintenanceAndStatusProps {
  maintenanceType: MaintenanceSlotType | undefined;
  status?: {
    condition: UnitCondition;
    occupied: boolean;
  };
  t: (key: string) => string;
}
export const BoxLabelForMaintenanceAndStatus: FC<BoxLabelForMaintenanceAndStatusProps> = ({
  maintenanceType,
  status,
  t
}: BoxLabelForMaintenanceAndStatusProps) => {
  const { spacing } = useTheme();
  return (
    <Grid2
      container
      sx={{
        alignItems: "center",
        borderRadius: 1.5,
        backgroundColor: maintenanceTypeColorScheme(maintenanceType, status)
          ? maintenanceTypeColorScheme(maintenanceType, status) + ".light"
          : grey[300],
        px: 1,
        height: spacing(3.5)
      }}
    >
      {maintenanceType && (
        <Grid2
          sx={{
            mr: 0.5,
            mt: 0.5
          }}
        >
          {maintenanceTypeIcon(maintenanceType)}
        </Grid2>
      )}
      <Grid2>
        <ParagraphSmall
          sx={{
            color: maintenanceTypeColorScheme(maintenanceType, status)
              ? maintenanceTypeColorScheme(maintenanceType, status) + ".dark"
              : grey[700]
          }}
        >
          {maintenanceType
            ? t(`labels__maintenance_${maintenanceType}`)
            : t(`labels__unit_condition_${status?.condition}`) +
              " " +
              "&" +
              " " +
              t(`labels__unit_${status?.occupied ? "occupied" : "free"}`)}
        </ParagraphSmall>
      </Grid2>
    </Grid2>
  );
};
