import { FC } from "react";
import { Box, Grid2 } from "@mui/material";
import PageTitle from "../components/page-title";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { ReleaseNotes } from "src/features/release-notes/release-notes";

const ReleaseNotesPage: FC = () => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        sx={{
          px: isMobile ? 0 : 3,
          py: isMobile ? 0 : 4
        }}
      >
        {!isMobile && (
          <Grid2
            container
            spacing={3}
            size={{ xs: 12 }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Grid2
              sx={{
                mb: 2.5
              }}
            >
              <PageTitle
                title={t("labels__release_notes")}
                subTitle={`${t("release__what__new")}?`}
              />
            </Grid2>
          </Grid2>
        )}
        <ReleaseNotes />
      </Box>
    </>
  );
};

export default ReleaseNotesPage;
