import {
  Button,
  formatDate,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  TenantContext
} from "@likemagic-tech/sv-magic-library";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid2,
  useTheme
} from "@mui/material";
import { FC, useCallback, useContext } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { PrismicRichText } from "@prismicio/react";
import { useProperty } from "src/hooks/use-property";
import { ReleaseNotes } from "../../components/dashboard/release-note-popover";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ReleaseNotesCardProps {
  releaseNotes: Array<ReleaseNotes>;
  page?: boolean;
}

export const ReleaseNotesCard: FC<ReleaseNotesCardProps> = ({ releaseNotes, page }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { urls } = useContext(TenantContext);
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const openFeedbackWindow = useCallback(() => {
    window.open(urls.magicSuggestFeatureUrl);
  }, [urls]);

  return (
    <>
      {releaseNotes.map((notes: ReleaseNotes) => (
        <Box {...(page ? { p: 3, paddingBottom: 2 } : { p: 1 })} key={notes?.title}>
          <Card sx={{ borderRadius: theme.spacing(2) }} key={notes?.title}>
            <CardHeader
              title={
                page ? (
                  <>
                    <Grid2
                      container
                      sx={{
                        alignItems: "baseline",
                        py: 0.5,
                        px: 1
                      }}
                    >
                      <Grid2
                        sx={{
                          paddingRight: theme.spacing(6)
                        }}
                      >
                        <ParagraphBold>{notes.title}</ParagraphBold>
                      </Grid2>
                      <Grid2>
                        <ParagraphSmall>
                          {formatDate(notes.date, language, selectedProperty?.details.timeZone)}
                        </ParagraphSmall>
                      </Grid2>
                    </Grid2>
                  </>
                ) : (
                  <>
                    <ParagraphSmall>
                      {formatDate(notes?.date, language, selectedProperty?.details?.timeZone)}
                    </ParagraphSmall>
                    <ParagraphBold>{notes?.title}</ParagraphBold>
                  </>
                )
              }
            />
            {page && <Divider />}
            <CardContent>
              <Box {...(page ? { py: 4 } : {})}>
                <PrismicRichText
                  field={(notes?.description as any) ?? null}
                  components={{
                    paragraph: ({ children }) => (
                      <Box
                        sx={{
                          py: 1
                        }}
                      >
                        {page ? (
                          <Paragraph>{children}</Paragraph>
                        ) : (
                          <ParagraphSmall>{children}</ParagraphSmall>
                        )}
                      </Box>
                    ),
                    listItem: ({ children }) => (
                      <li style={{ marginLeft: theme.spacing(2) }}>
                        {page ? (
                          <Paragraph>{children}</Paragraph>
                        ) : (
                          <ParagraphSmall>{children}</ParagraphSmall>
                        )}
                      </li>
                    ),
                    oListItem: ({ children }) => (
                      <li
                        style={{
                          marginLeft: theme.spacing(2),
                          fontWeight: "bold"
                        }}
                      >
                        {page ? (
                          <Paragraph>{children}</Paragraph>
                        ) : (
                          <ParagraphSmall>{children}</ParagraphSmall>
                        )}
                      </li>
                    ),
                    image: ({ node }) => (
                      <Grid2
                        container
                        sx={{
                          justifyContent: "center",
                          py: 2
                        }}
                      >
                        <img
                          alt={node.alt ?? undefined}
                          src={node.url}
                          style={{
                            maxWidth: page ? "70%" : "100%",
                            maxHeight: "600px"
                          }}
                        />
                      </Grid2>
                    )
                  }}
                />
              </Box>
            </CardContent>
            {page && <Divider />}
            <CardActions>
              <Grid2
                container
                {...(page ? { py: 1 } : {})}
                sx={{
                  justifyContent: "center",
                  width: "100%"
                }}
              >
                <Grid2>
                  <Button
                    sx={{
                      backgroundColor: page
                        ? theme.palette.secondary.main
                        : theme.palette.background.default,
                      borderRadius: page ? theme.spacing(8) : theme.spacing(1),
                      width: page ? "max-width" : theme.spacing(35),
                      height: theme.spacing(5),
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: page
                          ? theme.palette.secondary.main
                          : theme.palette.background.default
                      }
                    }}
                    onClick={openFeedbackWindow}
                  >
                    <ParagraphBold
                      sx={{
                        color: page ? theme.palette.background.paper : theme.palette.text.primary,
                        textTransform: "none"
                      }}
                    >
                      {t("release__send__feedback")}
                    </ParagraphBold>
                  </Button>
                </Grid2>
              </Grid2>
            </CardActions>
          </Card>
        </Box>
      ))}
    </>
  );
};
