import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type ToggleChatBotMutationVariables = Types.Exact<{
  conversationId: Types.Scalars["String"]["input"];
  enableChatBot: Types.Scalars["Boolean"]["input"];
}>;

export type ToggleChatBotMutation = { __typename?: "Mutation"; ToggleChatBot: boolean };

export const ToggleChatBotDocument = `
    mutation ToggleChatBot($conversationId: String!, $enableChatBot: Boolean!) {
  ToggleChatBot(conversationId: $conversationId, enableChatBot: $enableChatBot)
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    ToggleChatBot: build.mutation<ToggleChatBotMutation, ToggleChatBotMutationVariables>({
      query: (variables) => ({ document: ToggleChatBotDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useToggleChatBotMutation } = injectedRtkApi;
