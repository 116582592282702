import React, { FC } from "react";
import { Button } from "@likemagic-tech/sv-magic-library";
import { Add } from "@mui/icons-material";
import { Box, Divider, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { isFolioClosed } from "./payment-utils";
import { FolioPreview } from "../../../store/endpoints/reservation-table.endpoints";
import { MultipleChargesActionsMenu } from "./action-menus/multiple-charges-actions-menu";
import { MultiplePaymentsActionsMenu } from "./action-menus/multiple-payments-actions-menu";

export enum ActionsType {
  Charge = "charge",
  Payment = "payment"
}

interface AddActionsButtonsProps {
  type: ActionsType;
  addDisabled: boolean;
  onAddClick: () => void;
  onDoneClick: () => void;
  showActions: boolean;
  folios: Array<FolioPreview>;
  folio: FolioPreview;
  reservationId: string;
  propertyId: string;
  selectedIds: string[];
}

export const AddActionsButtons: FC<AddActionsButtonsProps> = ({
  type,
  addDisabled,
  onAddClick,
  onDoneClick,
  showActions,
  folios,
  folio,
  reservationId,
  selectedIds,
  propertyId
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();

  return (
    <>
      {!showActions && (
        <Button
          sx={{
            pr: 0,
            color: theme.palette.accent.main,
            ":hover": {
              backgroundColor: "transparent"
            },
            minWidth: "unset",
            fontWeight: 700
          }}
          variant="ghost"
          disabled={addDisabled}
          startIcon={<Add />}
          disableRipple
          disableFocusRipple
          onClick={onAddClick}
        >
          {t(`buttons__add_${type}`)}
        </Button>
      )}
      {showActions && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button
            sx={{
              p: 0,
              color: theme.palette.accent.main,
              ":hover": {
                backgroundColor: "transparent"
              },
              minWidth: "unset",
              fontWeight: 700
            }}
            variant="ghost"
            disableRipple
            disableFocusRipple
            onClick={onDoneClick}
          >
            {t("buttons__done")}
          </Button>
          <Divider sx={{ transform: "rotate(90deg)", width: "20px" }} />
          {type === ActionsType.Charge && (
            <MultipleChargesActionsMenu
              reservationId={reservationId}
              propertyId={propertyId}
              orderItemPmsIds={selectedIds}
              folioId={folio.id}
              folioMetadata={folio.metadata}
              isFolioClosed={isFolioClosed(folio)}
              folios={folios}
              disabled={selectedIds.length === 0}
              onSuccess={onDoneClick}
            />
          )}
          {type === ActionsType.Payment && (
            <MultiplePaymentsActionsMenu
              reservationId={reservationId}
              paymentPmsIds={selectedIds}
              folioId={folio.id}
              folioMetadata={folio.metadata}
              isFolioClosed={isFolioClosed(folio)}
              folios={folios}
              disabled={selectedIds.length === 0}
              onSuccess={() => {
                onDoneClick();
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};
