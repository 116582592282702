import { FolioPreview } from "../../../store/endpoints/reservation-table.endpoints";
import { PmsType } from "@likemagic-tech/sv-magic-library";

export const isFolioClosed = (folio: FolioPreview) => {
  return folio.closed || Number(folio.number) > 100;
};

export const isFolioDownloadable = (pms: PmsType | undefined, folio: FolioPreview) => {
  return pms && PmsType.STAYNTOUCH !== pms && (isFolioClosed(folio) || PmsType.OHIP === pms);
};
