import { Chip, Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { Grid2, IconButton, Paper, Tooltip, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { openBanner } from "src/slices/banner.slice";
import { useDispatch } from "src/store";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { NoteForProfile } from "../types/profile";
import { ProfileAddGuestNoteModal } from "./modals/profile-add-guest-note-modal";
import { useDeleteNoteMutationEnhanced } from "src/graphql/mutations/enhanced-mutations/delete-note-enhanced";

interface ProfileNoteItemProps {
  note: NoteForProfile;
}

export const ProfileNoteItem: FC<ProfileNoteItemProps> = ({ note }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [deleteNote, result] = useDeleteNoteMutationEnhanced();
  const dispatch = useDispatch();

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }
  }, [result, dispatch, t]);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid2
        size={{ xs: 12 }}
        key={note.pmsId}
        sx={{
          paddingBottom: 1
        }}
      >
        <Paper sx={{ p: 2 }} elevation={0}>
          <Grid2 container direction="column">
            <Grid2
              sx={{
                pb: 1
              }}
            >
              <Grid2
                container
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Grid2>
                  <Chip
                    label={t(`labels__notes_category_${note.pmsProfileNoteCategoryId}`)}
                    size="large"
                    sx={{ backgroundColor: palette.info.light, color: palette.info.dark }}
                  />
                </Grid2>
                <Grid2>
                  <Grid2 container spacing={1}>
                    <Grid2>
                      <IconButton
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        <Tooltip
                          placement="top-start"
                          title={t("labels__profile_guest_note_update_tooltip")}
                        >
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid2>
                    <Grid2>
                      <IconButton onClick={() => deleteNote({ pmsProfileNoteId: note.pmsId })}>
                        <Tooltip
                          placement="top-start"
                          title={t("labels__profile_guest_note_delete_tooltip")}
                        >
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2
              sx={{
                pb: 1
              }}
            >
              <ParagraphBold>{note.title}</ParagraphBold>
            </Grid2>
            <Grid2>
              <Paragraph>{note.text}</Paragraph>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      {openModal && (
        <ProfileAddGuestNoteModal isOpen onClose={handleClose} isEditNote note={note} />
      )}
    </>
  );
};
