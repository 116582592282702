import "react-quill/dist/quill.snow.css";
import { makeStyles } from "tss-react/mui";
import React, { FC } from "react";
import { DeleteOutlined, Notes } from "@mui/icons-material";
import { Button } from "@likemagic-tech/sv-magic-library";
import ReactQuill from "react-quill";
import { FieldWithIcon } from "./field-with-icon";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Box, useTheme } from "@mui/material";
import { toolbarOptions } from "./toolbar-options";

export const useStyles = makeStyles()((theme) => ({
  textArea: {
    background: theme.palette.common.white,
    width: "100%",
    "& .ql-toolbar": {
      border: `1px solid ${theme.palette.text.secondary} !important`,
      borderBottom: "none !important",
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0 !important`
    },
    "& .ql-container": {
      border: `1px solid ${theme.palette.text.secondary} !important`,
      borderTop: "none !important",
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px !important`
    },
    "& .ql-container > .ql-editor": {
      padding: theme.spacing(2.5)
    }
  },
  container: {
    display: "flex",
    width: "100%",
    position: "relative",
    // margin bottom on desktop is 6, on tablet is 9, on mobile is 12
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(9)
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(12)
    }
  }
}));

interface DescriptionProps {
  description: string;
  onChange: (value: string) => void;
  isWysiwygVisible: boolean;
  setIsWysiwygVisible: (value: boolean) => void;
}

export const Description: FC<DescriptionProps> = ({
  description,
  onChange,
  isWysiwygVisible,
  setIsWysiwygVisible
}) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <FieldWithIcon icon={<Notes />} alignTop={isWysiwygVisible}>
      {!isWysiwygVisible ? (
        <Button variant="ghost" size="medium" onClick={() => setIsWysiwygVisible(true)}>
          Description
        </Button>
      ) : (
        <Box
          className={classes.container}
          sx={{
            ml: 2
          }}
        >
          <ReactQuill
            placeholder={t("labels__description")}
            className={classes.textArea}
            id={"description"}
            value={description ?? undefined}
            modules={{
              toolbar: toolbarOptions
            }}
            theme={"snow"}
            onChange={onChange}
            style={{ width: "100%" }}
          />
          <Box
            sx={{
              width: 0,
              position: "absolute",
              right: theme.spacing(4),
              top: theme.spacing(1),
              cursor: "pointer",
              color: theme.palette.text.secondary
            }}
            onClick={() => {
              setIsWysiwygVisible(false);
              onChange("");
            }}
          >
            <DeleteOutlined />
          </Box>
        </Box>
      )}
    </FieldWithIcon>
  );
};
