import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box, Button } from "@mui/material";
import { FC } from "react";
import { ModalWithPropActions } from "src/components/submit-modal/modal-with-prop-actions";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface SettleFolioModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const SettleFolioModal: FC<SettleFolioModalProps> = ({ isOpen, onCancel, onSubmit }) => {
  const { t } = useTranslationWrapper();

  return (
    <ModalWithPropActions
      isOpen={isOpen}
      onCancel={onCancel}
      title={t("labels__settle_folio")}
      content={
        <Box>
          <Paragraph>{t("labels__settle_folio_information")}</Paragraph>
          <Paragraph color="red">{t("labels__action_cannot_be_undone")}</Paragraph>
          <Box
            sx={{
              textAlign: "center",
              mt: 4
            }}
          >
            <Button type="submit" variant="primary" fullWidth onClick={onSubmit} sx={{ py: 1.5 }}>
              {t("labels__confirm_settle_folio")}
            </Button>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              mt: 2
            }}
          >
            <Button variant="secondary" fullWidth onClick={onCancel} sx={{ py: 1.5 }}>
              {t("labels__cancel")}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
