import React, { CSSProperties, FC, useState } from "react";
import Image from "rc-image";
import "rc-image/assets/index.css";
import { Download, RotateLeft, RotateRight, ZoomIn, ZoomOut } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { saveAs } from "file-saver";

interface ImagePreviewProps {
  link: string;
  style?: CSSProperties;
  hoverText?: string;
}

export const ImagePreview: FC<ImagePreviewProps> = ({ link, style, hoverText }) => {
  const [preview, setPreview] = useState(false);

  return (
    <Image
      style={style}
      src={link}
      preview={{
        ...(hoverText
          ? {
              mask: (
                <>
                  <ZoomIn /> {hoverText}
                </>
              )
            }
          : {}),
        zIndex: 9999,
        visible: preview,
        icons: {
          rotateLeft: <RotateLeft />,
          rotateRight: <RotateRight />,
          zoomIn: <ZoomIn />,
          zoomOut: <ZoomOut />,
          close: <CloseIcon />,
          flipY: null,
          flipX: null
        },
        onVisibleChange: (visible) => {
          setPreview(visible);
        },
        toolbarRender: (originalNode) => {
          return {
            ...originalNode,
            props: {
              ...originalNode.props,
              children: [
                ...originalNode.props.children
                  .map((item: any) => ({
                    ...item,
                    props: {
                      ...item.props,
                      style: {
                        display: "flex",
                        alignItems: "center"
                      }
                    }
                  }))
                  .filter((item: any) => !!item.props.children)
                  .reverse(),
                <Box p={1.25} key={"download-action"}>
                  <Download
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center"
                    }}
                    onClick={() => {
                      const fileName = link.slice(0, window.location.pathname.lastIndexOf("/"));
                      saveAs(link, fileName);
                    }}
                  />
                </Box>
              ]
            }
          };
        }
      }}
    />
  );
};
