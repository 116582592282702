import { FC } from "react";
import { FieldWithIcon } from "./field-with-icon";
import NumbersIcon from "@mui/icons-material/Numbers";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Box, useTheme } from "@mui/material";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface ServiceQuantityProps {
  quantity: string;
}

export const ServiceQuantity: FC<ServiceQuantityProps> = ({ quantity }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { t } = useTranslationWrapper();
  return (
    <FieldWithIcon icon={<NumbersIcon />}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          ml: 2,
          minWidth: isMobile ? "calc(100% - 16px)" : "auto"
        }}
      >
        <ParagraphSmall sx={{ color: theme.palette.text.secondary, mr: 0.5 }}>
          {t("labels__service_quantity")}
        </ParagraphSmall>
        <ParagraphSmall>{quantity}</ParagraphSmall>
      </Box>
    </FieldWithIcon>
  );
};
