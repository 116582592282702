import { FC } from "react";
import { Price } from "../../domain/price";
import { Select } from "@likemagic-tech/sv-magic-library";
import { Grid2 } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { SelectProps } from "@likemagic-tech/sv-magic-library/dist/components/select/select.types";
import { AmountInput } from "./amount-input";

export const PricePicker: FC<{
  value: Price;
  onChange: (e: any) => void;
  currencies?: SelectProps["options"];
  disabled?: boolean;
  error?: string;
  prefix?: string;
}> = ({ value, onChange, currencies, disabled, error, prefix }) => {
  const { t } = useTranslationWrapper();

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 8 }}>
        <AmountInput
          id={`${prefix}.amount`}
          name={`${prefix}.amount`}
          label={t("labels__amount")}
          value={value.amount}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      </Grid2>
      <Grid2 size={{ xs: 4 }}>
        <Select
          onChange={onChange}
          id={`${prefix}.currency`}
          name={`${prefix}.currency`}
          variant="outlined"
          value={value.currency}
          options={currencies ?? [{ value: value.currency, label: value.currency }]}
          label={t("labels__currency")}
          disabled={disabled}
        />
      </Grid2>
    </Grid2>
  );
};
