import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid2,
  IconButton,
  Paper,
  styled,
  Switch,
  Tooltip,
  useTheme
} from "@mui/material";
import {
  Button,
  CommunicationChannel,
  Paragraph,
  useGlobalModal,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { AttachFile, Check, HomeWorkOutlined, PersonOutline, Send } from "@mui/icons-material";
import { useReplyMessageMutation } from "../../graphql-messaging/mutations/ReplayMessage.generated";
import { ConversationStatus, MessageType } from "../../graphql-messaging/generated/graphql";
import { AutocompleteOption } from "../../components/autocomplete-component";
import { AssigneeActor } from "../domain/conversation";
import { useUpdateConversationMutationEnhanced } from "../../graphql-messaging/mutations/enhanced-mutations/update-conversation-enhanced";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { useEmployee } from "../../hooks/use-employee";
import { ChatInputDisabledReason, isAiBot } from "../domain/conversation-constants";
import { useChatUploadFile } from "../hooks/use-chat-upload-file";
import {
  clearMessagingUploadState,
  removeMessagingUpload,
  selectAllUploadedAttachments,
  selectAttachmentIsLoading,
  selectFileUploads,
  selectUploadIds,
  uploadMessageAttachment
} from "../../slices/messaging-attachment.slice";
import { useDispatch, useSelector } from "../../store";
import { unwrapResult } from "@reduxjs/toolkit";
import { generateUUID } from "../../utils/data.utils";
import { ErrorCode as UploadErrorCode } from "react-dropzone";
import { useInjectMessage } from "../hooks/use-inject-message";
import { AttachmentsPreview } from "../../features/tasks/task-modal/task-form/attachments-preview";
import { useProperty } from "../../hooks/use-property";
import ActionDropdown from "../../components/action-dropdown/action-dropdown";
import { getYesNoModalArg } from "../../utils/modal-util";
import { EditPropertyModal } from "./edit-property-modal";
import { isStatusFailed } from "../../domain/EntityStateStatus";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { grey } from "@mui/material/colors";
import { useToggleChatBotMutationEnhanced } from "../../graphql-messaging/mutations/enhanced-mutations/toggle-chat-bot-enhanced";

const acceptedImageTypes = ["image/jpeg", "image/png"];

const TextareaAutosize = styled(BaseTextareaAutosize)(({ theme }) => ({
  padding: theme.spacing(0.75, 1.5, 0.75, 1.5),

  borderRadius: theme.spacing(0.5),
  borderColor: grey["300"],
  width: "100%",
  fontSize: 16,
  resize: "none",
  outline: "none"
}));

export const ChatInput: FC<{
  conversationId: string;
  communicationChannel?: CommunicationChannel;
  disabledReason: ChatInputDisabledReason;
  assignee?: AssigneeActor;
  propertyId: string;
  version?: string | null;
}> = ({ conversationId, communicationChannel, disabledReason, assignee, propertyId, version }) => {
  const { t } = useTranslationWrapper();
  const [text, setText] = useState("");
  const isMobile = useIsMobile();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const theme = useTheme();
  const disabled = useMemo(() => disabledReason !== ChatInputDisabledReason.NONE, [disabledReason]);
  const { getProperty } = useProperty();
  const property = getProperty(propertyId);
  const [isChangePropertyModalOpen, setIsChangePropertyModalOpen] = useState(false);

  const [uploadError, setUploadError] = useState<UploadErrorCode | string | null>(null);
  const uploadErrorMapper: Record<UploadErrorCode, string> = {
    "file-invalid-type": t("errors__file_invalid_type"),
    "file-too-large": t("errors__too_large"),
    "file-too-small": t("errors__too_small"),
    "too-many-files": t("errors__too_many_files")
  };
  const { currentEmployee, employees } = useEmployee([propertyId]);
  const [sendMessageAction] = useReplyMessageMutation();
  const allAttachments = useSelector(selectAllUploadedAttachments);
  const attachmentLoading = useSelector(selectAttachmentIsLoading);
  const attachmentIds = useSelector(selectUploadIds);
  const fileUploads = useSelector(selectFileUploads);
  const { open: openModal } = useGlobalModal();
  const [updateConversation] = useUpdateConversationMutationEnhanced();
  const { injectMessage } = useInjectMessage();

  const { features } = usePropertyConfig({
    propertyId: propertyId
  });

  const [toggleChatBotAction] = useToggleChatBotMutationEnhanced();

  useEffect(
    () => () => {
      dispatch(clearMessagingUploadState());
      setUploadError(null);
    },
    [dispatch]
  );

  const sendMessage = useCallback(() => {
    if (!assignee) {
      updateConversation({
        conversation: {
          conversationId,
          status: ConversationStatus.InProgress,
          assignee: {
            actorId: currentEmployee?.id
          }
        }
      });
    }

    sendMessageAction({
      message: {
        type: MessageType.ConversationMessage,
        content: {
          text,
          attachmentIds
        },
        conversationId,
        pmsPropertyId: propertyId ?? undefined
      }
    }).then(() => {
      dispatch(injectMessage({ conversationId }));
      dispatch(clearMessagingUploadState());
      setText("");
    });
  }, [
    dispatch,
    conversationId,
    propertyId,
    text,
    sendMessageAction,
    assignee,
    currentEmployee?.id,
    updateConversation,
    injectMessage,
    attachmentIds
  ]);

  const sendMessageV2 = useCallback(() => {
    if (!assignee) {
      updateConversation({
        conversation: {
          conversationId,
          status: ConversationStatus.InProgress,
          assignee: {
            actorId: currentEmployee?.id
          }
        }
      });
    }
    sendMessageAction({
      message: {
        type: MessageType.ConversationMessage,
        content: {
          text: text,
          attachments: fileUploads.map((fileUpload) => ({
            fileName: fileUpload.fileName ?? "",
            mediaType: fileUpload.mediaType ?? "",
            mediaUrl: fileUpload.mediaUrl ?? ""
          }))
        },
        conversationId,
        pmsPropertyId: propertyId ?? undefined
      }
    }).then(() => {
      dispatch(injectMessage({ conversationId }));
      dispatch(clearMessagingUploadState());
      setText("");
    });
  }, [
    dispatch,
    conversationId,
    propertyId,
    text,
    sendMessageAction,
    assignee,
    currentEmployee?.id,
    updateConversation,
    injectMessage,
    fileUploads
  ]);

  const updateAssignee = useCallback(
    async (selectedId: string) => {
      await updateConversation({
        conversation: {
          conversationId,
          status: ConversationStatus.InProgress,
          assignee: {
            actorId: selectedId
          }
        }
      });
    },
    [conversationId, updateConversation]
  );

  const toggleChatBot = useCallback(() => {
    toggleChatBotAction({ conversationId: conversationId, enableChatBot: !isAiBot(assignee) });
  }, [toggleChatBotAction, conversationId, assignee]);

  const options = useMemo(() => {
    const listOfEmployee = (employees || []).map((item) => ({
      id: `${item.id}`,
      label: [item.firstName, item.lastName].join(" ")
    }));

    return !assignee
      ? listOfEmployee.concat({
          id: "",
          label: t("labels__not_assigned")
        })
      : listOfEmployee;
  }, [employees, assignee, t]);

  const { getRootProps, open, isDragActive, getInputProps } = useChatUploadFile({
    async onDropAccepted(acceptedFiles: Array<File>): Promise<void> {
      setUploadError(null);
      await Promise.all(
        acceptedFiles.map((file) => {
          return dispatch(
            uploadMessageAttachment({
              file,
              localIdentifier: generateUUID(),
              preview: acceptedImageTypes.includes(file["type"]) ? URL.createObjectURL(file) : ""
            })
          ).then(unwrapResult);
        }) ?? []
      );
    },
    onDropRejected: (fileRejections) => {
      setUploadError(fileRejections[0].errors[0].code);
    },
    allowZipUpload: communicationChannel === CommunicationChannel.EMAIL
  });
  const { onClick, ...restOfRootProps } = getRootProps();
  const removeFile = useCallback(
    (fileIdentifier: string) => {
      dispatch(removeMessagingUpload(fileIdentifier));
    },
    [dispatch]
  );

  const resolveAction = useCallback(async () => {
    updateConversation({
      conversation: {
        conversationId,
        status: ConversationStatus.Resolved
      }
    });
  }, [conversationId, updateConversation]);

  const onResolveClick = useCallback(async () => {
    const result = await openModal(
      getYesNoModalArg(
        t("labels__resolve_modal_title"),
        t("labels__resolve_modal_subtitle"),
        t("buttons__yes"),
        t("buttons__cancel")
      )
    );
    if (result) {
      resolveAction();
    }
  }, [resolveAction, openModal, t]);

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 1,
        height: "100%",
        borderBottomRightRadius: isMobile ? 0 : 4,
        borderBottomLeftRadius: isMobile ? 0 : 4
      }}
    >
      <Grid2
        container
        spacing={1}
        sx={{
          px: 2,
          py: 1,
          height: "100%"
        }}
      >
        <Grid2
          container
          spacing={1}
          width="100%"
          sx={{
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          {disabledReason !== ChatInputDisabledReason.CONVERSATION_RESOLVED &&
            features?.unifiedMessagingChatBotEnabled && (
              <Tooltip title={t("labels__notification__modal_chatbot_tooltip")} placement="top">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAiBot(assignee)}
                      onChange={() => toggleChatBot()}
                      size="small"
                    />
                  }
                  label={t("labels__notification__modal_chatbot")}
                  slotProps={{
                    typography: {
                      fontSize: 12,
                      marginRight: 0.5
                    }
                  }}
                  labelPlacement="start"
                  sx={{
                    marginLeft: 0
                  }}
                />
              </Tooltip>
            )}
          <>
            <Button
              variant="ghost"
              size="small"
              startIcon={<HomeWorkOutlined />}
              onClick={() => setIsChangePropertyModalOpen(true)}
              sx={{
                marginLeft: "auto" // push this item and following to end
              }}
            >
              {property.details.name}
            </Button>
            <EditPropertyModal
              isOpen={isChangePropertyModalOpen}
              onClose={() => setIsChangePropertyModalOpen(false)}
              conversationId={conversationId}
              propertyId={propertyId}
            />
          </>

          <ActionDropdown
            startIcon={<PersonOutline />}
            size="small"
            handleOption={updateAssignee}
            options={options as AutocompleteOption[]}
            disabled={ChatInputDisabledReason.CONVERSATION_IS_ASSIGNED_TO_AI_BOT === disabledReason}
          >
            {assignee?.displayName}
          </ActionDropdown>

          {disabledReason !== ChatInputDisabledReason.CONVERSATION_RESOLVED && (
            <Button variant="ghost" size="small" startIcon={<Check />} onClick={onResolveClick}>
              {t("labels__conversation_status_to_resolve")}
            </Button>
          )}
        </Grid2>
        {disabled ? (
          <Grid2 size={{ xs: 12 }}>
            <Paragraph sx={{ background: palette.background.default }} p={1}>
              {t(`labels__disabled_reason_${disabledReason}`)}
            </Paragraph>
          </Grid2>
        ) : (
          <Grid2 size={{ xs: 12 }}>
            <Grid2
              container
              sx={{
                alignItems: "flex-end"
              }}
            >
              <Grid2 flexGrow={1}>
                <section {...restOfRootProps}>
                  <input hidden {...getInputProps()} />
                  {isDragActive ? (
                    <Box
                      sx={{
                        height: isMobile ? 70 : 100,
                        border: "1px dashed",
                        borderRadius: `${theme.shape.borderRadius}px`
                      }}
                    >
                      <Paragraph textAlign="center" pt={isMobile ? 2.5 : 4}>
                        {t("labels__drop_file_label")}
                      </Paragraph>
                    </Box>
                  ) : (
                    <Grid2
                      container
                      sx={{
                        alignItems: "flex-end"
                      }}
                    >
                      <TextareaAutosize
                        placeholder={t("labels__send_message_placeholder")}
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                        disabled={disabled}
                        maxRows={3}
                      />
                    </Grid2>
                  )}
                </section>
                {uploadError && (
                  <FormHelperText error required>
                    {t(uploadErrorMapper[uploadError as UploadErrorCode]) ?? uploadError}
                  </FormHelperText>
                )}
              </Grid2>

              <Grid2
                size="auto"
                sx={{
                  textAlign: "right"
                }}
              >
                <Grid2
                  ml={1}
                  container
                  direction="row"
                  spacing={1}
                  sx={{
                    justifyContent: "end"
                  }}
                  wrap="nowrap"
                >
                  <IconButton
                    size="small"
                    disabled={disabled}
                    sx={{
                      color: disabled ? "disabled" : palette.primary.main
                    }}
                    onClick={open}
                    onTouchEnd={open}
                  >
                    <AttachFile sx={{ transform: "rotate(90deg)" }} />
                  </IconButton>

                  <Button
                    size="medium"
                    startIcon={<Send fontSize="small" />}
                    onClick={version === "2" ? sendMessageV2 : sendMessage}
                    disabled={attachmentLoading || disabled}
                  >
                    {t(`labels__guest__communication__channel__${communicationChannel}`)}
                  </Button>
                </Grid2>
              </Grid2>
              {allAttachments.length ? (
                <Box
                  sx={{
                    overflow: "auto",
                    overflowY: "hidden",
                    alignItems: "center",
                    display: "flex",
                    width: "100%",
                    whiteSpace: "nowrap"
                  }}
                >
                  <Grid2 mt={1} container direction="row" wrap="wrap" spacing={1}>
                    {allAttachments
                      .filter((item) => !isStatusFailed(item.status))
                      .map((item) => (
                        <Grid2 key={item.localIdentifier}>
                          <AttachmentsPreview
                            attachment={{
                              __typename: "Attachment",
                              uuid: item.localIdentifier,
                              contentType: item.fileUpload?.mediaType ?? "png",
                              fileName: item.fileUpload?.fileName ?? item.localIdentifier,
                              originalFileName: item.fileUpload?.fileName ?? ""
                            }}
                            removeFileWithPreview={removeFile}
                          />
                        </Grid2>
                      ))}
                  </Grid2>
                </Box>
              ) : null}
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </Paper>
  );
};
