import React, { FC, useCallback } from "react";
import { Grid2 } from "@mui/material";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import {
  DatePicker,
  Input,
  InputProps,
  formatDateToIso,
  TimePicker
} from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "../../../utils/language";

interface DateTimePickerInterface {
  date?: Date;
  setDate: (value: Date) => void;
  setTime: (value: Date) => void;
  time?: Date;
  label?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  minimumTime?: string;
  dateError?: string;
  variant?: "standard" | "outlined" | "filled";
  hideLabel?: boolean;
  inputFontSize?: number;
}

const DateTimePicker: FC<DateTimePickerInterface> = ({
  time,
  date,
  setTime,
  setDate,
  label,
  disabled,
  minDate,
  maxDate,
  minimumTime,
  dateError = undefined,
  variant,
  hideLabel,
  inputFontSize
}) => {
  const { t } = useTranslationWrapper();

  const handleTimeChange = (time: Date | null) => {
    if (time && date) {
      setTime(time);
    }
  };

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) {
        setDate(date);
      }
    },
    [setDate]
  );

  const isSameDate = date && minDate && date.toDateString() === minDate.toDateString();

  const customInputComponent = (props: InputProps) => (
    <Input
      {...props}
      label={label}
      fullWidth
      helperText={dateError ?? ""}
      error={!!dateError}
      variant={variant}
      slotProps={{
        input: {
          sx: {
            fontSize: inputFontSize,
            ...props.InputProps?.sx
          },
          ...props.InputProps
        },

        inputLabel: {
          sx: {
            opacity: 0.5
          }
        }
      }}
    />
  );

  return (
    <Grid2 container spacing={2}>
      {/*Key is needed to rerender DatePicker once is updated*/}
      <Grid2 size={{ xs: 6 }} key={date?.toDateString()}>
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          selectedDate={date ? formatDateToIso(date) : null}
          selectedLanguage={getI18nSelectedLanguage()}
          onChange={(date) => {
            handleDateChange(date ? new Date(date) : null);
          }}
          CustomInputComponent={customInputComponent}
        />
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        <TimePicker
          value={time ?? null}
          onChange={handleTimeChange}
          label={!hideLabel ? t("labels__time") : ""}
          variant={variant}
          minTime={isSameDate && minimumTime ? new Date(minimumTime) : undefined}
        />
      </Grid2>
    </Grid2>
  );
};

export default DateTimePicker;
