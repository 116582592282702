import * as React from "react";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { Box, Fade } from "@mui/material";
import { useDispatch } from "src/store";
import { setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { DrawerDetails } from "src/components/drawer-details/drawer-details";
import { useReservationDetails } from "src/hooks/use-reservation-details";
import { useProperty } from "src/hooks/use-property";
import { Task } from "../../../tasks/domain/task";
import { useGetRelatedToQueryEnhanced } from "../../../../graphql-tasks/query/enhanced-queries/get-related-to-enhanced";
import { useReservationDetailsId } from "./use-reservation-details-id";
import { Params } from "../../../../hooks/use-select-id";
import { ReservationStatusStepper } from "../components/reservation-status-stepper";
import { ReservationDetailsTabs } from "../reservation-details-tabs/reservation-details-tabs";
import { ReservationDetailsHeader } from "../components/reservation-details-header";

export const ReservationDetails: FC<PropsWithChildren> = () => {
  const { selectedId, setSelectId } = useReservationDetailsId();

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { selectedProperty } = useProperty();

  const data = useReservationDetails({ reservationId: selectedId ?? "" });

  const { data: tasksData } = useGetRelatedToQueryEnhanced(
    {
      pmsPropertyId: selectedProperty?.propertyId ?? "",
      pmsReservationId: selectedId ?? ""
    },
    {
      skip: !selectedProperty?.propertyId || !selectedId
    }
  );

  const tasks: Array<Task> = tasksData?.GetRelatedToTasks ?? [];

  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedId || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [selectedId, setOpenSideNavigation, isMobile]);

  return (
    <DrawerDetails
      isOpen={!!selectedId}
      onClose={() =>
        setSelectId(
          undefined,
          new URLSearchParams({
            [Params.SELECTED_TAB]: ""
          })
        )
      }
      drawerId={Params.SELECTED_RESERVATION_ID}
    >
      {data && (
        <Box sx={{ overflowY: "scroll" }}>
          <Fade in={!!data?.id}>
            <Box
              sx={{
                p: 2
              }}
            >
              <ReservationDetailsHeader reservation={data} />
              <ReservationStatusStepper reservation={data} />
              <ReservationDetailsTabs reservation={data} tasks={tasks} />
            </Box>
          </Fade>
        </Box>
      )}
    </DrawerDetails>
  );
};
