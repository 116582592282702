import React, { FC } from "react";
import { AvailableDoor } from "../../domain/available-door";
import { Button, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { useTheme } from "@mui/material";

interface OfflinePinButtonProps {
  doorData: AvailableDoor;
}

export const OfflinePinButton: FC<OfflinePinButtonProps> = ({ doorData }) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();

  return (
    <Button fullWidth disabled>
      <Paragraph sx={{ color: theme.palette.common.white }}>{`${t("labels__keys_pin")}: ${
        doorData.pin ?? ""
      }`}</Paragraph>
    </Button>
  );
};
