import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { Message } from "../domain/message";
import { Avatar, Box, Grid2, Paper } from "@mui/material";
import { UserAvatar } from "../../components/user-avatar";
import { Caption, formatDateTime, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { Direction, Platform } from "../../graphql-messaging/generated/graphql";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { ChatItemPlatformIcon } from "./chat-item-platform-icon";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { messageStatusIcons, messageStatusLabelColor } from "../utils/utils";
import { ChatItemContentVideo } from "./chat-item-content/chat-item-content-video";
import { ChatItemContentWhatsapp } from "./chat-item-content/chat-item-content-whatsapp";
import { ChatItemContentImage } from "./chat-item-content/chat-item-content-image";
import { ChatItemContentText } from "./chat-item-content/chat-item-content-text";
import { ChatItemContentEmail } from "./chat-item-content/chat-item-content-email";
import { ChatItemContentAudio } from "./chat-item-content/chat-item-content-audio";
import { ChatItemContentFile } from "./chat-item-content/chat-item-content-file";
import { ChatItemContentLocation } from "./chat-item-content/chat-item-content-location";
import { GuestIdentity } from "../domain/conversation";
import { ChatItemPlaceholder } from "./chat-item-placeholder";
import { ConversationMessageStatus } from "../../domain/ConversationMessageStatus";
import { ChatItemContentV2Audio } from "./chat-item-content/chat-item-content-v2-audio";
import { ChatItemContentV2Image } from "./chat-item-content/chat-item-content-v2-image";
import { ChatItemContentV2Video } from "./chat-item-content/chat-item-content-v2-video";
import { ChatItemContentV2File } from "./chat-item-content/chat-item-content-v2-file";
import { useProperty } from "src/hooks/use-property";
import { ShowMore } from "../../components/show-more/show-more";
import { getI18nSelectedLanguage } from "src/utils/language";
import { grey } from "@mui/material/colors";
import { SmartToyOutlined } from "@mui/icons-material";
import { isAiBot } from "../domain/conversation-constants";

interface ChatItemProps {
  message?: Message;
  selectedMessageId: string | null;
  guestIdentity?: GuestIdentity | null;
}
const isDirectionReceived = (direction?: Direction) => direction === Direction.Received;
export const ChatItem: FC<ChatItemProps> = ({ message, selectedMessageId, guestIdentity }) => {
  const { palette } = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { selectedProperty } = useProperty();
  const chatItemRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const language = getI18nSelectedLanguage();

  useEffect(() => {
    if (selectedMessageId) {
      const element = chatItemRef.current;
      if (element && element.id === selectedMessageId) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedMessageId]);

  const userInitials = useMemo(() => {
    if (isDirectionReceived(message?.direction)) {
      if (guestIdentity?.firstName && guestIdentity?.lastName) {
        return `${guestIdentity?.firstName?.charAt(0)}${guestIdentity?.lastName?.charAt(0)}`;
      }
      return "";
    } else {
      return message?.from?.displayName
        ?.split(" ")
        .map((word) => word[0])
        .join("");
    }
  }, [guestIdentity, message]);

  const chatItemContentDisplay = useCallback(
    (content: any) => {
      switch (Object.keys(content)[0]) {
        case "text":
          return (
            <ChatItemContentText
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.text}
            />
          );
        case "video":
          return (
            <ChatItemContentVideo
              content={content.video?.url}
              caption={content.video?.caption}
              isLoggedUser={isDirectionReceived(message?.direction)}
            />
          );
        case "whatsapp":
          return (
            <ChatItemContentWhatsapp
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.whatsapp}
            />
          );
        case "hsm":
          return (
            <ChatItemContentWhatsapp
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.whatsapp}
            />
          );
        case "image":
          return (
            <ChatItemContentImage
              content={content.image?.url}
              caption={content.image?.caption}
              isLoggedUser={isDirectionReceived(message?.direction)}
              zoomInLabel={t("labels__zoom_in_image")}
            />
          );
        case "email":
          return (
            <ChatItemContentEmail
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.email.content}
            />
          );
        case "audio":
          return (
            <ChatItemContentAudio
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.audio}
            />
          );
        case "file":
          return (
            <ChatItemContentFile
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.file}
            />
          );
        case "location":
          return (
            <ChatItemContentLocation
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.location}
            />
          );
        default:
          return (
            <Box>
              <Paragraph
                sx={{
                  color: isDirectionReceived(message?.direction)
                    ? palette.background.paper
                    : palette.text.primary
                }}
              >
                {t("labels__messaging__content__not__supported")}
              </Paragraph>
            </Box>
          );
      }
    },
    [message?.direction, palette, t]
  );

  const chatItemContentV2Display = useCallback(
    (content: any) => {
      switch (content.type) {
        case "text":
          return (
            <ChatItemContentText
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.text.text}
            />
          );
        case "email":
          return (
            <ChatItemContentEmail
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.email.content}
            />
          );
        case "list":
          return (
            <ChatItemContentWhatsapp
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.list.text}
            />
          );
        case "file":
          switch (content.file.files[0].contentType) {
            case "audio/ogg":
              return (
                <ChatItemContentV2Audio
                  isLoggedUser={isDirectionReceived(message?.direction)}
                  content={content.file}
                  mediaUrl={content.file?.files?.[0].mediaUrl}
                />
              );
            case "video/mp4":
              return (
                <ChatItemContentV2Video
                  isLoggedUser={isDirectionReceived(message?.direction)}
                  content={content.file}
                  mediaUrl={content.file?.files?.[0].mediaUrl}
                />
              );
            case "application/pdf":
              return (
                <ChatItemContentV2File
                  isLoggedUser={isDirectionReceived(message?.direction)}
                  content={content.file}
                  mediaUrl={content.file?.files?.[0].mediaUrl}
                />
              );
            default:
              return (
                <Box>
                  <Paragraph
                    sx={{
                      color: isDirectionReceived(message?.direction)
                        ? palette.background.paper
                        : palette.text.primary
                    }}
                  >
                    {t("labels__messaging__content__not__supported")}
                  </Paragraph>
                </Box>
              );
          }
        case "location":
          return (
            <ChatItemContentLocation
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.location.coordinates}
            />
          );
        case "image":
          return (
            <ChatItemContentV2Image
              mediaUrl={content.image?.images?.[0].mediaUrl}
              content={content.image}
              isLoggedUser={isDirectionReceived(message?.direction)}
            />
          );
        default:
          return (
            <Box>
              <Paragraph
                sx={{
                  color: isDirectionReceived(message?.direction)
                    ? palette.background.paper
                    : palette.text.primary
                }}
              >
                {t("labels__messaging__content__not__supported")}
              </Paragraph>
            </Box>
          );
      }
    },
    [message?.direction, palette, t]
  );

  return message?.status === ConversationMessageStatus.Placeholder ? (
    <ChatItemPlaceholder conversationId={message?.conversationId} />
  ) : (
    <Grid2 ref={chatItemRef}>
      <Grid2
        container
        direction={isDirectionReceived(message?.direction) ? "row" : "row-reverse"}
        id={message?.messageId}
      >
        {!isMobile && (
          <Grid2
            sx={{
              display: isDirectionReceived(message?.direction) ? "" : "flex",
              justifyContent: isDirectionReceived(message?.direction) ? "" : "end",
              pr: isDirectionReceived(message?.direction) ? 1 : 0,
              pl: !isDirectionReceived(message?.direction) ? 1 : 0
            }}
          >
            {isAiBot(message?.from) ? (
              <Avatar
                sx={{
                  border: `1px solid ${grey["400"]}`,
                  background: grey["200"],
                  color: grey["600"],
                  height: 40,
                  width: 40,
                  p: 2
                }}
              >
                <SmartToyOutlined />
              </Avatar>
            ) : (
              <UserAvatar
                lettersToDisplay={userInitials}
                sx={{
                  backgroundColor: palette.common.white,
                  border: 1,
                  borderColor: palette.common.black,
                  color: palette.common.black,
                  height: 40,
                  width: 40,
                  p: 2
                }}
              />
            )}
          </Grid2>
        )}
        <Grid2 sx={{ maxWidth: isMobile ? "100%" : "70%" }}>
          <Grid2
            container
            direction={isDirectionReceived(message?.direction) ? "row" : "row-reverse"}
          >
            <Grid2 size={{ xs: 12 }}>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor:
                    !isDirectionReceived(message?.direction) &&
                    message?.channel.platform !== Platform.Email
                      ? palette.grey[800]
                      : palette.background.paper,
                  overflow: "hidden",
                  border: `1px solid ${grey["300"]}`
                }}
              >
                <ShowMore>
                  <Box
                    sx={{
                      px: 1.5,
                      py: 1
                    }}
                  >
                    {message?.messageBirdVersion === "2"
                      ? chatItemContentV2Display(message?.content)
                      : chatItemContentDisplay(message?.content)}
                  </Box>
                </ShowMore>
              </Paper>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        size={{ xs: 1 }}
        sx={{
          width: "100%"
        }}
      >
        <Grid2
          container
          sx={{
            alignItems: "center",
            justifyContent: isDirectionReceived(message?.direction) ? "start" : "end",
            color: grey["600"]
          }}
          direction={!isDirectionReceived(message?.direction) ? "row" : "row-reverse"}
        >
          {!isMobile && <Grid2 size={{ xs: 1 }}></Grid2>}
          {message?.createdAt && (
            <Grid2>
              <Caption color="inherit">
                {formatDateTime(message?.createdAt, language, selectedProperty?.details.timeZone)}
              </Caption>
            </Grid2>
          )}
          <Grid2
            container
            sx={{
              ...(isDirectionReceived(message?.direction)
                ? {
                    pr: 1
                  }
                : {
                    pl: 1
                  })
            }}
            alignItems="center"
            spacing={0.25}
          >
            <ChatItemPlatformIcon platform={message?.channel?.platform} />
            <Caption color="inherit">
              {t(`labels__message_platform_${message?.channel?.platform}`)}
            </Caption>
          </Grid2>
          {message?.direction === Direction.Sent && (
            <Grid2 container alignItems="center" pl={1}>
              {messageStatusIcons(message?.status, palette)}
              <Caption
                sx={{
                  ml: 0.125,
                  color: messageStatusLabelColor(message?.status, palette)
                }}
              >
                {t(`labels__message_status_${message?.status ?? ConversationMessageStatus.Other}`)}
              </Caption>
            </Grid2>
          )}
          {!isMobile && <Grid2 width={50} />}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
