import { createTheme, ThemeOptions } from "@mui/material";
import { palette } from "./palette";
import { typography } from "./typography";
import { components } from "./components";

export const theme: ThemeOptions = {
  shape: {
    borderRadius: 8
  },
  palette,
  typography,
  components
};

export const backofficeTheme = createTheme(theme);
