import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";
import DOMPurify from "dompurify";

interface ChatItemContentEmailProps extends ChatItemContentBaseProps {}

export const ChatItemContentEmail: FC<ChatItemContentEmailProps> = ({ content, isLoggedUser }) => {
  const { palette } = useTheme();
  const sanitizedHtmlContent = DOMPurify.sanitize(content.html);
  return (
    <Box sx={{ zoom: "85%" }}>
      {content.html && (
        <Box>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContent
            }}
          />
        </Box>
      )}
      {content.text && (
        <Box>
          <Paragraph
            sx={{
              color: !isLoggedUser ? palette.background.paper : palette.text.primary
            }}
          >
            {content.text}
          </Paragraph>
        </Box>
      )}
    </Box>
  );
};
