import {
  BREAKFAST_OPERATOR_ROLE,
  FeatureFlags,
  HOST_ROLE,
  HOUSEKEEPER_ROLE,
  SUPERUSER_ROLE
} from "@likemagic-tech/sv-magic-library";
import ContactsIcon from "@mui/icons-material/Contacts";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { ReactElement } from "react";
import {
  Groups,
  KingBed,
  RoomServiceOutlined,
  SettingsOutlined,
  StorefrontRounded,
  Task
} from "@mui/icons-material";
import {
  generateArrivalURLSearchParams,
  generateDefaultReservationTableURLSearchParams,
  generateDepartureURLSearchParams,
  generateInHouseURLSearchParams
} from "src/components/table/hooks/use-search-filter-params";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HomeIcon from "@mui/icons-material/Home";
import { generateDefaultTaskTableURLSearchParams } from "src/features/tasks/task-query-params";
import InboxIcon from "@mui/icons-material/Inbox";
import { generateDefaulMessagingTableURLSearchParams } from "src/messaging/domain/conversation-constants";
import { BACKOFFICE_ROUTES, BackofficeRoute } from "../../../routes";

export interface SectionInterface {
  title: string;
  path: BackofficeRoute;
  params?: URLSearchParams;
  icon: ReactElement;
  roles: string[];
  isCollapsable?: boolean;
  collapseOptions?: CollapseOptionsInterface;
  badgeNumber?: number;
  visibleWhenMultiPropertySelected?: boolean;
}

export interface CollapseOptionsInterface {
  key: string;
  items: SectionInterface[];
}

interface SidebarSectionsProps {
  t: (s: string) => string;
  features?: FeatureFlags;
  isRESTVersion: boolean;
}

export const sectionsOption: ({ t, features, isRESTVersion }: SidebarSectionsProps) => Array<{
  key: string;
  items: Array<SectionInterface>;
  isCollapsable?: boolean;
  collapseOptions?: Array<SectionInterface>;
}> = ({ t, features, isRESTVersion }) => {
  const reservationsTableItem: Array<SectionInterface> = features?.reservationTableEnabled
    ? [
        {
          title: t("title__reservations"),
          path: BACKOFFICE_ROUTES.RESERVATIONS,
          params: generateDefaultReservationTableURLSearchParams(),
          icon: <KingBed fontSize="small" />,
          roles: [HOST_ROLE],
          isCollapsable: true,
          collapseOptions: accordionPagesForReservationOptions(t, features)
        }
      ]
    : [];

  const breakfastItem = features?.breakfastViewEnabled
    ? [
        {
          title: t("title__breakfast"),
          path: BACKOFFICE_ROUTES.BREAKFAST,
          icon: <LocalDiningIcon fontSize="small" />,
          roles: [HOST_ROLE, BREAKFAST_OPERATOR_ROLE]
        }
      ]
    : [];
  const boxesItem: Array<SectionInterface> =
    features?.boxEnabled && isRESTVersion
      ? [
          {
            title: t("title__boxes"),
            path: BACKOFFICE_ROUTES.BOXES,
            icon: <StorefrontRounded fontSize="small" />,
            roles: [HOST_ROLE, HOUSEKEEPER_ROLE]
          }
        ]
      : [];

  const identityItem: Array<SectionInterface> = isRESTVersion
    ? [
        {
          title: t("title__identity_check"),
          path: BACKOFFICE_ROUTES.IDENTITIES,
          icon: <ContactsIcon fontSize="small" />,
          roles: [HOST_ROLE]
        }
      ]
    : [];

  const overviewItem: Array<SectionInterface> = isRESTVersion
    ? [
        {
          title: t("title__overview"),
          path: BACKOFFICE_ROUTES.DASHBOARD,
          icon: <ViewQuiltIcon fontSize="small" />,
          roles: [HOST_ROLE]
        }
      ]
    : [];

  const profiles: Array<SectionInterface> = features?.guestListEnabled
    ? [
        {
          title: t("title__profiles"),
          path: BACKOFFICE_ROUTES.PROFILES,
          icon: <Groups fontSize="small" />,
          roles: [HOST_ROLE]
        }
      ]
    : [];

  //housekeepingV2Enabled
  const housekeepingV2Beta: Array<SectionInterface> = features?.housekeepingV2Enabled
    ? [
        {
          title: t("title__housekeeping_v2_beta"),
          path: BACKOFFICE_ROUTES.HOUSEKEEPING,
          icon: <LocalLaundryServiceIcon fontSize="small" />,
          roles: [HOUSEKEEPER_ROLE]
        }
      ]
    : [];

  const settings: Array<SectionInterface> = [
    {
      title: t("title__settings"),
      path: BACKOFFICE_ROUTES.SETTINGS,
      icon: <SettingsOutlined fontSize="small" />,
      roles: [SUPERUSER_ROLE],
      isCollapsable: true,
      collapseOptions: accordionPagesForSettingsOptions(t)
    }
  ];

  return [
    {
      key: "General",
      items: [
        ...overviewItem,
        ...reservationsTableItem,
        {
          title: t("title__messaging"),
          path: BACKOFFICE_ROUTES.MESSAGING,
          params: generateDefaulMessagingTableURLSearchParams(),
          icon: <InboxIcon fontSize="small" />,
          roles: [HOST_ROLE]
        } as SectionInterface,
        ...breakfastItem,
        {
          title: t("title__housekeeping"),
          path: BACKOFFICE_ROUTES.DIRTY_UNITS,
          icon: <LocalLaundryServiceIcon fontSize="small" />,
          roles: [HOUSEKEEPER_ROLE]
        },
        ...housekeepingV2Beta,
        {
          title: t("title__units"),
          path: BACKOFFICE_ROUTES.UNITS,
          icon: <LocalHotelIcon fontSize="small" />,
          roles: [HOST_ROLE, HOUSEKEEPER_ROLE]
        } as SectionInterface,
        {
          title: t("title__tasks"),
          path: BACKOFFICE_ROUTES.TASKS,
          params: generateDefaultTaskTableURLSearchParams(
            features?.foodAndBeverageEnabled ?? false
          ),
          icon: <Task fontSize="small" />,
          roles: [HOST_ROLE, HOUSEKEEPER_ROLE]
        } as SectionInterface,
        ...identityItem,
        ...boxesItem,
        ...profiles,
        ...settings
      ] as Array<SectionInterface>
    }
  ];
};

export const accordionPagesForReservationOptions = (
  t: (s: string) => string,
  features: FeatureFlags
): CollapseOptionsInterface => {
  const arrivalsItem: Array<SectionInterface> = [
    {
      title: t("title__arrivals"),
      path: BACKOFFICE_ROUTES.ARRIVAL,
      params: generateArrivalURLSearchParams(features?.arrivalsWithoutInHouseEnabled),
      icon: <FlightLandIcon fontSize="small" />,
      roles: [HOST_ROLE]
    }
  ];

  const departuresItem: Array<SectionInterface> = [
    {
      title: t("title__departures"),
      path: BACKOFFICE_ROUTES.DEPARTURE,
      params: generateDepartureURLSearchParams(),
      icon: <FlightTakeoffIcon fontSize="small" />,
      roles: [HOST_ROLE]
    }
  ];

  const inHotelItem: Array<SectionInterface> = [
    {
      title: t("title__in_hotel"),
      path: BACKOFFICE_ROUTES.IN_HOTEL,
      params: generateInHouseURLSearchParams(),
      icon: <HomeIcon fontSize="small" />,
      roles: [HOST_ROLE]
    }
  ];

  return {
    key: "ReservationAccordion",
    items: [...arrivalsItem, ...departuresItem, ...inHotelItem]
  };
};

export const accordionPagesForSettingsOptions = (
  t: (s: string) => string
): CollapseOptionsInterface => {
  const additionalServicesItem: Array<SectionInterface> = [
    {
      title: t("title__additional_services"),
      path: BACKOFFICE_ROUTES.SETTINGS_ADDITIONAL_SERVICES,
      icon: <RoomServiceOutlined />,
      roles: [SUPERUSER_ROLE]
    }
  ];

  return {
    key: "SettingsAccordion",
    items: [...additionalServicesItem]
  };
};
