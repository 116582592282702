import React, { useEffect } from "react";
import { setShareHereSecondScreenId } from "../../utils/second-screen";

export const ParamsToLocalStorage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const queryParamsString = window.location.search;

  useEffect(() => {
    const queryParams = new URLSearchParams(queryParamsString);
    setShareHereSecondScreenId(queryParams.get("shareHereSecondScreenId"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
