import * as Types from "../generated/graphql";

export type ActorGroupFragment = {
  __typename?: "ActorGroup";
  actorGroupType: Types.ActorGroupType;
  name: string;
};

export const ActorGroupFragmentDoc = `
    fragment ActorGroup on ActorGroup {
  actorGroupType
  name
}
    `;
