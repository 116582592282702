import { FC } from "react";
import { Grid2 } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  Select,
  DatePicker,
  formatDateToIso,
  Input,
  InputProps
} from "@likemagic-tech/sv-magic-library";
import { SelectProps } from "@likemagic-tech/sv-magic-library/dist/components/select/select.types";
import { getI18nSelectedLanguage } from "../../../../utils/language";

export interface DateRangeFilterValue {
  id: string;
  type?: DateRangeFilterType | "";
  from?: Date;
  to?: Date;
}

interface DateRangeFilterProps {
  value: DateRangeFilterValue;
  onChange: (props: DateRangeFilterValue, oldType: DateRangeFilterType | "") => void;
  alreadySelectedTypes: Array<DateRangeFilterType>;
  size?: SelectProps["size"];
}

//Order is important
export enum DateRangeFilterType {
  "ARRIVAL" = "arrival",
  "DEPARTURE" = "departure",
  "CREATED" = "createdAt",
  "STAY" = "stay"
}
const isValidDate = (date: Date | null) => date && !!date.getDate();

export const DateRangeFilter: FC<DateRangeFilterProps> = ({
  value,
  onChange,
  alreadySelectedTypes,
  size
}) => {
  const { t } = useTranslationWrapper();
  const onChangeDatePicker = (field: string) => (date: string | null) => {
    const parsedDate = date ? new Date(date) : null; // Convert string to Date
    if (isValidDate(parsedDate)) {
      onChange({ ...value, [field]: parsedDate }, value.type ?? "");
    }
  };

  const customInputComponent = (props: InputProps) => {
    return <Input variant="outlined" size="small" {...props} />;
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
        <Select
          size={size}
          value={value.type}
          variant={"outlined"}
          label={t("labels__type")}
          onChange={(e) => {
            onChange(
              {
                ...value,
                type: e.target.value as DateRangeFilterType
              },
              value.type ?? ""
            );
          }}
          options={Object.values(DateRangeFilterType).map((type) => ({
            label: t(`labels__filter_title_${type}`),
            value: type,
            disabled: alreadySelectedTypes.indexOf(type) > -1
          }))}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
        <DatePicker
          label={t("labels__from")}
          maxDate={value.to}
          disabled={!value.type}
          selectedDate={value.from ? formatDateToIso(value.from) : null}
          onChange={onChangeDatePicker("from")}
          selectedLanguage={getI18nSelectedLanguage()}
          CustomInputComponent={customInputComponent}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
        <DatePicker
          minDate={value.from}
          disabled={!value.type}
          label={t("labels__to")}
          selectedDate={value.to ? formatDateToIso(value.to) : null}
          onChange={onChangeDatePicker("to")}
          selectedLanguage={getI18nSelectedLanguage()}
          CustomInputComponent={customInputComponent}
        />
      </Grid2>
    </Grid2>
  );
};
