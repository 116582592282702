import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Badge, Drawer, Grid2, useTheme } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { grey } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { ReleaseNotesCard } from "src/features/release-notes/release-notes-card";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";
import { useReleaseNote } from "../../features/release-notes/use-release-note";

export enum LanguageType {
  "en" = "en-us",
  "de" = "de-ch",
  "it" = "it-ch",
  "fr" = "fr-ch"
}

export type ReleaseNotes = {
  date: string;
  title: string;
  description: string;
};

const RELEASE_NOTES_LOCAL_STORAGE_KEY = "releaseNotes";

export const ReleaseNotePopover: FC = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const [openDrawer, setOpenDrawer] = useState(false);
  const releaseNotes = useReleaseNote();
  const [newFeaturesCounter, setNewFeaturesCounter] = useState(0);
  const [newFeaturesChecked, setNewFeaturesChecked] = useState(false);
  const navigate = useNavigateWithPropertyId();

  /**
   * Compares the number of releases notes in the localStorage with the
   * lenght of the release notes array to check if there are new features
   * @param index: lenght of the releaseNotes array
   */
  const checkForNewFeatures = useCallback((amount: number) => {
    let newNotes: Object;
    const notes = JSON.parse(localStorage.getItem(RELEASE_NOTES_LOCAL_STORAGE_KEY) ?? "{}");
    if (notes) {
      if (notes.counter < amount) {
        newNotes = {
          counter: amount,
          checked: false,
          notRead: amount - notes.counter
        };
        localStorage.setItem(RELEASE_NOTES_LOCAL_STORAGE_KEY, JSON.stringify(newNotes));
        setNewFeaturesChecked(false);
        setNewFeaturesCounter(amount - notes.counter);
      } else {
        if (!notes.checked) {
          setNewFeaturesChecked(false);
          setNewFeaturesCounter(notes.notRead);
        } else {
          setNewFeaturesChecked(false);
        }
      }
    } else {
      newNotes = {
        counter: amount,
        checked: false,
        notRead: amount
      };
      localStorage.setItem(RELEASE_NOTES_LOCAL_STORAGE_KEY, JSON.stringify(newNotes));
      setNewFeaturesChecked(false);
      setNewFeaturesCounter(amount);
    }
  }, []);

  useEffect(() => {
    checkForNewFeatures(releaseNotes.length);
  }, [releaseNotes.length, checkForNewFeatures]);

  /**
   * Handles actions when the Release Notes icon is pressed
   */
  const handleIconClick = () => {
    const notes = JSON.parse(localStorage.getItem(RELEASE_NOTES_LOCAL_STORAGE_KEY) ?? "{}");
    setOpenDrawer(true);
    setNewFeaturesChecked(true);
    const newNotes = {
      counter: +notes.counter,
      checked: true,
      notRead: 0
    };
    localStorage.setItem(RELEASE_NOTES_LOCAL_STORAGE_KEY, JSON.stringify(newNotes));
  };

  return (
    <Fragment>
      <Badge
        color="secondary"
        badgeContent={newFeaturesCounter}
        invisible={newFeaturesChecked}
        max={9}
      >
        <CampaignIcon
          fontSize="large"
          onClick={handleIconClick}
          sx={{ cursor: "pointer", color: theme.palette.background.paper }}
        />
      </Badge>
      <Drawer
        anchor="right"
        SlideProps={{ appear: false }}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        sx={{ zIndex: 1301 }}
        PaperProps={{
          sx: {
            maxWidth: theme.spacing(45)
          }
        }}
      >
        <Grid2
          container
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: theme.palette.primary.main,
            minHeight: theme.spacing(8)
          }}
        >
          <Grid2>
            <Heading4 sx={{ color: grey[200], margin: theme.spacing(2) }}>
              {t("release__what__new")}
            </Heading4>
          </Grid2>
          <Grid2>
            <OpenInNewIcon
              fontSize="medium"
              sx={{
                color: grey[200],
                margin: theme.spacing(2),
                cursor: "pointer"
              }}
              onClick={() => {
                navigate("/release-notes");
                setOpenDrawer(false);
              }}
            />
          </Grid2>
        </Grid2>
        <ReleaseNotesCard releaseNotes={releaseNotes} />
      </Drawer>
    </Fragment>
  );
};
