import type { FC } from "react";
import { Box, Card, CardContent, CardHeader, Grid2, useTheme } from "@mui/material";
import { Heading2, ParagraphSmall } from "@likemagic-tech/sv-magic-library";

export interface StatProps {
  textPrimary: string;
  textSecondary: string;
}

export interface QuickStats9Props {
  title: string;
  onClick?: () => void;
  stat1: StatProps;
  stat2: StatProps;
  stat3: StatProps;
}

interface ItemProps {
  stat: StatProps;
}

const Item: FC<ItemProps> = ({ stat }) => {
  const theme = useTheme();
  return (
    <Grid2
      size={{ xs: 4 }}
      sx={{
        textAlign: "center"
      }}
    >
      <Heading2 sx={{ color: theme.palette.text.primary }}>{stat.textPrimary}</Heading2>
      <ParagraphSmall
        sx={{ fontWeight: 700, marginTop: theme.spacing(1), color: theme.palette.text.secondary }}
      >
        {stat.textSecondary}
      </ParagraphSmall>
    </Grid2>
  );
};
const QuickStats9: FC<QuickStats9Props> = ({ onClick, title, stat1, stat2, stat3 }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default
      }}
    >
      <Card onClick={onClick} sx={{ cursor: "pointer" }}>
        <CardHeader
          title={
            <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>{title}</ParagraphSmall>
          }
        />
        <CardContent>
          <Grid2
            container
            sx={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Item stat={stat1} />
            <Item stat={stat2} />
            <Item stat={stat3} />
          </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

export default QuickStats9;
