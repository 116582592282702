import * as React from "react";
import { FC, PropsWithChildren, useMemo, useRef, useState } from "react";
import { Badge, Box, Popover, useTheme } from "@mui/material";
import { Button } from "@likemagic-tech/sv-magic-library";
import { FilterAltOutlined } from "@mui/icons-material";
import { FreeTextFilter } from "./free-text-filter";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { FilterObject } from "src/types/filters/filters";
import { DateRangeFilterType } from "./components/date-range-filter";

export interface BaseTableFiltersProps {
  filterMap: FilterMapInterface;
  onFilterMapChange: (filterMap: FilterMapInterface) => void;
}

interface TableFiltersProps extends BaseTableFiltersProps {
  disableFilters?: boolean;
}

export enum FilterObjectType {
  AUTOCOMPLETE = "AUTOCOMPLETE",
  DATE_RANGE = "DATE_RANGE",
  FREE_TEXT_SEARCH = "FREE_TEXT_SEARCH",
  ADDITIONAL = "ADDITIONAL",
  PROPERTY = "PROPERTY"
}

export interface FilterObjectWithType extends FilterObject {
  type: FilterObjectType | DateRangeFilterType;
}

export interface FilterMapInterface {
  [key: string]: FilterObjectWithType;
}

export const appliableFilters = (filterMap: FilterMapInterface) =>
  Object.keys(filterMap).reduce(
    (acc, keyFilter) => {
      const filterItem = filterMap[keyFilter];
      if (filterItem.operator && filterItem.value && filterItem.name) {
        const copyOfAcc = { ...acc };
        copyOfAcc[keyFilter as keyof typeof copyOfAcc] = filterItem;
        return copyOfAcc;
      }

      return acc;
    },
    {} as { [key: string]: FilterObjectWithType }
  );

export const serializeIdOfFilter = ({ type, operator, name }: FilterObjectWithType) =>
  [type, name, operator].join("-");

export const getFilterValueByType = (filters: Record<string, any>, filterType: string) => {
  for (const key of Object.keys(filters)) {
    const { type } = deserializeIdOfFilter(key);
    if (type === filterType) {
      return filters[key]?.value;
    }
  }
  return null;
};

export const deserializeIdOfFilter = (key: string) => {
  const parts = key.split("-");
  return {
    type: parts[0],
    name: parts[1],
    operator: parts[2]
  };
};
export const TableFilter: FC<PropsWithChildren<TableFiltersProps>> = ({
  disableFilters,
  filterMap,
  onFilterMapChange,
  children
}) => {
  const [filterOpened, setFilterOpened] = useState(false);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const isMobile = useIsMobile();

  const filtersToBeApplied = useMemo(() => appliableFilters(filterMap), [filterMap]);

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "fit-content"
      }}
    >
      <Box
        ref={searchRef}
        sx={{
          width: isMobile ? "100%" : "fit-content",
          display: "flex",
          gap: 1,
          mr: 1
        }}
      >
        <FreeTextFilter
          disabled={disableFilters}
          filterMap={filterMap}
          onFilterMapChange={onFilterMapChange}
        />
        <Badge
          variant="standard"
          sx={{
            "& .MuiBadge-badge": {
              width: "18px",
              height: "18px",
              backgroundColor: theme.palette.primary.main,
              top: 0,
              right: 0
            }
          }}
          badgeContent={
            Object.keys(filtersToBeApplied).length ? (
              <>{Object.keys(filtersToBeApplied).length}</>
            ) : null
          }
        >
          <Button
            variant="surface"
            color="primary"
            disabled={disableFilters}
            onClick={() => setFilterOpened(!filterOpened)}
            startIcon={<FilterAltOutlined fontSize={"small"} />}
            sx={{
              textTransform: "capitalize",
              height: "32px",
              width: "32px",
              padding: 0,
              minWidth: "auto",
              display: "flex",
              borderRadius: theme.spacing(0.75),
              justifyContent: "center",
              alignItems: "center",
              "& .MuiButton-startIcon": {
                margin: 0
              }
            }}
          />
        </Badge>
      </Box>
      <Popover
        sx={{ mt: 2 }}
        slotProps={{
          paper: {
            sx: {
              width: "fit-content",
              minWidth: isMobile ? "90%" : theme.spacing(64),
              overflowY: "auto"
            }
          }
        }}
        open={filterOpened}
        anchorEl={searchRef.current}
        onClose={() => setFilterOpened(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Box
          sx={{
            p: 2
          }}
        >
          {children}
        </Box>
      </Popover>
    </Box>
  );
};
