import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "../../../../store";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Alert, Box, CircularProgress, DialogContent, useTheme } from "@mui/material";
import { Button, ParagraphSmall, Select } from "@likemagic-tech/sv-magic-library";
import { PricePicker } from "../../../../components/price-picker/price-picker";
import { Form, Formik } from "formik";
import { useFormValidations } from "../../../../hooks/use-form-validation";
import { InfoIcon } from "../../../../icons/info.icon";
import { selectLastSelectedTerminalId } from "../../../../slices/recently-used-devices.slice";
import { DialogTitleModal } from "src/components/submit-modal/dialog-title";
import { DialogModal } from "src/components/submit-modal/dialog";
import { useTerminalPayment } from "../hooks/use-terminal-payment";

export const TerminalPaymentModal: FC = () => {
  const { t } = useTranslationWrapper();
  const { terminalPaymentValidations } = useFormValidations();
  const theme = useTheme();
  const lastSelectedSecondScreenId = useSelector(selectLastSelectedTerminalId);

  const {
    isLoading,
    selectedTerminal,
    isCancelAvailable,
    price,
    resetTerminalPayment,
    handleCancelPayment
  } = useTerminalPayment();

  useEffect(() => {
    return () => {
      resetTerminalPayment();
    };
  }, [resetTerminalPayment]);

  const cleanup = useCallback(() => {
    resetTerminalPayment();
  }, [resetTerminalPayment]);

  const initialValues = useMemo(() => {
    if (price) {
      return {
        paymentTerminalId: lastSelectedSecondScreenId ?? undefined,
        price
      };
    }
    return {
      price: {
        currency: "",
        amount: 0
      }
    };
  }, [lastSelectedSecondScreenId, price]);

  const terminalOptions = useMemo(
    () => (selectedTerminal ? [selectedTerminal] : []),
    [selectedTerminal]
  );

  if (!price) {
    return null;
  }

  return (
    <DialogModal isOpen={true} handleClose={cleanup}>
      <Box
        sx={{
          p: 3,
          width: "100%"
        }}
      >
        <DialogTitleModal title={t(`modals__title_TERMINAL_PAYMENT`)} onClose={cleanup} />
        <DialogContent sx={{ padding: 0, pt: 1 }}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleCancelPayment}
            validationSchema={terminalPaymentValidations}
          >
            {(formik) => (
              <Form id="terminal-payment">
                {isLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 10,
                      left: 0,
                      right: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <CircularProgress size={88} thickness={5} />
                  </Box>
                )}
                <Box
                  sx={{
                    py: 1
                  }}
                >
                  <Select
                    id="paymentTerminalId"
                    name="paymentTerminalId"
                    variant="outlined"
                    options={terminalOptions}
                    value={formik.values.paymentTerminalId}
                    disabled={isLoading}
                    label={t("labels__terminal_id")}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.paymentTerminalId ? !!formik.errors.paymentTerminalId : false
                    }
                  />
                </Box>
                <Box
                  sx={{
                    py: 1
                  }}
                >
                  <PricePicker
                    prefix="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    disabled={isLoading}
                  />
                </Box>
                {formik.values.price.amount < 0 && (
                  <Alert
                    icon={<InfoIcon sx={{ color: theme.palette.info.main }} />}
                    sx={{
                      alignItems: "center",
                      backgroundColor: theme.palette.info.light,
                      mb: 2,
                      mt: 1
                    }}
                  >
                    <ParagraphSmall>
                      {t("labels_make_unreferenced_negative_payment")}
                    </ParagraphSmall>
                  </Alert>
                )}
                <Box
                  sx={{
                    textAlign: "center",
                    mt: 1
                  }}
                >
                  {isLoading ? (
                    <Button
                      sx={{ width: "50%" }}
                      type="submit"
                      variant="primary"
                      disabled={isCancelAvailable}
                    >
                      {t("buttons__cancel")}
                    </Button>
                  ) : (
                    <Button
                      sx={{ width: "50%" }}
                      type="submit"
                      variant="primary"
                      disabled={!formik.values.paymentTerminalId}
                    >
                      {t("buttons__choose")}
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Box>
    </DialogModal>
  );
};
