import * as Types from "../generated/graphql";

import { ActorFragmentDoc } from "../fragments/Actor.generated";
import { ActorGroupFragmentDoc } from "../fragments/ActorGroup.generated";
import { messagingApi } from "../messaging.api";
export type GetConversationsQueryVariables = Types.Exact<{
  filter: Types.ConversationFilter;
}>;

export type GetConversationsQuery = {
  __typename?: "Query";
  GetConversations: {
    __typename?: "ConversationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Conversation";
      conversationId: string;
      messageBirdVersion?: string | null;
      lastReceivedMessage?: any | null;
      status: Types.ConversationStatus;
      numberOfUnreadMessages: number;
      updatedAt?: string | null;
      pmsPropertyId: string;
      assignee?: {
        __typename?: "Actor";
        actorId?: string | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: Types.ActorGroupType;
          name: string;
        } | null;
      } | null;
      guest: {
        __typename?: "Actor";
        actorId?: string | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: Types.ActorGroupType;
          name: string;
        } | null;
      };
      lastMessage: Array<{
        __typename?: "Message";
        messageId: string;
        messageBirdVersion?: string | null;
        content: any;
        updatedAt: any;
        channel: { __typename?: "Channel"; platform: Types.Platform };
        from?: {
          __typename?: "Actor";
          actorId?: string | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: Types.ActorGroupType;
            name: string;
          } | null;
        } | null;
      } | null>;
    }>;
  };
};

export const GetConversationsDocument = `
    query GetConversations($filter: ConversationFilter!) {
  GetConversations(filter: $filter) {
    totalElements
    content {
      assignee {
        ...Actor
      }
      conversationId
      messageBirdVersion
      lastReceivedMessage
      guest {
        ...Actor
      }
      status
      numberOfUnreadMessages
      updatedAt
      pmsPropertyId
      lastMessage: messages(
        filter: {pageable: {pageNumber: 0, pageSize: 1, sort: "createdAt,desc"}}
      ) {
        channel {
          platform
        }
        messageId
        messageBirdVersion
        content
        updatedAt
        from {
          ...Actor
        }
      }
    }
  }
}
    ${ActorFragmentDoc}
${ActorGroupFragmentDoc}`;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    GetConversations: build.query<GetConversationsQuery, GetConversationsQueryVariables>({
      query: (variables) => ({ document: GetConversationsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetConversationsQuery, useLazyGetConversationsQuery } = injectedRtkApi;
