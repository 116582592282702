import { Box, Card, Divider, Grid2, useTheme } from "@mui/material";
import { useUnitConditionModal } from "../../hooks/use-unit-condition-modal";
import { useDispatch } from "../../store";
import { UnitDashboardDTO } from "../../domain/UnitDashboardDTO";
import { BatteryLevel } from "./battery-level";
import {
  Button,
  Heading3,
  Paragraph,
  TenantContext,
  useApiVersion,
  useDoorProviderConfig,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { getUnitType } from "src/utils/tenant-unit-type";
import { Unit, UnitCondition } from "../../domain/Unit";
import { resetDoorStatus } from "../../slices/available-doors-state.slice";
import { areRemoteKeysAvailable } from "../../utils/key-utils";
import { useUnitGroupById } from "../../hooks/use-unit-group-by-id";
import { getYesNoModalArg } from "../../utils/modal-util";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { buttonVariantMap } from "../../components/chip/chip-button";
import { ChipStatus } from "../../components/chip/chip-status";
import { KeyComponent } from "../../components/key-component";
import { FC, useCallback, useContext, useMemo, useRef } from "react";
import { AvailableDoor } from "../../domain/available-door";
import { DisplayUnitsLabels } from "./display-units-labels";
import { useUpdateUnit } from "../../hooks/use-update-unit";
import { useOpenDoor } from "../../hooks/use-open-door";
import { useFetchAvailableDoors } from "../../hooks/use-fetch-available-doors";
import { MaintenanceSlotInfo } from "src/components/maintenance-slot-info/maintenance-slot-info";
import { MaintenanceSlotStatus } from "../../graphql-tasks/generated/graphql";

interface UnitCardProps {
  unit: UnitDashboardDTO;
  refreshUnitList?: () => void;
  propertyId: string;
}
export const unitConditionVariantMap = {
  [UnitCondition.DIRTY]: ChipStatus.CRITICAL,
  [UnitCondition.CLEAN_TO_BE_INSPECTED]: ChipStatus.MINOR,
  [UnitCondition.CLEAN]: ChipStatus.OK
};

export const UnitCard: FC<UnitCardProps> = ({ unit, refreshUnitList, propertyId }) => {
  const { t } = useTranslationWrapper();
  const assignedUnitGroup = useUnitGroupById(propertyId, unit.unitGroupId);

  const { open: openYesNoModal } = useGlobalModal();
  const dispatch = useDispatch();
  const { theme: selectedTheme } = useContext(TenantContext);
  const { open } = useUnitConditionModal();
  const doorConfig = useDoorProviderConfig(propertyId);
  const keySliderRefs = useRef<{ [doorId: string]: any }>({});
  const [updateUnit] = useUpdateUnit();
  const { openAdminDoor } = useOpenDoor();
  const { isRESTVersion } = useApiVersion();
  const { filterPrivateKeysByUnitId } = useFetchAvailableDoors(propertyId);
  const theme = useTheme();

  const doorsData = filterPrivateKeysByUnitId(unit.id);

  const handleOpenDoor = useCallback(
    async (unit: Unit, doorId: string) => {
      await openAdminDoor({ propertyId: propertyId, doorId });
      setTimeout(() => {
        dispatch(resetDoorStatus(doorId));
        keySliderRefs.current?.[doorId]?.reset();
      }, 5000);
    },
    [dispatch, openAdminDoor, propertyId]
  );

  const displayUnitName = useMemo(
    () =>
      [
        // TODO use proper pattern for replacing strings
        t("labels__unit_type").replace("{{unitType}}", getUnitType(selectedTheme)),
        unit?.name || ""
      ].join(" "),
    [selectedTheme, unit?.name, t]
  );

  const handleOpenModal = async () => {
    const result = await open({
      condition: unit.status.condition,
      unitName: displayUnitName
    });
    if (result != null) {
      await updateUnit({
        unitId: unit?.id,
        condition: result
      });

      refreshUnitList?.();
    }
  };

  const handleOpenDoorSlider = useCallback(
    async (door: AvailableDoor) => {
      if (unit?.status?.occupied) {
        const result = await openYesNoModal(
          getYesNoModalArg(t("labels__open_door_occupied"), "", t("labels__yes"), t("labels__no"))
        );
        if (result) {
          handleOpenDoor(unit, door.id);
        } else {
          keySliderRefs.current?.[Number(door.id)]?.reset();
        }
      } else {
        handleOpenDoor(unit, door.id);
      }
    },
    [handleOpenDoor, openYesNoModal, unit, t]
  );

  const getOngoingMaintenance = useMemo(() => {
    return unit?.maintenanceSlots?.find((unit) => unit.status === MaintenanceSlotStatus.Ongoing);
  }, [unit]);

  return (
    <Grid2 key={unit.id} size={{ xs: 12, md: 4 }}>
      <Card sx={{ p: 3 }}>
        <Grid2
          container
          sx={{
            alignItems: "center"
          }}
        >
          <Grid2>
            <Heading3 sx={{ color: theme.palette.text.primary }}>{displayUnitName}</Heading3>
          </Grid2>
          <Box sx={{ flexGrow: 1 }} />
          {doorConfig?.batteryLevelSupported && (
            <Grid2>
              <BatteryLevel level={unit?.door?.batteryLevel} />
            </Grid2>
          )}
        </Grid2>

        <Grid2
          container
          sx={{
            alignItems: "center"
          }}
        >
          <Grid2>
            <DisplayUnitsLabels unit={unit} />
          </Grid2>
          <Box sx={{ flexGrow: 1 }} />
          <Grid2>
            <Paragraph sx={{ color: theme.palette.text.primary }}>
              {unit?.status
                ? t(`labels__unit_${unit.status.occupied ? "occupied" : "free"}`)
                : null}
            </Paragraph>
          </Grid2>
        </Grid2>

        {unit && (
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 1
            }}
          >
            <Box>
              <Paragraph>{assignedUnitGroup?.name}</Paragraph>
            </Box>
          </Box>
        )}
        {!!getOngoingMaintenance && isRESTVersion && (
          <MaintenanceSlotInfo maintenance={{ ...getOngoingMaintenance, unitIds: [unit.id] }} />
        )}
        {unit.id && (
          <Box
            sx={{
              pt: 2,
              pb: 1
            }}
          >
            <Button
              onClick={handleOpenModal}
              color={
                buttonVariantMap[
                  unitConditionVariantMap[
                    unit?.status?.condition as keyof typeof unitConditionVariantMap
                  ]
                ]
              }
              fullWidth
              disabled={!!unit.expectedDeparture}
            >
              {t(`labels__unit_condition_${unit.status.condition}`)}
            </Button>

            {doorsData.length !== 0 && areRemoteKeysAvailable(doorConfig?.doorProvider) && (
              <Box
                sx={{
                  mt: 2
                }}
              >
                <Divider sx={{ pt: 1 }} />
                <Box
                  sx={{
                    mt: 3
                  }}
                >
                  {doorsData.map((doorData) => (
                    <KeyComponent
                      key={doorData.id}
                      doorData={doorData}
                      doorProvider={doorConfig?.doorProvider}
                      keySliderRefs={keySliderRefs}
                      onSuccess={handleOpenDoorSlider}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Card>
    </Grid2>
  );
};
