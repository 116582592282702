import React, { FC, useCallback } from "react";
import { Box, useTheme } from "@mui/material";
import { KeyComponent } from "../key-component";
import { Paragraph, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { resetDoorStatus } from "../../slices/available-doors-state.slice";
import { useDispatch } from "../../store";
import { AvailableDoor } from "../../domain/available-door";
import { useOpenDoor } from "../../hooks/use-open-door";

interface PublicDoorModalProps {
  doorData: AvailableDoor;
  keySliderRefs: any;
  propertyId?: string;
}

export const PublicDoorPreview: FC<PublicDoorModalProps> = ({
  doorData,
  keySliderRefs,
  propertyId
}) => {
  const dispatch = useDispatch();
  const { openAdminDoor } = useOpenDoor();
  const theme = useTheme();

  const doorConfig = useDoorProviderConfig(propertyId ?? "");

  const handleOpenDoor = useCallback(
    async (doorData: AvailableDoor) => {
      await openAdminDoor({
        propertyId: propertyId ?? "",
        doorId: doorData?.id
      });
      setTimeout(() => {
        dispatch(resetDoorStatus(doorData?.id));
        keySliderRefs.current?.[doorData?.id]?.reset();
      }, 5000);
    },
    [dispatch, propertyId, keySliderRefs, openAdminDoor]
  );

  return (
    <>
      <Box
        sx={{
          my: 3
        }}
      >
        <Paragraph sx={{ color: theme.palette.text.secondary }}>{doorData?.title}</Paragraph>
      </Box>
      <KeyComponent
        doorData={doorData}
        doorProvider={doorConfig?.doorProvider}
        keySliderRefs={keySliderRefs}
        onSuccess={handleOpenDoor}
      />
    </>
  );
};
