import * as React from "react";
import { FC } from "react";
import { BaseTableFiltersProps, FilterObjectType, serializeIdOfFilter } from "../table-filters";
import { Box } from "@mui/material";
import { AutocompleteFilterType, AutocompleteFilterValue } from "../components/autocomplete-filter";
import { FilterOperator } from "../../../../types/filters/filters";
import { AutocompleteOption } from "../../../autocomplete-component";
import { EmployeeFilterItem } from "./employee-filter-item";

interface AutocompleteFiltersProps extends BaseTableFiltersProps {
  removeFilter: (val: Array<string>) => void;
  allowedFilters: Array<AutocompleteFilterType>;
  employeeOptions: Array<AutocompleteOption>;
}

export const EmployeeAutocompleteFilters: FC<AutocompleteFiltersProps> = ({
  filterMap,
  onFilterMapChange,
  removeFilter,
  allowedFilters,
  employeeOptions
}) => {
  const autocompleteFilters = Object.keys(filterMap)
    .filter((filterKey) => filterKey.startsWith(FilterObjectType.AUTOCOMPLETE))
    .map((key) => filterMap[key])
    .filter((item) => allowedFilters.map((item) => item.toString()).includes(item.name ?? ""));

  const onChange = (changedFilter: AutocompleteFilterValue, oldType?: AutocompleteFilterType) => {
    const newFilter = {
      name: changedFilter.type,
      operator: FilterOperator.Equality,
      type: FilterObjectType.AUTOCOMPLETE,
      value: changedFilter.id
    };
    if (!oldType) {
      onFilterMapChange({
        ...filterMap,
        ...{ [serializeIdOfFilter(newFilter)]: newFilter }
      });
    } else if (oldType !== changedFilter.type) {
      const newFilterMap = {
        ...filterMap,
        ...{ [serializeIdOfFilter(newFilter)]: newFilter }
      };

      delete newFilterMap[
        serializeIdOfFilter({
          type: FilterObjectType.AUTOCOMPLETE,
          operator: FilterOperator.Equality,
          name: oldType
        })
      ];
      onFilterMapChange({ ...newFilterMap });
    }
  };

  return (
    <Box>
      {autocompleteFilters.length ? (
        <Box>
          {autocompleteFilters.map((filter) => (
            <EmployeeFilterItem
              key={`${filter.type}-${filter.name}`}
              filter={{
                value: filter.value,
                type: FilterObjectType.AUTOCOMPLETE,
                name: filter.name as AutocompleteFilterType
              }}
              onChange={onChange}
              removeFilter={removeFilter}
              allowedFilters={allowedFilters}
              employeeOptions={employeeOptions}
            />
          ))}
        </Box>
      ) : (
        <EmployeeFilterItem
          filter={{
            value: undefined,
            type: FilterObjectType.AUTOCOMPLETE,
            name: AutocompleteFilterType.ASSIGNED_TO
          }}
          onChange={onChange}
          removeFilter={removeFilter}
          hideMinus
          allowedFilters={allowedFilters}
          employeeOptions={employeeOptions}
        />
      )}
    </Box>
  );
};
