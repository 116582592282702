import { FC, PropsWithChildren } from "react";
import { Grid2 } from "@mui/material";

export const ProfileDrawerCards: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid2
      container
      sx={{
        pt: 3,
        gap: 3,
        width: "100%"
      }}
    >
      {children}
    </Grid2>
  );
};
