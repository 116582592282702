import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SettleFolioMutationVariables = Types.Exact<{
  settleFolioInput: Types.SettleFolioInput;
}>;

export type SettleFolioMutation = { __typename?: "Mutation"; SettleFolio?: boolean | null };

export const SettleFolioDocument = `
    mutation SettleFolio($settleFolioInput: SettleFolioInput!) {
  SettleFolio(settleFolioInput: $settleFolioInput)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SettleFolio: build.mutation<SettleFolioMutation, SettleFolioMutationVariables>({
      query: (variables) => ({ document: SettleFolioDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSettleFolioMutation } = injectedRtkApi;
