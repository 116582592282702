import {
  Badge,
  Box,
  Collapse,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme
} from "@mui/material";
import { FC, ReactElement, SyntheticEvent, useCallback, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { resolvePath } from "react-router";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useNavigateWithPropertyId } from "src/hooks/use-navigate-with-propertyId";
import { selectIsOpenSideNavigation, setIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { useDispatch, useSelector } from "src/store";
import { makeStyles } from "tss-react/mui";
import { grey } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SUPERUSER_ROLE, useAuth } from "@likemagic-tech/sv-magic-library";
import { CollapseOptionsInterface } from "./options";

interface SidebarItemProps {
  title: string;
  path: string;
  params?: URLSearchParams;
  icon: ReactElement;
  isCollapsable: boolean;
  collapseOptions?: CollapseOptionsInterface;
  badgeNumber?: number;
}

const useStyle = makeStyles()((theme) => ({
  root: {
    color: grey[600],
    "& .MuiListItemIcon-root": {
      color: grey[600]
    },
    "&:hover": {
      backgroundColor: theme.palette.accent.light
    },
    "&.Mui-selected": {
      //TODO we need to find proper way to override it.
      background: `${theme.palette.accent.light}!important`,
      color: theme.palette.accent.main,
      "& .MuiListItemIcon-root": {
        color: theme.palette.accent.main
      },
      "& .MuiTypography-body1": {
        color: theme.palette.accent.main
      }
    }
  }
}));

const externalUrlRegEx = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

export const SidebarItem: FC<SidebarItemProps> = ({
  title,
  path,
  params,
  icon,
  isCollapsable,
  collapseOptions,
  badgeNumber
}) => {
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const navigate = useNavigateWithPropertyId();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(isMobile);
  const { pathname } = useLocation();
  const { classes: selectClasses } = useStyle();
  const { spacing } = useTheme();
  const { hasRole, hasSuperuserRole } = useAuth();
  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  const handleClick = useCallback(
    (path: string, params?: URLSearchParams) => {
      const isExternalLink = externalUrlRegEx.test(path);
      if (isExternalLink) {
        window.open(path, "_blank");
      } else {
        navigate(path, params);
        if (isMobile) {
          setOpenSideNavigation(false);
        }
      }
    },
    [navigate, isMobile, setOpenSideNavigation]
  );

  const exactMatch = (itemPath: string) =>
    itemPath
      ? !!matchPath(
          {
            path: resolvePath(itemPath).pathname,
            end: true
          },
          pathname
        )
      : false;

  const itemReservationStopPropagation = useCallback(
    (e: SyntheticEvent<Element, Event>) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const iconExpandLogic = useCallback(
    (visible: boolean) => {
      if (visible) {
        return isOpen ? (
          <ExpandLessIcon onClick={itemReservationStopPropagation} />
        ) : (
          <ExpandMoreIcon onClick={itemReservationStopPropagation} />
        );
      }
    },
    [isOpen, itemReservationStopPropagation]
  );

  return (
    <ListItem key={title} disablePadding sx={{ display: isCollapsable ? "block" : "flex" }}>
      <Tooltip
        title={title}
        placement="right"
        sx={{
          justifyContent: "center"
        }}
        arrow
      >
        <Box
          sx={{
            width: "100%"
          }}
        >
          <ListItemButton
            selected={exactMatch(path)}
            onClick={() => {
              handleClick(path, params);
              if (isCollapsable) {
                setIsOpen(true);
              }
            }}
            sx={{
              borderRadius: 48,
              ...(!isCollapsable ? { maxWidth: openSideNavigation ? "100%" : spacing(6.5) } : {})
            }}
            classes={selectClasses}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                ...(isCollapsable ? { alignItems: "center" } : {})
              }}
            >
              <Badge
                sx={{ "& .MuiBadge-badge": { top: -8, right: -8 } }}
                badgeContent={badgeNumber}
              >
                {icon}
              </Badge>

              {isCollapsable ? iconExpandLogic(!openSideNavigation) : <></>}
            </ListItemIcon>
            <Fade in={openSideNavigation}>
              <ListItemText
                sx={{
                  ml: 3
                }}
                primary={title}
              />
            </Fade>
            {isCollapsable ? iconExpandLogic(openSideNavigation) : <></>}
          </ListItemButton>
        </Box>
      </Tooltip>
      {isCollapsable && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            sx={{
              pl: 1,
              maxWidth: openSideNavigation ? "100%" : spacing(7)
            }}
          >
            {collapseOptions?.items
              .filter(
                (items) =>
                  items?.roles?.some(
                    (role) =>
                      hasRole(role) ||
                      (items.roles.includes(SUPERUSER_ROLE) ? hasSuperuserRole() : false)
                  )
              )
              .map((item) => (
                <Tooltip
                  key={item.title}
                  title={item.title}
                  disableHoverListener={openSideNavigation}
                  placement="right"
                  sx={{
                    justifyContent: "center"
                  }}
                  arrow
                >
                  <ListItemButton
                    sx={{
                      borderRadius: 48
                    }}
                    classes={selectClasses}
                    selected={exactMatch(item.path)}
                    onClick={() => {
                      handleClick(item.path, item.params);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <Fade in={openSideNavigation}>
                      <ListItemText
                        sx={{
                          ml: 3
                        }}
                        primary={item.title}
                      />
                    </Fade>
                  </ListItemButton>
                </Tooltip>
              ))}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};
