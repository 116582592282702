import React, { FC } from "react";
import { ChatItem } from "./chat-item";
import { useGetConversationDetailsQueryEnhanced } from "../../graphql-messaging/queries/enhanced-queries/get-conversation-details-enhanced";
import { ChatScrollToBottom } from "../../components/chat-scroll-to-bottom/chat-scroll-to-bottom";
import { useMessageSelectId } from "./use-message-select-id";
import { Box } from "@mui/material";

interface ChatMessagesProps {
  conversationId: string;
  selectedThread: boolean;
}

export const ChatMessages: FC<ChatMessagesProps> = ({ conversationId, selectedThread }) => {
  const { data } = useGetConversationDetailsQueryEnhanced(
    {
      filter: {
        conversationId: conversationId,
        pageable: { pageNumber: 0, pageSize: 1, sort: "createdAt,asc" }
      }
    },
    {
      skip: !conversationId,
      refetchOnMountOrArgChange: true
    }
  );
  const conversation = data?.GetConversations?.content?.[0];
  const { selectedMessageId } = useMessageSelectId();

  return conversation?.messages ? (
    <>
      {conversation?.messages?.map((message) => (
        <Box my={3} key={message?.messageId}>
          <ChatItem
            message={message}
            selectedMessageId={selectedMessageId}
            guestIdentity={conversation?.guestIdentity}
          />
        </Box>
      ))}
      {selectedThread && (
        <ChatScrollToBottom
          selectedMessageId={selectedMessageId}
          messages={conversation?.messages}
        />
      )}
    </>
  ) : null;
};
