import Paper from "@mui/material/Paper";
import { Grid2, IconButton } from "@mui/material";
import { EmptyState, Heading2 } from "@likemagic-tech/sv-magic-library";
import React, { FC, useCallback, useEffect, useState } from "react";
import Plus from "../../../../icons/Plus";
import { PreferencesForProfile } from "../../types/profile";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useProperty } from "src/hooks/use-property";
import CloseIcon from "@mui/icons-material/Close";
import { useProfileDrawer } from "../use-profile-drawer";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { useDeletePreferenceFromProfile } from "src/hooks/use-delete-preference-from-profile";
import { DisplayPreference } from "../../../reservations/reservation-details/reservation-details-tabs/overview/rows-for-table-guest-overview/display-preference";
import { GuestPreferencesModal } from "../../../reservations/reservation-details/preferences/guest-preferences-modal";

interface ProfileDrawerPreferencesCardProps {
  preferences: PreferencesForProfile | undefined;
  userFullName: string;
}

export const ProfileDrawerPreferencesCard: FC<ProfileDrawerPreferencesCardProps> = ({
  preferences,
  userFullName
}) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const [addPreferencesModalOpened, setAddPreferencesModalOpened] = useState(false);
  const { selectedProperty } = useProperty();
  const [removePreferenceToProfile, resultRemoveToProfile] = useDeletePreferenceFromProfile();
  const { profileGuestIdentityId } = useProfileDrawer();

  const handleRemovePreference = useCallback(
    async (preferenceId: string) => {
      await removePreferenceToProfile({
        preferenceId: preferenceId,
        profileId: Number(profileGuestIdentityId)
      });
    },
    [profileGuestIdentityId, removePreferenceToProfile]
  );

  useEffect(() => {
    if (resultRemoveToProfile.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      resultRemoveToProfile.reset();
    }
  }, [resultRemoveToProfile, dispatch, t]);

  return (
    <Grid2 size={{ xs: 12 }}>
      <Paper elevation={0}>
        <Grid2
          container
          sx={{
            gap: 3,
            p: 3
          }}
        >
          <Grid2
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "end",
              width: "100%"
            }}
          >
            <Grid2 size={{ xs: 11 }}>
              <Heading2>{t(`labels__profiles_preferences`)}</Heading2>
            </Grid2>
            <Grid2 size={{ xs: 1 }}>
              <Grid2
                container
                sx={{
                  gap: 2,
                  justifyContent: "flex-end"
                }}
              >
                <IconButton onClick={() => setAddPreferencesModalOpened(true)}>
                  <Plus />
                </IconButton>
              </Grid2>
            </Grid2>
          </Grid2>

          {preferences && preferences.length > 0 ? (
            <Grid2
              container
              sx={{
                gap: 1
              }}
            >
              {preferences?.map((preference) => (
                <DisplayPreference
                  propertyId={selectedProperty?.propertyId ?? ""}
                  preference={preference.pmsId}
                  icon={<CloseIcon />}
                  onIconClick={() => handleRemovePreference(preference.pmsId)}
                  key={preference.pmsId}
                />
              ))}
            </Grid2>
          ) : (
            <EmptyState title={t("labels__profile_no_preferences")} />
          )}
        </Grid2>
      </Paper>
      {addPreferencesModalOpened && (
        <GuestPreferencesModal
          isOpen={addPreferencesModalOpened}
          propertyId={selectedProperty?.propertyId ?? ""}
          onClose={() => setAddPreferencesModalOpened(false)}
          userFullName={userFullName}
        />
      )}
    </Grid2>
  );
};
