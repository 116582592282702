import { useGetOrderItemTransactionTypesQuery } from "../graphql/queries/GetOrderItemTransactionTypes.generated";
import { transformOrderItemTransactionType } from "../graphql/transform/transform-order-item-transaction-type";
import { OrderItemTransactionTypeResponse } from "../domain/order-item-transaction-type";
import { useProperty } from "./use-property";

export const useOrderItemTransactionTypes = ({
  pmsPropertyId,
  skip
}: {
  pmsPropertyId: string;
  skip?: boolean;
}): { data: OrderItemTransactionTypeResponse[] | undefined; isLoading: boolean } => {
  const { selectedProperty } = useProperty();

  const { data, isLoading } = useGetOrderItemTransactionTypesQuery(
    {
      pmsPropertyId: pmsPropertyId,
      // @ts-ignore
      skipSpinner: true
    },
    {
      skip: !pmsPropertyId || skip
    }
  );

  return {
    data: data?.GetOrderItemTransactionTypes.map((value) =>
      transformOrderItemTransactionType(value, selectedProperty?.details?.currencyCode)
    ),
    isLoading
  };
};
