import type { FC } from "react";
import { Box, Grid2 } from "@mui/material";
import { useProperty } from "../../hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import PageTitle from "src/components/page-title";
import { ReservationTable } from "../../features/reservations/reservation-table/reservation-table";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { DEPARTURE_RESERVATIONS_SEARCH_FILTERS } from "src/components/table/hooks/use-search-filter-params";

const Departure: FC = () => {
  const { t } = useTranslationWrapper();
  const { selectedPropertyPreview } = useProperty();
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        sx={{
          px: isMobile ? 0 : 3,
          py: isMobile ? 0 : 4
        }}
      >
        {!isMobile && (
          <Grid2
            container
            spacing={3}
            size={{ xs: 12 }}
            sx={{
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Grid2
              sx={{
                pb: 2.5
              }}
            >
              <PageTitle subTitle={selectedPropertyPreview} title={t("title__departures")} />
            </Grid2>
          </Grid2>
        )}
        <ReservationTable defaultFilter={DEPARTURE_RESERVATIONS_SEARCH_FILTERS} />
      </Box>
    </>
  );
};

export default Departure;
