import { getI18nSelectedLanguage } from "../../utils/language";
import { useSinglePrismicDocument } from "@prismicio/react";
import { LanguageType } from "../../components/dashboard/release-note-popover";
import { useProperty } from "../../hooks/use-property";
import { useContext, useMemo } from "react";
import { TenantContext, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";

export const useReleaseNote = () => {
  const language = getI18nSelectedLanguage();
  const release_notes = useSinglePrismicDocument("release_notes", {
    lang: LanguageType[language as keyof typeof LanguageType]
  });

  const { selectedProperty } = useProperty();

  const { pms } = useContext(TenantContext);
  const config = useDoorProviderConfig(selectedProperty?.propertyId ?? "");

  return useMemo(() => {
    const arrayOfReleaseNotes: Array<any> = release_notes[0]?.data.release_notes; //need to test if it's always this position

    return (
      arrayOfReleaseNotes
        ?.map((note) => ({
          date: note.date,
          title: note.title,
          description: note.description,
          doorProviders: [
            note.door_provider,
            note.door_provider2,
            note.door_provider3,
            note.door_provider4
          ].filter((item) => !!item),
          pmses: [note.pms, note.pms2].filter((item) => !!item)
        }))
        .filter((item) => {
          return (
            item.doorProviders.length === 0 || item.doorProviders.includes(config?.doorProvider)
          );
        })
        .filter((item) => {
          return item.pmses.length === 0 || item.pmses.includes(pms);
        })
        .reverse() ?? []
    );
  }, [release_notes, config?.doorProvider, pms]);
};
