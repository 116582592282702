import React, { FC } from "react";
import { Grid2 } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { formatPriceToString } from "../../../utils/price";
import { useTheme } from "@mui/material/styles";
import { Price } from "../../../domain/price";

interface TotalPricePreviewProps {
  price: Price;
  label: string;
}

export const TotalPricePreview: FC<TotalPricePreviewProps> = ({ price, label }) => {
  const { shape } = useTheme();

  return (
    <Grid2
      container
      sx={{
        justifyContent: "space-between",
        p: 2.5,
        background: grey[100],
        borderRadius: (shape.borderRadius as number) * 0.25
      }}
    >
      <Grid2>
        <Heading4>{label}</Heading4>
      </Grid2>
      <Grid2>
        <Heading4>{formatPriceToString(price)}</Heading4>
      </Grid2>
    </Grid2>
  );
};
