import * as React from "react";
import { FC, PropsWithChildren } from "react";
import { Grid2, Paper } from "@mui/material";

export const ManualWizardStepItem: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper sx={{ borderRadius: 2 }} elevation={0}>
      <Grid2
        container
        direction="row"
        sx={{
          p: 3,
          alignItems: "center"
        }}
      >
        {children}
      </Grid2>
    </Paper>
  );
};
