import { FC } from "react";
import { Platform } from "../../graphql-messaging/generated/graphql";
import { EmailOutlined, QuestionMarkOutlined, SmsOutlined, WhatsApp } from "@mui/icons-material";

export const ChatItemPlatformIcon: FC<{ platform?: Platform }> = ({ platform }) => {
  switch (platform) {
    case Platform.Whatsapp:
      return <WhatsApp sx={{ fontSize: 14 }} />;
    case Platform.Sms:
      return <SmsOutlined sx={{ fontSize: 14 }} />;
    case Platform.Email:
      return <EmailOutlined sx={{ fontSize: 14 }} />;
    default:
      return <QuestionMarkOutlined sx={{ fontSize: 14 }} />;
  }
};
