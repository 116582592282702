import * as Types from "../generated/graphql";

import { messagingApi } from "../messaging.api";
export type GetEmployeesQueryVariables = Types.Exact<{
  pmsPropertyIds?: Types.InputMaybe<
    Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
  >;
}>;

export type GetEmployeesQuery = {
  __typename?: "Query";
  GetEmployees: Array<{
    __typename?: "Employee";
    id: string;
    actorId: number;
    keycloakUUID?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    pmsPropertyIds: Array<string>;
  }>;
};

export const GetEmployeesDocument = `
    query GetEmployees($pmsPropertyIds: [String!]) {
  GetEmployees(pmsPropertyIds: $pmsPropertyIds) {
    id
    actorId
    keycloakUUID
    firstName
    lastName
    pmsPropertyIds
  }
}
    `;

const injectedRtkApi = messagingApi.injectEndpoints({
  endpoints: (build) => ({
    GetEmployees: build.query<GetEmployeesQuery, GetEmployeesQueryVariables | void>({
      query: (variables) => ({ document: GetEmployeesDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetEmployeesQuery, useLazyGetEmployeesQuery } = injectedRtkApi;
