import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid2 } from "@mui/material";
import { Button, DefaultConfirmationIcon, Subtitle } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useDispatch } from "../../../../../store";
import { useManualCheckinId } from "../../use-manual-checkin-id";
import { ReservationStatus } from "../../../../../domain/reservation-status";
import { usePerformReservationAction } from "../../../../../hooks/use-reservation-actions";
import { openBanner } from "../../../../../slices/banner.slice";
import { ManualWizardStepItem } from "../../../components/manual-wizard/manual-wizard-step-item";
import { ManualWizardStepItemNavigation } from "../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { isCheckInAvailable } from "../../../../../utils/reservation-utils";
import { useProperty } from "../../../../../hooks/use-property";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { AllowedActionEnum } from "../../../../../domain/reservation-table-dto";
import { Params } from "../../../../../hooks/use-select-id";
import { AssignKeyModal } from "../../../../../components/key-assign/assign-key-modal";
import { ReservationCheckInOutAuthor } from "../../../../../graphql/generated/graphql";
import { useReservationDetailsId } from "../../../reservation-details/reservation-details/use-reservation-details-id";
import { ReservationTabs } from "../../../reservation-details/reservation-details/reservation-details-constants";

export const ManualCheckinStepConfirmation: FC<ManualCheckinStepProps> = ({
  reservationDetails
}) => {
  const { t } = useTranslationWrapper();
  const { manualCheckinId, setManualCheckinId } = useManualCheckinId();
  const dispatch = useDispatch();
  const [assignKeyModalOpened, setAssignKeyModalOpened] = useState(false);
  const { setSelectId } = useReservationDetailsId();

  const { selectedProperty } = useProperty();
  const [performAction, result] = usePerformReservationAction();
  const [checkinClicked, setCheckinClicked] = useState(false);

  useEffect(() => {
    if (result.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      result.reset();
    }

    if (result.isError && checkinClicked) {
      setCheckinClicked(false);
    }
  }, [result, dispatch, t, setManualCheckinId, checkinClicked]);

  const isCheckinAvailable = useMemo(
    () =>
      reservationDetails?.arrival &&
      reservationDetails?.reservationStatus === ReservationStatus.CONFIRMED &&
      manualCheckinId &&
      isCheckInAvailable(reservationDetails.arrival, selectedProperty?.details?.timeZone),
    [
      reservationDetails?.arrival,
      reservationDetails?.reservationStatus,
      manualCheckinId,
      selectedProperty?.details?.timeZone
    ]
  );

  const checkin = useCallback(async () => {
    if (isCheckinAvailable) {
      setCheckinClicked(true);
      await performAction({
        reservationId: manualCheckinId!,
        action: AllowedActionEnum.CHANGE_STATUS_TO_IN_HOUSE,
        payload: { author: ReservationCheckInOutAuthor.CheckinWizard }
      });
    }
  }, [isCheckinAvailable, manualCheckinId, performAction]);

  const goToBilling = () => {
    if (manualCheckinId) {
      setSelectId(
        manualCheckinId,
        new URLSearchParams({
          [Params.SELECTED_MANUAL_CHECKIN_RESERVATION_ID]: "",
          [Params.MANUAL_CHECKIN_STEP]: "",
          [Params.SELECTED_TAB]: ReservationTabs.BILLING
        })
      );
    }
  };

  return (
    <Box
      sx={{
        mt: 2
      }}
    >
      <ManualWizardStepItem>
        <Grid2
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Grid2
            size={{ xs: 12 }}
            sx={{
              textAlign: "center"
            }}
          >
            <DefaultConfirmationIcon />
          </Grid2>

          <Grid2 size={{ xs: 12 }}>
            <Subtitle textAlign="center">
              {reservationDetails?.reservationStatus === ReservationStatus.IN_HOUSE
                ? t("labels__manual_checkin_success_checkin")
                : t("labels__manual_checkin_success")}
            </Subtitle>
          </Grid2>

          <Grid2
            size={{ md: 4, xs: 6 }}
            sx={{
              mt: 4
            }}
          >
            <Button variant="secondary" fullWidth onClick={goToBilling}>
              {t("buttons__go_to_billing")}
            </Button>
          </Grid2>

          <Grid2
            size={{ xs: 6, md: 4 }}
            sx={{
              mt: 4
            }}
          >
            <Button
              variant="secondary"
              fullWidth
              onClick={() => setAssignKeyModalOpened(true)}
              disabled={reservationDetails?.reservationStatus !== ReservationStatus.IN_HOUSE}
            >
              {t("buttons__encode_key")}
            </Button>
          </Grid2>

          {reservationDetails?.reservationStatus !== ReservationStatus.IN_HOUSE && (
            <Grid2
              size={{ xs: 12, md: 8 }}
              sx={{
                mt: 2
              }}
            >
              <Button
                variant="primary"
                fullWidth
                onClick={checkin}
                disabled={!isCheckinAvailable || checkinClicked}
              >
                {t("buttons__checkin")}
              </Button>
            </Grid2>
          )}
        </Grid2>
      </ManualWizardStepItem>
      <ManualWizardStepItemNavigation />
      {reservationDetails && assignKeyModalOpened && (
        <AssignKeyModal
          isOpen
          reservation={reservationDetails}
          onClose={() => {
            setAssignKeyModalOpened(false);
          }}
        />
      )}
    </Box>
  );
};
