import * as React from "react";
import { FC, ReactNode } from "react";
import { Grid2 } from "@mui/material";
import { Button } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";

interface ManualWizardStepItemNavigationProps {
  children?: ReactNode;
  onNextStep?: () => void;
  onPreviousStep?: () => void;
  disableNext?: boolean;
  disablePrevious?: boolean;
  nextLabel?: string;
  backLabel?: string;
}

export const ManualWizardStepItemNavigation: FC<ManualWizardStepItemNavigationProps> = ({
  onPreviousStep,
  onNextStep,
  disableNext = false,
  disablePrevious = false,
  nextLabel,
  backLabel,
  children
}) => {
  const { t } = useTranslationWrapper();

  return (
    <Grid2
      container
      spacing={3}
      sx={{
        justifyContent: "flex-end",
        mt: 2
      }}
    >
      <Grid2
        sx={{
          minWidth: 140
        }}
      >
        {onPreviousStep && (
          <Button variant="secondary" onClick={onPreviousStep} fullWidth disabled={disablePrevious}>
            {backLabel ?? t("buttons__back")}
          </Button>
        )}
      </Grid2>
      {children}
      <Grid2
        sx={{
          minWidth: 140
        }}
      >
        {onNextStep && (
          <Button
            variant="primary"
            onClick={onNextStep}
            fullWidth
            disabled={disableNext}
            type="submit"
          >
            {nextLabel ?? t("buttons__next")}
          </Button>
        )}
      </Grid2>
    </Grid2>
  );
};
