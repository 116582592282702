import { Heading3 } from "@likemagic-tech/sv-magic-library";
import { Grid2, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface DialogTitleModalProps {
  title: string;
  onClose: () => void;
}

export const DialogTitleModal: FC<DialogTitleModalProps> = ({ title, onClose }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  return (
    <Grid2
      container
      direction="row"
      sx={{
        alignItems: "center"
      }}
    >
      <Grid2 size={{ xs: 11 }}>
        <Heading3 sx={{ color: theme.palette.text.primary }}>{t(title)}</Heading3>
      </Grid2>
      <Grid2
        size={{ xs: 1 }}
        sx={{
          display: "flex",
          justifyContent: "end"
        }}
      >
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Grid2>
    </Grid2>
  );
};
