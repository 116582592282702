import React, { FC, useCallback, useRef, useState } from "react";
import { Box, Grid2, Toolbar, ToolbarProps } from "@mui/material";
import { addDays, addWeeks, isBefore, isDate, isSameDay, startOfDay } from "date-fns";
import { DatePicker } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { Button, formatDate, formatDateTime, Heading3 } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../../hooks/use-property";
import { DateRangeOutlined } from "@mui/icons-material";
import { getI18nSelectedLanguage } from "src/utils/language";

interface QuickButtonsToolbarProps extends ToolbarProps {
  onAccept: (value: any) => void;
}

export const QuickButtonsToolbar: FC<QuickButtonsToolbarProps> = ({ onAccept }) => {
  const { spacing } = useTheme();
  const { t } = useTranslationWrapper();

  const handleTodayClick = () => {
    onAccept(new Date());
  };

  const handleTomorrowClick = () => {
    onAccept(addDays(new Date(), 1));
  };

  const handleNextWeekClick = () => {
    onAccept(addWeeks(new Date(), 1));
  };

  return (
    <Box>
      <Grid2
        container
        sx={{
          pt: 2,
          px: 3,
          alignItems: "center"
        }}
      >
        <Grid2 size={{ xs: 12 }}>
          <Heading3>{t("labels__task_due_date")}</Heading3>
        </Grid2>
      </Grid2>
      <Toolbar
        sx={{
          flex: "1",
          justifyContent: "space-between",
          paddingX: spacing(1.5) + "!important",
          gap: 0.5
        }}
      >
        <Button color="neutral" onClick={handleTodayClick}>
          {t("labels__additional_filter_TODAY")}
        </Button>
        <Button color="neutral" onClick={handleTomorrowClick}>
          {t("labels__additional_filter_TOMORROW")}
        </Button>
        <Button color="neutral" onClick={handleNextWeekClick}>
          {t("labels__additional_filter_NEXT_WEEK")}
        </Button>
      </Toolbar>
    </Box>
  );
};

interface DueModalProps {
  value?: string;
  iconOnly?: boolean;
  onChange: (label: string, value: string) => void;
  QuickButtonsToolbar: any;
}

export const DueModal: FC<DueModalProps> = ({ onChange, value, iconOnly }) => {
  const PROPERTY_LABEL = "due";
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const anchorRef = useRef<any>(null);

  const onSubmit = useCallback(
    (newDate: Date) => {
      onChange(PROPERTY_LABEL, new Date(newDate).toISOString());
      setOpen(false);
    },
    [onChange, setOpen]
  );

  const onChangeHandler = useCallback(
    (date: Date) => {
      if (date && isDate(date)) {
        onSubmit(date);
      }
    },
    [onSubmit]
  );

  const QuickButtonsToolbarCallBack = useCallback(
    (props: any) => <QuickButtonsToolbar {...props} onAccept={onChangeHandler} />,
    [onChangeHandler]
  );

  const shouldDisableDate = (date: Date) => {
    // Disable all past dates except for the currently selected date
    // This fixes the issue in selecting todays date
    const todayStartOfDay = startOfDay(new Date());
    const dateStartOfDay = startOfDay(date);
    const formattedDate = formatDate(value);
    if (value && formattedDate) {
      return (
        isBefore(dateStartOfDay, todayStartOfDay) &&
        !isSameDay(dateStartOfDay, startOfDay(new Date(formattedDate)))
      );
    }
    return isBefore(dateStartOfDay, todayStartOfDay);
  };

  return (
    <Box>
      <DatePicker
        onClose={() => setOpen(false)}
        open={open}
        disableOpenPicker
        showToolbar
        PopperProps={{
          placement: "auto",
          anchorEl: anchorRef.current
        }}
        shouldDisableDate={shouldDisableDate}
        value={value || ""}
        onAccept={(date: Date | null) => date && onChangeHandler(date)}
        ToolbarComponent={QuickButtonsToolbarCallBack}
        renderInput={({ inputRef }: any) => (
          <Button
            ref={inputRef}
            variant={iconOnly ? "primary" : "secondary"}
            startIcon={<DateRangeOutlined style={{ color: palette.common.black }} />}
            onClick={(event) => {
              anchorRef.current = event.currentTarget;
              setOpen(true);
            }}
            sx={{ width: "100%", textAlign: "start" }}
          >
            {!iconOnly &&
              (value
                ? formatDateTime(value, language, selectedProperty?.details?.timeZone)
                : t("labels__task_due_date"))}
          </Button>
        )}
        onChange={() => {}}
      />
    </Box>
  );
};
