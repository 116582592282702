import { Grid2 } from "@mui/material";
import { FC, useMemo } from "react";
import { useProperty } from "src/hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import PersonCard from "./person-card";
import { getGuestInsightInfoDataToShow } from "./reservation-details-guest-insight-utils";
import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";

interface ReservationDetailsGuestInsightProps {
  reservation: ReservationDetailsDTO;
}

const ReservationDetailsGuestInsight: FC<ReservationDetailsGuestInsightProps> = ({
  reservation
}) => {
  const { selectedProperty } = useProperty();
  const { t } = useTranslationWrapper();
  const { disabledCountriesForIdentification } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  const {
    booker,
    primaryGuest,
    travelBuddies,
    propertyId,
    idCheckStatus,
    primaryGuestSameAsBooker
  } = reservation || {};

  const info = useMemo(
    () =>
      getGuestInsightInfoDataToShow({
        booker,
        primaryGuest,
        travelBuddies,
        idCheckStatus,
        primaryGuestSameAsBooker,
        selectedPropertyTimezone: selectedProperty?.details?.timeZone ?? "",
        translation: t,
        disabledCountriesForIdentification
      }),
    [
      booker,
      primaryGuest,
      travelBuddies,
      idCheckStatus,
      primaryGuestSameAsBooker,
      selectedProperty,
      t,
      disabledCountriesForIdentification
    ]
  );

  return (
    <>
      {info?.map((profile) => (
        <Grid2
          container
          key={`profile-card-${profile.id}`}
          sx={{
            py: 2
          }}
        >
          <Grid2 size={{ xs: 12 }}>
            <PersonCard personInfo={profile} propertyId={propertyId} />
          </Grid2>
        </Grid2>
      ))}
    </>
  );
};

export default ReservationDetailsGuestInsight;
