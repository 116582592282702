import { convert } from "html-to-text";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import ForumIcon from "@mui/icons-material/ForumOutlined";
import SmsIcon from "@mui/icons-material/SmsOutlined";
import {
  ConversationStatus,
  MessageStatus,
  Platform,
  ReservationStatus
} from "src/graphql-messaging/generated/graphql";
import { ConversationMessagePreview } from "../domain/conversation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import MarkAsUnreadOutlinedIcon from "@mui/icons-material/MarkAsUnreadOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import MailLockOutlinedIcon from "@mui/icons-material/MailLockOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import { Palette } from "@mui/material";
import {
  FilterMapInterface,
  FilterObjectType,
  serializeIdOfFilter
} from "../../components/table/filters/table-filters";
import {
  CONVERSATION_PREDEFINED_FILTER,
  ConversationTabsValues
} from "../domain/conversation-constants";
import { compareFiltersMaps } from "../../utils/filters";
import { ConversationMessageStatus } from "../../domain/ConversationMessageStatus";
import { Params } from "../../hooks/use-select-id";
import { FilterOperator } from "../../types/filters/filters";

export const mapMessageConditionColors = (conversationStatus: ConversationStatus) => {
  switch (conversationStatus) {
    case ConversationStatus.InProgress:
      return "warning";
    case ConversationStatus.New:
      return "primary";
    case ConversationStatus.Resolved:
      return "success";
  }
};

export const channelIconMap = (channel: any) => {
  switch (channel) {
    case Platform.Whatsapp:
      return WhatsAppIcon;
    case Platform.Email:
      return EmailIcon;
    case Platform.Sms:
      return SmsIcon;
    default:
      return ForumIcon;
  }
};

export const getMessageText = (message?: ConversationMessagePreview | null) => {
  switch (message?.channel?.platform) {
    case Platform.Whatsapp:
      return getText(message);
    case Platform.Email:
      return getEmail(message);
    default:
      return getText(message);
  }
};

const getText = (message?: ConversationMessagePreview | null) => {
  if (message?.messageBirdVersion === "2") {
    return message?.content?.text?.text ?? message?.content?.list?.text ?? "";
  }
  return message?.content?.text ?? "";
};

const getEmail = (message?: ConversationMessagePreview | null) => {
  const text = convert(message?.content?.email?.content?.html, {});

  if (message?.messageBirdVersion === "2") {
    return message?.content?.text?.text ?? text;
  }
  return message?.content?.email?.content?.text ?? text;
};

export const messageStatusIcons = (status: MessageStatus | undefined, palette: Palette) => {
  switch (status) {
    case ConversationMessageStatus.Sent:
      return <CheckCircleOutlineIcon sx={{ color: palette.success.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Opened:
      return <DraftsOutlinedIcon sx={{ color: palette.info.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Accepted:
      return <CircleOutlinedIcon sx={{ color: palette.success.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Other:
      return <HorizontalRuleOutlinedIcon sx={{ fontSize: 14 }} />;
    case ConversationMessageStatus.Pending:
      return <PendingOutlinedIcon sx={{ color: palette.info.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Bounce:
      return <CloseIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Buffered:
      return <ForwardToInboxIcon sx={{ color: palette.info.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Clicked:
      return <AdsClickIcon sx={{ color: palette.info.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Delayed:
      return <ScheduleSendIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Deleted:
      return <DeleteIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Delivered:
      return <CheckCircleOutlineIcon sx={{ color: palette.success.main, fontSize: 14 }} />;
    case ConversationMessageStatus.DeliveryFailed:
      return <CancelIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Dispatched:
      return <MarkAsUnreadOutlinedIcon sx={{ color: palette.info.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Expired:
      return <EventBusyOutlinedIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Failed:
      return <CancelIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.ListUnsubscribe:
      return <UnsubscribeOutlinedIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.OutOfBounded:
      return <OutboundOutlinedIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Read:
      return <CheckCircleIcon sx={{ color: palette.success.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Received:
      return <CheckCircleOutlineIcon sx={{ color: palette.success.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Rejected:
      return <FeedbackOutlinedIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.SpamComplaint:
      return <MailLockOutlinedIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Transmitted:
      return <MarkEmailReadOutlinedIcon sx={{ color: palette.info.main, fontSize: 14 }} />;
    case ConversationMessageStatus.Unknown:
      return <HorizontalRuleOutlinedIcon sx={{ fontSize: 14 }} />;
    case ConversationMessageStatus.Unsupported:
      return <CancelIcon sx={{ color: palette.error.main, fontSize: 14 }} />;
    default:
      return <HorizontalRuleOutlinedIcon sx={{ fontSize: 14 }} />;
  }
};

export const messageStatusLabelColor = (status: MessageStatus | undefined, palette: Palette) => {
  switch (status) {
    case ConversationMessageStatus.Accepted:
    case ConversationMessageStatus.Sent:
    case ConversationMessageStatus.Read:
    case ConversationMessageStatus.Received:
    case ConversationMessageStatus.Delivered:
      return palette.success.main;
    case ConversationMessageStatus.Pending:
    case ConversationMessageStatus.Opened:
    case ConversationMessageStatus.Buffered:
    case ConversationMessageStatus.Clicked:
    case ConversationMessageStatus.Dispatched:
    case ConversationMessageStatus.Transmitted:
      return palette.info.main;
    case ConversationMessageStatus.Bounce:
    case ConversationMessageStatus.Delayed:
    case ConversationMessageStatus.Deleted:
    case ConversationMessageStatus.DeliveryFailed:
    case ConversationMessageStatus.Expired:
    case ConversationMessageStatus.Failed:
    case ConversationMessageStatus.ListUnsubscribe:
    case ConversationMessageStatus.OutOfBounded:
    case ConversationMessageStatus.Rejected:
    case ConversationMessageStatus.SpamComplaint:
    case ConversationMessageStatus.Unsupported:
      return palette.error.main;
    default:
      return palette.common.black;
  }
};

export const getSelectedTabBaseOnFilters = (
  filterMap: FilterMapInterface,
  currentActorId: string
) =>
  (Object.keys(CONVERSATION_PREDEFINED_FILTER).find((tabItem) => {
    const selectedFilterValue = CONVERSATION_PREDEFINED_FILTER[tabItem];
    const copyOfFilterMap = { ...filterMap };
    delete copyOfFilterMap[Params.SELECTED_CONVERSATION_ID];
    delete copyOfFilterMap[Params.SELECTED_PROPERTY_ID];
    delete copyOfFilterMap[Params.SELECTED_RESERVATION_ID];

    // delete property filter from filter map since we wanto stay on the same tab when property is selected
    const propertyFilter = serializeIdOfFilter({
      type: FilterObjectType.PROPERTY,
      operator: FilterOperator.In,
      name: FilterObjectType.PROPERTY
    });
    delete copyOfFilterMap[propertyFilter];

    return compareFiltersMaps(
      selectedFilterValue({ mineActorId: currentActorId }),
      copyOfFilterMap
    );
  }) as ConversationTabsValues) || ConversationTabsValues.NONE;

export const mappingStatusColors = (status: ReservationStatus | undefined) => {
  switch (status) {
    case ReservationStatus.InHouse:
      return "success";
    case ReservationStatus.Confirmed:
      return "warning";
    case ReservationStatus.NoShow:
      return "error";
    default:
      return undefined;
  }
};
