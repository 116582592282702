import * as React from "react";
import { FC } from "react";
import { FilterObjectType, FilterObjectWithType, serializeIdOfFilter } from "../table-filters";
import {
  AutocompleteFilter,
  AutocompleteFilterType,
  AutocompleteFilterValue
} from "../components/autocomplete-filter";
import { AutocompleteOption } from "../../../autocomplete-component";
import { Grid2, IconButton } from "@mui/material";
import { FilterOperator } from "../../../../types/filters/filters";
import Minus from "../../../../icons/Minus";

interface EmployeeFilterItemProps {
  filter: FilterObjectWithType;
  onChange: (changedFilter: AutocompleteFilterValue, oldType?: AutocompleteFilterType) => void;
  removeFilter: (val: Array<string>) => void;
  hideMinus?: boolean;
  allowedFilters: Array<AutocompleteFilterType>;
  employeeOptions?: Array<AutocompleteOption>;
}

export const EmployeeFilterItem: FC<EmployeeFilterItemProps> = ({
  filter,
  onChange,
  removeFilter,
  hideMinus,
  allowedFilters,
  employeeOptions
}) => {
  return (
    <Grid2
      container
      sx={{
        mb: 2,
        alignItems: "center"
      }}
    >
      <Grid2 size={{ xs: 11 }}>
        <AutocompleteFilter
          value={{
            id: filter.value ?? "",
            type: filter.name as AutocompleteFilterType
          }}
          options={employeeOptions ?? []}
          onChange={onChange}
          allowedFilters={allowedFilters}
        />
      </Grid2>
      <Grid2
        size={{ xs: 1 }}
        sx={{
          textAlign: "right"
        }}
      >
        {!hideMinus && (
          <IconButton
            onClick={() => {
              removeFilter([
                serializeIdOfFilter({
                  type: FilterObjectType.AUTOCOMPLETE,
                  operator: FilterOperator.Equality,
                  name: filter.name
                })
              ]);
            }}
          >
            <Minus />
          </IconButton>
        )}
      </Grid2>
    </Grid2>
  );
};
