import * as React from "react";
import { ChangeEvent, FC, useState } from "react";
import { Autocomplete } from "@mui/material";
import { Chip, Input } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

interface MultipleInputProps {
  label: string;
  value: Array<string>;
  setValue: (arg: Array<string>) => void;
  error?: string;
  validateItem?: (item: string) => boolean;
  options?: Array<string>;
  translate?: boolean;
  freeSolo?: boolean;
}

export const MultipleInput: FC<MultipleInputProps> = ({
  value,
  setValue,
  label,
  error,
  validateItem,
  options,
  translate,
  freeSolo = true
}) => {
  const { t } = useTranslationWrapper();

  const [inputState, setInputState] = useState("");
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputState(event.target.value);
  };

  return (
    <Autocomplete
      multiple
      value={value}
      options={options ?? ([] as Array<string>)}
      noOptionsText={freeSolo ? <> </> : t("labels__no_options")}
      onChange={(_, newValue: Array<string>) => {
        setValue(newValue);
        if (!freeSolo) {
          setInputState("");
        }
      }}
      freeSolo={freeSolo}
      getOptionLabel={(option) => (translate ? t(`labels__${option}`) : option)}
      renderTags={(value, getTagProps) => {
        return (
          <>
            {value.map((item, index) => {
              return (
                <Chip
                  color={!validateItem || validateItem?.(item) ? "default" : "error"}
                  sx={{ mx: 0.5 }}
                  {...getTagProps({ index })}
                  label={translate ? t(`labels__${item}`) : item}
                  size="small"
                  rightIcon={<CloseIcon />}
                  onRightIconClick={() => getTagProps({ index }).onDelete({})}
                />
              );
            })}
          </>
        );
      }}
      renderInput={(params) => (
        <Input
          inputProps={{
            ...params.inputProps,
            value: inputState
          }}
          InputProps={params.InputProps}
          startAdornment={params.InputProps.startAdornment}
          id={params.id}
          value={inputState}
          onChange={onInputChange}
          error={error}
          onKeyDown={(e) => {
            if (e.key === "Enter" && freeSolo) {
              setValue([...value, inputState]);
              setInputState("");
            }
          }}
          onBlur={() => {
            if (inputState && freeSolo) {
              setInputState("");
              setValue([...value, inputState]);
            }
          }}
          variant="outlined"
          label={label}
        />
      )}
    />
  );
};
