import {
  Application,
  AuthProvider,
  CheckKeycloakInstance,
  GlobalModalProvider,
  TenantProvider
} from "@likemagic-tech/sv-magic-library";
import { HelmetProvider } from "react-helmet-async";
import { SettingsProvider } from "./contexts/settings-context";
import { BrowserRouter } from "react-router-dom";
import { FC, PropsWithChildren } from "react";
import { FaviconSetter } from "./components/favicon/favicon-setter";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AppLoaders } from "./features/loaders/app-loaders";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { GraphqlTenantHeadersSetup } from "./graphql/graphql-tenant-headers-setup";
import { InitI18n } from "./config/i18n/core/init-i18n";
import { InjectContextTenantIntoRedux } from "./api/component/inject-context-tenant-into-redux";
import { MetaUpdate } from "./utils/meta-update";
import GlobalStyles from "./components/global-styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { backofficeTheme } from "./config/theme/theme";
import { ParamsToLocalStorage } from "./components/params-to-local-storage/params-to-local-storage";

export const AppProviders: FC<PropsWithChildren<PropsWithChildren>> = ({ children }) => {
  return (
    <TenantProvider application={Application.BACKOFFICE}>
      <AuthProvider>
        <InjectContextTenantIntoRedux>
          <CheckKeycloakInstance>
            <GraphqlTenantHeadersSetup />
            <PrismicProvider client={client}>
              <ThemeProvider theme={backofficeTheme}>
                {/*@ts-ignore*/}
                <ParamsToLocalStorage />
                <HelmetProvider>
                  <FaviconSetter />
                  <MetaUpdate />
                  <CssBaseline />
                  <AppLoaders />
                  <GlobalStyles />
                  <InitI18n>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <SettingsProvider>
                        <GlobalModalProvider>
                          <BrowserRouter>{children}</BrowserRouter>
                        </GlobalModalProvider>
                      </SettingsProvider>
                    </LocalizationProvider>
                  </InitI18n>
                </HelmetProvider>
              </ThemeProvider>
            </PrismicProvider>
          </CheckKeycloakInstance>
        </InjectContextTenantIntoRedux>
      </AuthProvider>
    </TenantProvider>
  );
};
