import { FC } from "react";
import { ReservationDetailsDTO } from "../../../../../../../store/endpoints/reservation-table.endpoints";
import { Grid2, Paper, useTheme } from "@mui/material";
import { formatDate, formatTime, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";
import { useTranslationWrapper } from "../../../../../../../hooks/use-translation-wrapper";
import { useProperty } from "src/hooks/use-property";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ReservationDetailsOverviewRestaurantsProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsOverviewRestaurants: FC<
  ReservationDetailsOverviewRestaurantsProps
> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const theme = useTheme();
  const language = getI18nSelectedLanguage();

  return (
    <>
      <Grid2
        container
        sx={{
          alignItems: "center",
          pb: 0.5,
          pr: 1,
          width: "100%"
        }}
      >
        <Grid2
          size={{ xs: 5 }}
          sx={{
            pl: 1.5
          }}
        >
          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
            {t("labels__reservation__details__overview__restaurant_venue_name")}
          </ParagraphSmall>
        </Grid2>
        <Grid2
          size={{ xs: 2 }}
          sx={{
            textAlign: "center"
          }}
        >
          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
            {t("labels__reservation__details__overview__restaurant_guests")}
          </ParagraphSmall>
        </Grid2>
        <Grid2
          size={{ xs: 5 }}
          sx={{
            textAlign: "right",
            pr: 1.5
          }}
        >
          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
            {t("labels__reservation__details__overview__restaurant_date_time")}
          </ParagraphSmall>
        </Grid2>
      </Grid2>
      <Grid2 container sx={{ width: "100%" }}>
        {reservation.restaurantReservations.map((restaurantReservation) => (
          <Grid2
            size={{ xs: 12 }}
            key={`${restaurantReservation?.foodAndBeveragePropertyId}_${restaurantReservation?.reservationDateTime}`}
            sx={{
              mb: 1
            }}
          >
            <Paper
              elevation={0}
              sx={{
                background: grey[100],
                borderColor: grey[100],
                borderRadius: "5px",
                p: 1.5
              }}
              variant="outlined"
            >
              <Grid2
                container
                sx={{
                  alignItems: "center",
                  pb: 0.5,
                  pr: 1
                }}
              >
                <Grid2 size={{ xs: 5 }}>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {restaurantReservation?.foodAndBeveragePropertyName}
                  </ParagraphSmall>
                </Grid2>
                <Grid2
                  size={{ xs: 2 }}
                  sx={{
                    textAlign: "center"
                  }}
                >
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {restaurantReservation?.actualNumberOfGuests ??
                      restaurantReservation?.expectedNumberOfGuests ??
                      ""}
                  </ParagraphSmall>
                </Grid2>
                <Grid2
                  size={{ xs: 5 }}
                  sx={{
                    textAlign: "right"
                  }}
                >
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {formatDate(
                      restaurantReservation?.reservationDateTime,
                      language,
                      selectedProperty?.details.timeZone
                    )}
                    {" | "}
                    {formatTime(
                      restaurantReservation?.reservationDateTime,
                      language,
                      selectedProperty?.details.timeZone
                    )}
                  </ParagraphSmall>
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};
