import React, { FC, useCallback } from "react";
import { Box, Button, Grid2, Paper } from "@mui/material";
import { PaymentFolioSelection } from "../../../payment/payment-folio-selection";
import { Heading4, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useDispatch, useSelector } from "../../../../../store";
import { selectPaymentDTO, setPriceToPay } from "../../../../../slices/payment.slice";
import { ManualWizardStepItem } from "../../../components/manual-wizard/manual-wizard-step-item";
import { useManualCheckinSteps } from "../../../../../hooks/use-manual-checkin-steps";
import { Price } from "../../../../../domain/price";
import { ManualWizardStepItemNavigation } from "../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { Notification } from "../../../../../components/notification";
import { grey } from "@mui/material/colors";
import { formatPriceToString } from "../../../../../utils/price";
import { CardComments } from "../../../components/card-comments/card-comments";
import { ManualCheckinStepsEnum } from "../../../domain/enums";
import { TotalPricePreview } from "../../../payment/total-price-preview";
import { useProperty } from "../../../../../hooks/use-property";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { getYesNoModalArg } from "../../../../../utils/modal-util";
import { numberOfComments } from "../../../reservation-details/reservation-details-tabs/overview/reservation-details-overview";

export const ManualCheckinStepRateBreakdown: FC<ManualCheckinStepProps> = ({
  reservationDetails
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { nextStep, previousStep, goToStep } = useManualCheckinSteps({
    propertyId: selectedProperty?.propertyId
  });
  const emptyPrice: Price = { currency: selectedProperty?.details?.currencyCode ?? "", amount: 0 };
  const dispatch = useDispatch();
  const { open: openModal } = useGlobalModal();
  const { price: paymentPrice } = useSelector(selectPaymentDTO);
  const setPaymentPrice = useCallback(
    (price: Price) => {
      dispatch(setPriceToPay(price));
    },
    [dispatch]
  );

  const handleNextStep = async () => {
    if (paymentPrice?.amount) {
      nextStep();
    } else {
      const response = await openModal(
        getYesNoModalArg(
          t(`labels__are_you_sure_skip_payment_modal_title`),
          t("labels__are_you_sure_skip_payment_modal_text"),
          t("labels__yes"),
          t("labels__no")
        )
      );
      if (response) {
        goToStep(ManualCheckinStepsEnum.CONFIRMATION);
      }
    }
  };

  const handleNextStepTokenize = async () => {
    dispatch(setPriceToPay(emptyPrice));
    nextStep();
  };

  const notes = reservationDetails?.notes ?? {
    previousVisits: 0,
    guestComment: [],
    bookerComment: [],
    comment: [],
    bookingComment: []
  };

  return (
    <>
      <Heading4 m={3}>{t("subtitle__rate_breakdown")}</Heading4>
      <ManualWizardStepItem>
        <Notification type="info" title={t("labels__rate_breakdown_description")} fullWidth />
        <Box
          sx={{
            width: "100%"
          }}
        >
          {reservationDetails && (
            <PaymentFolioSelection
              setPaymentPrice={setPaymentPrice}
              reservation={reservationDetails}
            />
          )}
          <Box
            sx={{
              mt: 3
            }}
          >
            <TotalPricePreview
              price={paymentPrice?.currency ? paymentPrice : emptyPrice}
              label={t("labels__total_amount")}
            />
          </Box>
        </Box>
      </ManualWizardStepItem>
      {notes && numberOfComments(notes) ? (
        <Paper sx={{ mt: 3, borderRadius: 2 }} elevation={0}>
          <Box
            sx={{
              p: 3
            }}
          >
            <Box
              sx={{
                display: "flex",
                pb: 3
              }}
            >
              <Heading4>{t("labels__reservation__details__overview__comments")}</Heading4>
              <Heading4 ml={0.25} color={grey[600]}>
                <span style={{ fontVariantLigatures: "none" }}>
                  <>&#160;</>
                  <>&#40;</>
                  <span>{numberOfComments(notes)}</span>
                  <>&#41;</>
                </span>
              </Heading4>
            </Box>

            <CardComments notes={notes} numberOfComments={numberOfComments(notes)} />
          </Box>
        </Paper>
      ) : undefined}
      <ManualWizardStepItemNavigation
        onNextStep={handleNextStep}
        onPreviousStep={previousStep}
        nextLabel={
          !paymentPrice?.amount
            ? t("buttons__skip_payment")
            : t("buttons__charge", { price: formatPriceToString(paymentPrice) })
        }
      >
        <Grid2
          sx={{
            minWidth: 140
          }}
        >
          <Button variant="secondary" size="large" fullWidth onClick={handleNextStepTokenize}>
            {t("buttons__tokenize_card")}
          </Button>
        </Grid2>
      </ManualWizardStepItemNavigation>
    </>
  );
};
