import { Box, Grid2 } from "@mui/material";
import React, { FC, useCallback } from "react";
import {
  Button,
  generateSmallUUID,
  Heading4,
  Input,
  NativeSelect,
  Paragraph,
  PhoneInput,
  useCountries,
  useGlobalModal
} from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { getYesNoModalArg } from "../../../../../../utils/modal-util";
import { Guest } from "../../../../../../domain/guest";
import WarningIcon from "@mui/icons-material/Warning";
import { useWizardGenderOptions } from "../../../../../../hooks/use-gender-options";
import AddIcon from "@mui/icons-material/Add";
import { useProperty } from "../../../../../../hooks/use-property";

export const emptyGuest: () => Guest = () => ({
  objectId: generateSmallUUID(),
  firstName: "",
  lastName: "",
  birthdate: "",
  gender: null,
  phone: "",
  email: ""
});

const useStyles = makeStyles()((theme) => ({
  deleteIcon: {
    fontSize: 24,
    fontWeight: "bold"
  },
  travelBuddy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1)
  }
}));

export interface SecondScreenTravelBuddyFormProps {
  push: (value: any) => void;
  remove: (index: number) => void;
  form: any;
  handleBlur: any;
  disabled?: boolean;
}

export const ManualCheckinTravelBuddyForm: FC<SecondScreenTravelBuddyFormProps> = ({
  push,
  form,
  remove,
  handleBlur,
  disabled
}) => {
  const { open: openModal } = useGlobalModal();
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const { selectedProperty } = useProperty();
  const { genderOptions } = useWizardGenderOptions();
  const { preferredCountries } = useCountries({ propertyId: selectedProperty?.propertyId ?? "" });

  const addTravelBuddy = useCallback(() => push(emptyGuest()), [push]);
  const deleteTravelBuddy = useCallback(
    (buddyIndexToBeDeleted: number) => async () => {
      const modalResult: boolean | null = await openModal(
        getYesNoModalArg(
          t("modals__wizard_delete_buddy_dialog_title"),
          t("modals__wizard_delete_buddy_dialog_text"),
          t("buttons__wizard_yes"),
          t("buttons__wizard_no")
        )
      );
      if (modalResult) {
        remove(buddyIndexToBeDeleted);
      }
    },
    [openModal, t, remove]
  );

  return (
    <>
      {form.values.travelBuddies.map((guest: Guest, index: number) => {
        return (
          <Box key={`guest-${index}`}>
            <Box
              sx={{
                mt: 6.25,
                mb: 4
              }}
            >
              <Grid2 container direction="row">
                <Grid2 size={{ xs: 6 }} container className={classes.travelBuddy}>
                  <Heading4>{`${t("labels__wizard_travel_buddy")} ${index + 1}`}</Heading4>
                </Grid2>
                <Grid2
                  size={{ xs: 6 }}
                  container
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  <Button
                    variant="ghost"
                    onClick={deleteTravelBuddy(index)}
                    startIcon={<CloseIcon />}
                    disabled={disabled}
                  >
                    <Paragraph>{t("buttons__wizard_remove")}</Paragraph>
                  </Button>
                </Grid2>
              </Grid2>
            </Box>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 4, md: 2 }}>
                <NativeSelect
                  items={genderOptions}
                  id={`travelBuddies.[${index}].gender`}
                  name={`travelBuddies.[${index}].gender`}
                  value={form.values.travelBuddies[index].gender || ""}
                  label={t("labels__wizard_gender")}
                  onChange={form.handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  disabled={disabled}
                />
              </Grid2>
              <Grid2 size={{ xs: 8, md: 4 }}>
                <Input
                  id={`travelBuddies.[${index}].firstName`}
                  name={`travelBuddies.[${index}].firstName`}
                  value={form.values.travelBuddies[index]?.firstName || ""}
                  label={t("labels__wizard_first_name")}
                  error={
                    form.touched?.travelBuddies?.length &&
                    form.touched?.travelBuddies[index]?.firstName &&
                    form.errors?.travelBuddies?.length &&
                    form.errors?.travelBuddies[index]?.firstName
                  }
                  onChange={form.handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  autoComplete="nope"
                  disabled={disabled}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Input
                  id={`travelBuddies.[${index}].lastName`}
                  name={`travelBuddies.[${index}].lastName`}
                  value={form.values.travelBuddies[index].lastName || ""}
                  label={t("labels__wizard_last_name")}
                  error={
                    form.touched?.travelBuddies?.length &&
                    form.touched?.travelBuddies[index]?.lastName &&
                    form.errors?.travelBuddies?.length &&
                    form.errors?.travelBuddies[index]?.lastName
                  }
                  onChange={form.handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  autoComplete="nope"
                  disabled={disabled}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Input
                  id={`travelBuddies.[${index}].email`}
                  name={`travelBuddies.[${index}].email`}
                  value={form.values.travelBuddies[index].email || ""}
                  type="email"
                  label={t("labels__wizard_email")}
                  error={
                    form.touched?.travelBuddies?.length &&
                    form.touched?.travelBuddies[index]?.email &&
                    form.errors?.travelBuddies?.length &&
                    form.errors?.travelBuddies[index]?.email
                  }
                  onChange={form.handleChange}
                  onBlur={handleBlur}
                  variant="standard"
                  autoComplete="nope"
                  disabled={disabled}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <PhoneInput
                  id={`travelBuddies.[${index}].phone`}
                  name={`travelBuddies.[${index}].phone`}
                  value={form.values.travelBuddies[index]?.phone || ""}
                  label={t("labels__wizard_phone")}
                  autoComplete="nope"
                  onChange={(newValue: string) =>
                    form.setFieldValue(`travelBuddies.[${index}].phone`, newValue)
                  }
                  variant="standard"
                  error={
                    !!(
                      form.touched?.travelBuddies?.length &&
                      form.touched?.travelBuddies[index]?.phone &&
                      form.errors?.travelBuddies?.length &&
                      form.errors?.travelBuddies[index]?.phone
                    )
                  }
                  fullWidth
                  helperText={
                    form.errors?.travelBuddies?.length && form.errors?.travelBuddies[index]?.phone
                  }
                  placeholder={t("labels__wizard_phone")}
                  InputProps={{
                    endAdornment: form.touched?.travelBuddies?.length &&
                      form.touched?.travelBuddies[index]?.phone &&
                      form.errors?.travelBuddies?.length &&
                      form.errors?.travelBuddies[index]?.phone && <WarningIcon color="error" />
                  }}
                  disabled={disabled}
                  preferredCountries={preferredCountries}
                />
              </Grid2>
            </Grid2>
          </Box>
        );
      })}
      {form.values.maxCompanions > form.values.travelBuddies.length && (
        <Box
          sx={{
            mt: 4.25
          }}
        >
          <Button
            variant="ghost"
            onClick={addTravelBuddy}
            startIcon={<AddIcon />}
            disabled={disabled}
          >
            {t("buttons__wizard_add_buddy")}
          </Button>
        </Box>
      )}
    </>
  );
};
