import { ParagraphSmall, ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { Box, Grid2, Theme, Tooltip, useTheme } from "@mui/material";
import { FC, RefObject, useCallback, useRef } from "react";
import { Notes } from "src/domain/reservation-table-dto";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { makeStyles } from "tss-react/mui";

interface NotesPreviewProps {
  notesList: Notes;
  isRecurring: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    "& > *:not(:last-child)": {
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.text.secondary}`
    }
  }
}));

export const NotesPreview: FC<NotesPreviewProps> = ({ notesList, isRecurring }) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const { palette } = useTheme();
  const rateBreakdownCommentRef = useRef<HTMLDivElement>(null);
  const beddingCommentRef = useRef<HTMLDivElement>(null);

  const previousVisitsLabel = useCallback(
    (value: number, isRecurring: boolean) => {
      if (value > 0) {
        return t("labels__note_previousVisits") + ": " + value;
      } else if (isRecurring) {
        return t("labels__reservation__details__overview__guests__recurring");
      } else {
        return t("labels__note_newGuest");
      }
    },
    [t]
  );

  const paragraphList = (list: Array<string>) => {
    return list.map((item: string) => (
      <ParagraphSmall color="common.white" key={`paragraph-list-${item}`}>
        {item}
      </ParagraphSmall>
    ));
  };

  const notesForTooltip = useCallback(
    (notes: Notes) => {
      return (
        <Grid2
          container
          className={classes.wrapper}
          sx={{
            gap: 1,
            p: 1
          }}
        >
          <Grid2
            container
            direction="column"
            sx={{
              gap: 1,
              width: "100%"
            }}
          >
            <ParagraphSmallBold color="common.white">
              {previousVisitsLabel(notes.previousVisits, isRecurring)}
            </ParagraphSmallBold>
          </Grid2>
          {notesList.guestComment.length > 0 && (
            <Grid2
              container
              direction="column"
              sx={{
                gap: 1,
                width: "100%"
              }}
            >
              <ParagraphSmallBold color="common.white">
                {t("labels__notes__guest__comments")}
              </ParagraphSmallBold>
              {paragraphList(notesList?.guestComment)}
            </Grid2>
          )}
          {notesList.rateBreakdownComment && notesList.rateBreakdownComment.length > 0 && (
            <Grid2
              container
              direction="column"
              sx={{
                gap: 1,
                width: "100%"
              }}
            >
              <ParagraphSmallBold color="common.white">
                {t("labels__notes__rate_break_down__comment")}
              </ParagraphSmallBold>
              {paragraphList(notesList?.rateBreakdownComment)}
            </Grid2>
          )}
          {notesList.beddingComment && notesList.beddingComment.length > 0 && (
            <Grid2
              container
              direction="column"
              sx={{
                gap: 1,
                width: "100%"
              }}
            >
              <ParagraphSmallBold color="common.white">
                {t("labels__notes__bedding__comment")}
              </ParagraphSmallBold>
              {paragraphList(notesList?.beddingComment)}
            </Grid2>
          )}
          {notesList.bookerComment.length > 0 && (
            <Grid2
              container
              direction="column"
              sx={{
                gap: 1,
                width: "100%"
              }}
            >
              <ParagraphSmallBold color="common.white">
                {t("labels__notes__booker__comment")}
              </ParagraphSmallBold>
              {paragraphList(notesList?.bookerComment)}
            </Grid2>
          )}
          {notesList.comment.length > 0 && (
            <Grid2
              container
              direction="column"
              sx={{
                gap: 1,
                width: "100%"
              }}
            >
              <ParagraphSmallBold color="common.white">
                {t("labels__notes__reservation__comment")}
              </ParagraphSmallBold>
              {paragraphList(notesList?.comment)}
            </Grid2>
          )}
          {notesList.bookingComment.length > 0 && (
            <Grid2
              container
              direction="column"
              sx={{
                gap: 1,
                width: "100%"
              }}
            >
              <ParagraphSmallBold color="common.white">
                {t("labels__notes__booking__comment")}
              </ParagraphSmallBold>
              {paragraphList(notesList?.bookingComment)}
            </Grid2>
          )}
        </Grid2>
      );
    },
    [classes.wrapper, notesList, previousVisitsLabel, t, isRecurring]
  );

  const checkEllipsis = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current && ref.current.firstChild instanceof HTMLElement) {
      const firstChild = ref.current.firstChild as HTMLElement;
      return firstChild.scrollWidth > firstChild.clientWidth;
    }
    return false;
  };

  return (
    <>
      <ParagraphSmall color={palette.text.secondary}>
        {previousVisitsLabel(notesList.previousVisits, isRecurring)}
      </ParagraphSmall>

      <Box sx={{ display: "flex", gap: 0.4 }}>
        {notesList.rateBreakdownComment && notesList.rateBreakdownComment?.length > 0 && (
          <div ref={rateBreakdownCommentRef}>
            <ParagraphSmall
              color={palette.text.secondary}
              sx={{
                maxWidth: 100,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {notesList?.rateBreakdownComment}
            </ParagraphSmall>
          </div>
        )}
        <ParagraphSmall color={palette.text.secondary}>
          {notesList.rateBreakdownComment &&
            notesList.rateBreakdownComment?.length > 0 &&
            notesList.beddingComment &&
            notesList.beddingComment?.length > 0 &&
            " / "}
        </ParagraphSmall>
        {notesList.beddingComment && notesList.beddingComment?.length > 0 && (
          <div ref={beddingCommentRef}>
            <ParagraphSmall
              color={palette.text.secondary}
              sx={{
                maxWidth: 100,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}
            >
              {notesList?.beddingComment}
            </ParagraphSmall>
          </div>
        )}
      </Box>

      {(notesList.guestComment.length > 0 ||
        (notesList.rateBreakdownComment &&
          notesList.rateBreakdownComment.length > 0 &&
          checkEllipsis(rateBreakdownCommentRef)) ||
        (notesList.beddingComment &&
          notesList.beddingComment.length > 0 &&
          checkEllipsis(beddingCommentRef)) ||
        notesList.bookerComment.length > 0 ||
        notesList.comment.length > 0 ||
        notesList.bookingComment.length > 0) && (
        <Tooltip title={notesForTooltip(notesList)} onClick={(e) => e.stopPropagation()}>
          <Box>
            <ParagraphSmallBold color={palette.accent.main} sx={{ textDecoration: "underline" }}>
              {t("labels__see_more")}
            </ParagraphSmallBold>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default NotesPreview;
