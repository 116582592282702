import { FieldWithIcon } from "./field-with-icon";
import { ScheduleOutlined } from "@mui/icons-material";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import React, { FC, useRef } from "react";
import { Box } from "@mui/material";
import { useProperty } from "../../../../hooks/use-property";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import DateTimePicker from "../../../../components/maintenance-modal/date-time-picker/date-time-picker";
import { utcToZonedTime } from "date-fns-tz";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

interface DueDateProps {
  dueDate?: string;
  dueTime?: string;
  onChangeDateHandler: (date: Date | null) => void;
  onChangeTimeHandler: (date: Date | null) => void;
}

export const DueDate: FC<DueDateProps> = ({
  dueTime,
  dueDate,
  onChangeDateHandler,
  onChangeTimeHandler
}) => {
  const { t } = useTranslationWrapper();
  const anchorRef = useRef<any>(null);
  const { selectedProperty } = useProperty();
  const theme = useTheme();

  return (
    <FieldWithIcon icon={<ScheduleOutlined />}>
      <Box sx={{ display: "flex", gap: 1, ml: 2, alignItems: "center" }} ref={anchorRef}>
        <ParagraphSmall sx={{ width: "auto", textWrap: "nowrap", mr: 1 }}>
          {t("labels__task_due_date")}
        </ParagraphSmall>
        <DateTimePicker
          time={
            dueTime ? utcToZonedTime(dueTime, selectedProperty?.details.timeZone ?? "") : undefined
          }
          date={
            dueDate ? utcToZonedTime(dueDate, selectedProperty?.details.timeZone ?? "") : undefined
          }
          minDate={utcToZonedTime(new Date(), selectedProperty?.details.timeZone ?? "")}
          setDate={(date: Date) => onChangeDateHandler(date)}
          setTime={(value: Date) => onChangeTimeHandler(value)}
          variant="standard"
          hideLabel
          inputFontSize={14}
        />
        {(dueTime || dueDate) && (
          <CloseIcon
            sx={{ color: theme.palette.text.secondary, cursor: "pointer" }}
            onClick={() => {
              onChangeDateHandler(null);
              onChangeTimeHandler(null);
            }}
          />
        )}
      </Box>
    </FieldWithIcon>
  );
};
