import { Button, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { FC, useCallback, useMemo, useState } from "react";
import { Grid2, useTheme } from "@mui/material";
import { Check, WarningAmberOutlined } from "@mui/icons-material";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { openBanner } from "../../../slices/banner.slice";
import { useDispatch } from "../../../store";
import { TaskType } from "../../../graphql-tasks/generated/graphql";
import { HousekeepingCardType } from "./housekeeping-card";
import { HousekeepingTaskType, TaskStatus } from "../../../features/tasks/domain/task";
import { HousekeepingModal } from "../housekeeping-modal/housekeeping-modal";

interface HousekeepingCardActionProps {
  tasks: Array<HousekeepingTaskType>;
  housekeepingCard: HousekeepingCardType;
  housekeepingTask?: HousekeepingTaskType;
  handleSubmit: (values: HousekeepingTaskType) => Promise<void>;
  futureDate: boolean;
}

export const HousekeepingCardAction: FC<HousekeepingCardActionProps> = ({
  housekeepingCard,
  tasks,
  housekeepingTask,
  handleSubmit,
  futureDate
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { palette, spacing } = useTheme();
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();

  const handleOnMarkAsDone = useCallback(() => {
    housekeepingTask &&
      handleSubmit({
        ...housekeepingTask,
        status: TaskStatus.Done
      }).then(() => {
        dispatch(
          openBanner({
            type: "success",
            title: t("labels__room_marked_as_clean", {
              roomNumber: housekeepingCard?.unit?.name ?? ""
            })
          })
        );
      });
  }, [housekeepingTask, handleSubmit, dispatch, housekeepingCard, t]);

  const disabledCleaningLabel = useMemo(() => {
    if (housekeepingCard?.unit?.occupied && housekeepingTask?.type === TaskType.DepartureCleaning) {
      return t("labels__guest_still_in_room");
    }
  }, [housekeepingCard, t, housekeepingTask]);

  const unfinishedTasks = useMemo(() => {
    return tasks?.filter((task) => task.status !== TaskStatus.Done);
  }, [tasks]);

  return (
    <>
      <Grid2
        container
        sx={{
          gap: 1
        }}
      >
        <Grid2
          container
          sx={{
            width: "100%"
          }}
        >
          <Button
            color="success"
            fullWidth
            onClick={() => {
              unfinishedTasks.length ? setIsModalOpen(true) : handleOnMarkAsDone();
            }}
            disabled={
              (housekeepingCard?.unit?.occupied &&
                housekeepingTask?.type === TaskType.DepartureCleaning) ||
              !housekeepingCard?.unit ||
              futureDate
            }
            startIcon={<Check />}
          >
            {t("buttons__mark_as_done")}
          </Button>
        </Grid2>
        {!!disabledCleaningLabel && !futureDate && (
          <Grid2
            container
            sx={{
              justifyContent: "center",
              alignItems: "center",
              gap: spacing(1)
            }}
          >
            <WarningAmberOutlined color="error" />
            <ParagraphSmall color={palette.error.main}>{disabledCleaningLabel}</ParagraphSmall>
          </Grid2>
        )}
      </Grid2>
      {isModalOpen && !!tasks.length && (
        <HousekeepingModal
          housekeepingCard={housekeepingCard}
          handleUpdateHousekeepingCard={handleSubmit}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleOnMarkAsDone={handleOnMarkAsDone}
        />
      )}
    </>
  );
};
