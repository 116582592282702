import { Box, Collapse, Grid2, IconButton, useTheme } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, Checkbox } from "@likemagic-tech/sv-magic-library";
import { HousekeepingTaskType, TaskStatus } from "../../features/tasks/domain/task";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import DOMPurify from "dompurify";
import { Form, Formik } from "formik";
import ReactQuill from "react-quill";

const editDescriptionModuleOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }]
];

const useStyles = makeStyles()((theme) => ({
  card: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 0px 0px 1px #E0E0E0"
  },
  icon: {
    padding: theme.spacing(1)
  },
  textArea: {
    "& .ql-toolbar": {
      border: `1px solid ${theme.palette.text.secondary} !important`,
      borderBottom: "none !important",
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0 !important`
    },
    "& .ql-container": {
      border: `1px solid ${theme.palette.text.secondary} !important`,
      borderTop: "none !important",
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px !important`
    },
    "& .ql-container > .ql-editor": {
      padding: theme.spacing(2.5)
    }
  }
}));

interface HousekeepingCardTaskProps {
  task: HousekeepingTaskType;
  handleUpdateHousekeepingCard: (values: HousekeepingTaskType) => Promise<void>;
  enableEditTaskDescription?: boolean;
  futureDate?: boolean;
}

export const HousekeepingTaskComponent: FC<HousekeepingCardTaskProps> = ({
  task,
  handleUpdateHousekeepingCard,
  enableEditTaskDescription = false,
  futureDate = false
}) => {
  const { classes } = useStyles();
  const [editTaskDescription, setEditTaskDescription] = useState<boolean>(false);
  const { spacing } = useTheme();
  const { t } = useTranslationWrapper();

  const sanitizedHtmlContent = useMemo(() => {
    return DOMPurify.sanitize(task.description ?? "");
  }, [task]);

  const handleUpdateTask = useCallback(
    (values: { description: string; checked: boolean }) => {
      handleUpdateHousekeepingCard({
        ...task,
        description: values.description,
        status: values.checked ? TaskStatus.Done : TaskStatus.Todo
      }).then(() => {
        setEditTaskDescription(false);
      });
    },
    [task, handleUpdateHousekeepingCard]
  );

  return (
    <Formik
      initialValues={{
        description: task.description ?? "",
        checked: task.status === TaskStatus.Done
      }}
      onSubmit={handleUpdateTask}
    >
      {(formik) => (
        <Form noValidate style={{ width: "100%" }}>
          <Grid2
            container
            sx={{
              justifyContent: "space-between",
              gap: spacing(1)
            }}
          >
            <Grid2>
              <Checkbox
                id={task.id.toString()}
                title={task.title}
                text={editTaskDescription ? "" : sanitizedHtmlContent}
                textIsHtml={true}
                disabled={futureDate}
                checked={task.status === TaskStatus.Done}
                size="small"
                onChange={(e) => {
                  switch (e.target.checked) {
                    case true:
                      handleUpdateTask({
                        description: formik.values.description,
                        checked: true
                      });
                      break;
                    case false:
                      handleUpdateTask({
                        description: formik.values.description,
                        checked: false
                      });
                      break;
                    default:
                      break;
                  }
                }}
              />
            </Grid2>
            <Grid2
              size={{ xs: 2 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              {enableEditTaskDescription && !editTaskDescription && (
                <IconButton
                  onClick={() => {
                    setEditTaskDescription(!editTaskDescription);
                  }}
                  disabled={task.status === TaskStatus.Done}
                  size="small"
                >
                  <EditOutlined />
                </IconButton>
              )}
              {editTaskDescription && (
                <Box
                  sx={{
                    display: "flex"
                  }}
                >
                  <Button
                    size="small"
                    variant="ghost"
                    onClick={() => {
                      setEditTaskDescription(false);
                    }}
                  >
                    {t("buttons__cancel")}
                  </Button>
                  <Button size="small" color="accent" variant="ghost" type="submit">
                    {t("buttons__save")}
                  </Button>
                </Box>
              )}
            </Grid2>
            <Collapse in={editTaskDescription} sx={{ width: "100%" }}>
              <Grid2
                container
                sx={{
                  gap: spacing(1)
                }}
              >
                <ReactQuill
                  id="description"
                  className={classes.textArea}
                  value={formik.values.description}
                  onChange={(value) => formik.setFieldValue("description", value)}
                  theme={"snow"}
                  modules={{
                    toolbar: editDescriptionModuleOptions
                  }}
                  style={{ width: "100%", height: "100%", overflow: "auto" }}
                />
              </Grid2>
            </Collapse>
          </Grid2>
        </Form>
      )}
    </Formik>
  );
};
