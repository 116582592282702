import { TenantContext, useApiVersion } from "@likemagic-tech/sv-magic-library";
import saveAs from "file-saver";
import { useCallback, useContext } from "react";
import { downloadInvoice } from "src/slices/download.slice";
import { useDispatch } from "src/store";

interface DownloadInvoiceProps {
  pmsPropertyId: string;
  pmsFolioId: string;
  pmsReservationId: string;
  isClosed: boolean;
}

export const useDownloadInvoice = () => {
  const { keycloak } = useContext(TenantContext);
  const { isRESTVersion } = useApiVersion();
  const dispatch = useDispatch();

  const downloadInvoiceV1 = () => {
    return Promise.reject("not yet implemented");
  };

  const downloadInvoiceV2 = useCallback(
    async ({ pmsPropertyId, pmsFolioId, pmsReservationId, isClosed }: DownloadInvoiceProps) => {
      const blob = await dispatch(
        downloadInvoice({
          pmsPropertyId,
          pmsFolioId,
          pmsReservationId,
          isClosed,
          authToken: keycloak.token
        })
      ).unwrap();

      const file = new Blob([blob], {
        type: "application/octet-stream",
        endings: "native"
      });
      saveAs(file, `download-invoice-folio-${pmsFolioId}.pdf`);
    },
    [keycloak, dispatch]
  );

  const functionCall = isRESTVersion ? downloadInvoiceV1 : downloadInvoiceV2;

  return [functionCall] as [typeof downloadInvoiceV1] | [typeof downloadInvoiceV2];
};
