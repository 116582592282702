import { Grid2, Skeleton } from "@mui/material";
import React, { FC } from "react";
import { AlwaysScrollToBottom } from "../../components/always-scroll-to-bottom/always-scroll-to-bottom";
import { useIsMobile } from "../../hooks/use-is-mobile";

interface ChatItemPlaceholderProps {
  conversationId: string | undefined;
}
export const ChatItemPlaceholder: FC<ChatItemPlaceholderProps> = ({ conversationId }) => {
  const isMobile = useIsMobile();

  return (
    <Grid2
      container
      direction={"row-reverse"}
      sx={{
        pt: 1
      }}
    >
      {!isMobile && (
        <Grid2
          sx={{
            display: "flex",
            justifyContent: "end",
            pl: 1
          }}
        >
          <Skeleton
            variant="circular"
            sx={{
              height: 40,
              width: 40,
              p: 2
            }}
          />
        </Grid2>
      )}
      <Grid2 size={{ md: 7, lg: 9, xl: 7, xs: 11 }}>
        <Skeleton variant="rectangular" height={150} sx={{ borderRadius: 1 }}></Skeleton>
      </Grid2>
      <Grid2
        size={{ xs: 11 }}
        sx={{
          pt: 1
        }}
      >
        <Grid2
          container
          sx={{
            alignItems: "center",
            justifyContent: "end"
          }}
        >
          <Skeleton variant={"text"} width={270} height={35} />
          {!isMobile && <Grid2 size={{ xs: 11 }}></Grid2>}
        </Grid2>
      </Grid2>
      <AlwaysScrollToBottom id={conversationId ?? ""} />
    </Grid2>
  );
};
