import React from "react";
import { Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { AvatarProps, AvatarTypeMap } from "@mui/material/Avatar/Avatar";
import { grey } from "@mui/material/colors";
import { Paragraph } from "@likemagic-tech/sv-magic-library";

type UserAvatarTypeMap = AvatarTypeMap<{ lettersToDisplay?: string }>;

export const UserAvatar: OverridableComponent<UserAvatarTypeMap> = (
  props: AvatarProps & { lettersToDisplay?: string }
) => {
  const { lettersToDisplay, ...avatarProps } = props;
  if (lettersToDisplay) {
    return (
      <Avatar
        {...avatarProps}
        sx={{ border: `1px solid ${grey["400"]}`, background: grey["200"], color: grey["600"] }}
      >
        <Paragraph sx={{ color: grey["600"] }}>{lettersToDisplay}</Paragraph>
      </Avatar>
    );
  }
  return (
    <Avatar
      {...avatarProps}
      sx={{ border: `1px solid ${grey["400"]}`, background: grey["200"], color: grey["600"] }}
    >
      <PersonIcon />
    </Avatar>
  );
};
