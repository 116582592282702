import React, { FC } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { ConversationDetails } from "../domain/conversation";
import { ChatPreviewReservations } from "./chat-preview-reservations";
import { ChatPreviewTasks } from "./chat-preview-tasks";
import { useReservationDetailsId } from "../../features/reservations/reservation-details/reservation-details/use-reservation-details-id";
import { Task } from "src/features/tasks/domain/task";
import { ChatPreviewHeader } from "./chat-preview-header";

interface ChatPreviewProps {
  conversation: ConversationDetails;
  tasks: Task[];
}

export const ChatPreview: FC<ChatPreviewProps> = ({ conversation, tasks }) => {
  const { palette } = useTheme();
  const { setSelectId } = useReservationDetailsId();
  const onRowSelect = (reservationId: string) => {
    setSelectId(reservationId);
  };

  return (
    <Box
      sx={{
        px: 1.5,
        py: 2,
        backgroundColor: palette.common.white,
        height: "100%",
        overflowY: "scroll"
      }}
    >
      <ChatPreviewHeader conversation={conversation} />
      <Divider sx={{ mt: 2.5, width: "100%", color: palette.grey[300] }} />

      {conversation.guestIdentity?.reservations?.length ? (
        <>
          <Box mt={2.5}>
            <ChatPreviewReservations
              reservations={conversation.guestIdentity?.reservations ?? []}
              onSelect={onRowSelect}
            />
          </Box>
          <Divider sx={{ mt: 2.5, width: "100%", color: palette.grey[300] }} />
        </>
      ) : null}

      <Box mt={2.5}>
        <ChatPreviewTasks
          primaryReservationId={
            conversation?.guestIdentity?.reservations
              ? conversation?.guestIdentity?.reservations[0]?.pmsId ?? ""
              : ""
          }
          tasks={tasks}
        />
      </Box>
    </Box>
  );
};
