import {
  GetPaymentMethodsQuery,
  useGetPaymentMethodsQuery
} from "../../../../graphql/queries/GetPaymentMethods.generated";
import { PaymentOption } from "../domain/payment-option";
import { useCallback, useMemo } from "react";
import { PaymentMethodType } from "../../../../graphql/generated/graphql";

export interface PaymentMethodOption {
  hashCode: string;
  type: PaymentOption;
}

type PaymentMethodDataType = Exclude<GetPaymentMethodsQuery["GetPaymentMethods"], null | undefined>;
export type PaymentMethodDataItemType = Exclude<PaymentMethodDataType[number], null | undefined>;

export const usePaymentMethodOptions = ({
  propertyId,
  magicId,
  skip
}: {
  propertyId?: string;
  magicId?: string;
  skip?: boolean;
}) => {
  const { data } = useGetPaymentMethodsQuery(
    {
      pmsPropertyId: propertyId ?? "",
      shopperReference: magicId ?? ""
    },
    { skip: !propertyId || skip }
  );

  const options = useMemo(
    () =>
      (data?.GetPaymentMethods?.filter((item) => item?.type) as Array<PaymentMethodDataItemType>) ??
      [],
    [data?.GetPaymentMethods]
  );

  const getPaymentOptionDetails = useCallback(
    (id?: string) => data?.GetPaymentMethods?.find((item) => item?.hashCode === id),
    [data?.GetPaymentMethods]
  );

  const defaultSelectedHashId = useMemo(() => {
    const terminalPaymentData = options.find(
      (item) => item.type === PaymentMethodType.TerminalPayment
    );

    return terminalPaymentData?.hashCode;
  }, [options]);

  return { options, getPaymentOptionDetails, defaultSelectedHashId };
};
