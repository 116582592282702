import React, { FC, useCallback, useMemo } from "react";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { Box, Grid2, useTheme } from "@mui/material";
import { FieldArray, Form, useFormikContext } from "formik";
import {
  Heading4,
  ParagraphSmallBold,
  useGlobalModal,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { ManualCheckinMainGuestForm, ManualCheckinTravelBuddyForm } from "./../components";
import { makeStyles } from "tss-react/mui";
import { useManualCheckinSteps } from "../../../../../../hooks/use-manual-checkin-steps";
import { ManualWizardStepItem } from "../../../../components/manual-wizard/manual-wizard-step-item";
import {
  FrequentFlyerProgram,
  RegistrationCardFormDataType
} from "../../../../../../domain/registration-card";
import { useManualCheckinId } from "../../../use-manual-checkin-id";
import { transformRegistrationCardData } from "../../../../../../graphql/transform/transform-registration-card";
import { useUpdateRegistrationCardGuestsMutationEnhanced } from "../../../../../../graphql/mutations/enhanced-mutations/update-registration-card-guests-enhanced";
import { ManualWizardStepItemNavigation } from "../../../../components/manual-wizard/manual-wizard-step-item-navigation";
import { openBanner } from "../../../../../../slices/banner.slice";
import { useDispatch } from "../../../../../../store";
import { getYesNoModalArg } from "../../../../../../utils/modal-util";
import { useProperty } from "../../../../../../hooks/use-property";
import { ManualCheckinStepsEnum } from "../../../../domain/enums";
import { useBackofficeAddMembershipToProfileMutation } from "../../../../../../graphql/mutations/backoffice-add-membership-to-profile.generated";
import { MemberShipClassEnum } from "../../../../../../graphql/generated/graphql";
import { useUpsellUnitGroups } from "../../../../reservation-details/reservation-details-tabs/upsell/use-upsell-unit-groups";
import { CheckboxIcon } from "src/icons/CheckboxIcon";
import { ReservationDetailsDTO } from "../../../../../../store/endpoints/reservation-table.endpoints";

const useStyles = makeStyles()((theme) => ({
  mainGuest: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    height: 24
  }
}));

interface ManualCheckinStepRegistrationCardFormProps {
  isConfirmedIndicatorShown: boolean;
  setIsConfirmedIndicatorShown: (value: boolean) => void;
  disabled: boolean;
  reservation?: ReservationDetailsDTO;
}

export const ManualCheckinStepRegistrationCardForm: FC<
  ManualCheckinStepRegistrationCardFormProps
> = ({ isConfirmedIndicatorShown, disabled, setIsConfirmedIndicatorShown, reservation }) => {
  const { t } = useTranslationWrapper();
  const { classes } = useStyles();
  const { errors, handleBlur, values, dirty, handleSubmit } =
    useFormikContext<RegistrationCardFormDataType>();
  const { selectedProperty } = useProperty();
  const { nextStep } = useManualCheckinSteps({ propertyId: selectedProperty?.propertyId });
  const { manualCheckinId } = useManualCheckinId();
  const [updateRegistrationCardGuest] = useUpdateRegistrationCardGuestsMutationEnhanced();
  const dispatch = useDispatch();
  const { open: openModal } = useGlobalModal();
  const theme = useTheme();
  const { items: upsellIOffers } = useUpsellUnitGroups({
    reservationId: reservation?.id,
    reservationStatus: reservation?.reservationStatus
  });
  const skipPages = useMemo(
    () => (upsellIOffers.length ? undefined : ManualCheckinStepsEnum.UPSELL_UNIT_GROUP),
    [upsellIOffers.length]
  );
  const [addMembershipToProfile] = useBackofficeAddMembershipToProfileMutation();
  const { features } = usePropertyConfig({
    propertyId: reservation?.propertyId
  });

  const handleFrequentFlyerPrograms = useCallback(
    async (frequentFlyerPrograms: FrequentFlyerProgram[]) => {
      const newFrequentFlyerProgramsNotEmpty = frequentFlyerPrograms.filter(
        (program: any) =>
          !program.existing && program.frequentFlyerNumber && program.frequentFlyerProgram
      );

      await Promise.all(
        newFrequentFlyerProgramsNotEmpty.map((program) =>
          addMembershipToProfile({
            membershipAccount: {
              membershipId: program.frequentFlyerNumber,
              membershipTypeCode: program.frequentFlyerProgram,
              membershipClass: MemberShipClassEnum.FrequentFlyer
            },
            pmsReservationId: values.reservationId,
            pmsProfileId: values.mainGuest.id
          })
        )
      );
    },
    [addMembershipToProfile, values.mainGuest.id, values.reservationId]
  );

  const handleNextStep = useCallback(async () => {
    handleSubmit();
    if (Object.keys(errors).length === 0) {
      const result =
        features?.secondScreenEnabled &&
        !isConfirmedIndicatorShown &&
        (await openModal(
          getYesNoModalArg(
            t("labels__are_you_sure_you_want_to_skip"),
            t("labels__registration_card_not_confirmed"),
            t("buttons__go_to_next_step"),
            t("buttons__cancel")
          )
        ));

      if (result || !features?.secondScreenEnabled || isConfirmedIndicatorShown) {
        if (dirty) {
          await updateRegistrationCardGuest(
            transformRegistrationCardData(values, manualCheckinId ?? "")
          );
          await handleFrequentFlyerPrograms(values.mainGuest.frequentFlyerPrograms);
        }
        nextStep(skipPages);
      }
    }
  }, [
    handleSubmit,
    openModal,
    errors,
    dirty,
    nextStep,
    t,
    manualCheckinId,
    isConfirmedIndicatorShown,
    handleFrequentFlyerPrograms,
    updateRegistrationCardGuest,
    values,
    skipPages,
    features?.secondScreenEnabled
  ]);

  return (
    <>
      <ManualWizardStepItem>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <Form noValidate>
            <Grid2 container className={classes.mainGuest}>
              <Heading4>{t("labels__wizard_main_guest")}</Heading4>
              {isConfirmedIndicatorShown && (
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: theme.palette.success.light,
                    color: theme.palette.success.dark,
                    alignItems: "center",
                    gap: 0.5,
                    px: 1.25,
                    height: "fit-content",
                    borderRadius: theme.spacing(0.5)
                  }}
                >
                  <CheckboxIcon />
                  <ParagraphSmallBold
                    sx={{
                      color: theme.palette.success.dark
                    }}
                  >
                    {t(`labels__data_confirmed`)}
                  </ParagraphSmallBold>
                </Box>
              )}
            </Grid2>
            <ManualCheckinMainGuestForm disabled={disabled} />

            <FieldArray
              name="travelBuddies"
              render={(props) => (
                <ManualCheckinTravelBuddyForm
                  push={props.push}
                  remove={props.remove}
                  form={props.form}
                  handleBlur={handleBlur}
                  disabled={disabled}
                />
              )}
            />
          </Form>
        </Box>
      </ManualWizardStepItem>
      <ManualWizardStepItemNavigation
        onNextStep={handleNextStep}
        onPreviousStep={async () => {
          if (dirty) {
            handleSubmit();
            if (Object.keys(errors).length === 0) {
              setIsConfirmedIndicatorShown(false);
              await updateRegistrationCardGuest(
                transformRegistrationCardData(values, manualCheckinId ?? "")
              ).unwrap();
              await handleFrequentFlyerPrograms(values.mainGuest.frequentFlyerPrograms);

              dispatch(
                openBanner({
                  type: "success",
                  title: t("labels__saved_successfully")
                })
              );
            }
          }
        }}
        backLabel={t("buttons__save")}
        disableNext={disabled}
        disablePrevious={disabled}
      />
    </>
  );
};
