import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useSettleFolioMutation } from "src/graphql/mutations/settle-folio.generated";

interface SettleFolioProps {
  pmsPropertyId: string;
  pmsReservationId: string;
  folioMetadata: any;
}

export const useSettleFolio = () => {
  const { isRESTVersion } = useApiVersion();
  const [performAction] = useSettleFolioMutation();

  const settleFolioV1 = () => {
    return Promise.reject("not yet implemented");
  };

  const settleFolioV2 = ({ pmsPropertyId, pmsReservationId, folioMetadata }: SettleFolioProps) => {
    return performAction({
      settleFolioInput: {
        pmsPropertyId,
        pmsReservationId,
        folioMetadata
      }
    });
  };

  const functionCall = isRESTVersion ? settleFolioV1 : settleFolioV2;

  return [functionCall] as [typeof settleFolioV2];
};
