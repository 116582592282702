import { useEffect } from "react";
import type { FC } from "react";
import NProgress from "nprogress";
import { Box, useTheme } from "@mui/material";

const LoadingScreen: FC = () => {
  const theme = useTheme();

  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        minHeight: "100%"
      }}
    />
  );
};

export default LoadingScreen;
