import { Chip, Heading1, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Grid2, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { HelmetWrapper } from "../features/helmet-wrapper/helmet-wrapper";

interface PageTitleProps {
  subTitle: string;
  title: string;
  beta?: boolean;
}

const PageTitle: FC<PageTitleProps> = ({ subTitle, title, beta }) => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  return (
    <>
      <HelmetWrapper title={title} />
      <Grid2>
        <ParagraphSmall sx={{ color: theme.palette.text.secondary }} gutterBottom={true}>
          {subTitle}
        </ParagraphSmall>
        <Grid2
          container
          sx={{
            alignItems: "center"
          }}
        >
          <Grid2>
            <Heading1 sx={{ color: theme.palette.text.primary }}>{title}</Heading1>
          </Grid2>
          {beta && (
            <Grid2
              sx={{
                mx: 2
              }}
            >
              <Chip color="primary" label={t("labels__beta")} size="small" />
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </>
  );
};

export default PageTitle;
