import { Box, Grid2, useTheme } from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import { ModalWithPropActions } from "../../../../../components/submit-modal/modal-with-prop-actions";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import {
  Button,
  formatDateTime,
  Input,
  Paragraph,
  ParagraphBold,
  PaperCheckbox
} from "@likemagic-tech/sv-magic-library";
import { FolioPreview } from "../../../../../store/endpoints/reservation-table.endpoints";
import { PictureAsPdf } from "@mui/icons-material";
import { PricePreview } from "../../../../../components/price-preview/price-preview";
import { MultipleInput } from "../../../../../components/multiple-input/multiple-input";
import { useProperty } from "../../../../../hooks/use-property";
import { Form, Formik } from "formik";
import { useFormValidations } from "../../../../../hooks/use-form-validation";
import { Person } from "../../../../../domain/person";
import { useSendCustomNotificationMutation } from "../../../../../graphql/mutations/send-custom-notification.generated";
import { CommunicationChannel, NotificationType } from "../../../../../graphql/generated/graphql";
import { isFolioClosed } from "../../../payment/payment-utils";
import { validateEmail } from "../../../../../utils/email";
import { getI18nSelectedLanguage } from "src/utils/language";

interface SendInvoiceModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSuccess: () => void;
  folios: Array<FolioPreview>;
  primaryGuest: Person;
  reservationId: string;
}

interface SendInvoiceFormData {
  folios: Array<string>;
  sendTo: Array<string>;
  copySendTo: Array<string>;
  message: string;
}

export const SendInvoiceModal: FC<SendInvoiceModalProps> = ({
  isOpen,
  closeModal,
  folios,
  primaryGuest,
  reservationId,
  onSuccess
}) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const [action] = useSendCustomNotificationMutation();
  const language = getI18nSelectedLanguage();
  const theme = useTheme();

  const initialValues: SendInvoiceFormData = useMemo(() => {
    const firstFolioNumber =
      folios[0]?.closed ?? isFolioClosed(folios[0]) ? folios[0].id : folios[0]?.number;

    return {
      folios: firstFolioNumber ? [firstFolioNumber] : [],
      message: t("labels__email_template_send_invoice_" + selectedProperty?.propertyId, {
        property: selectedProperty?.details?.name ?? "",
        firstName: primaryGuest.firstName
      }),
      sendTo: primaryGuest.email ? [primaryGuest.email] : [],
      copySendTo: []
    };
  }, [t, primaryGuest.firstName, primaryGuest.email, folios, selectedProperty]);

  const handleSubmit = useCallback(
    (values: SendInvoiceFormData) =>
      action({
        pmsReservationId: reservationId,
        payload: {
          sendTos: values.sendTo,
          sendBCCs: values.copySendTo,
          template: NotificationType.Invoice,
          channel: CommunicationChannel.Email,
          customMessage: values.message.replaceAll("\n", "<br/>"),
          attachmentIds: values.folios
        }
      })
        .unwrap()
        .then(() => {
          onSuccess();
        }),
    [reservationId, action, onSuccess]
  );
  const { sendInvoiceFormValidation } = useFormValidations();

  return (
    <ModalWithPropActions
      isOpen={isOpen}
      onCancel={closeModal}
      title={t("labels__send_invoice")}
      content={
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={sendInvoiceFormValidation}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form>
              {folios.map((folio) => (
                <Box key={`folio-selection-${folio.id}`}>
                  <PaperCheckbox
                    name={`folios`}
                    value={isFolioClosed(folio) ? folio.id : folio.number}
                    content={
                      <Grid2
                        container
                        direction="row"
                        sx={{
                          alignContent: "center"
                        }}
                      >
                        <Grid2>
                          <Grid2
                            container
                            sx={{
                              alignContent: "center",
                              height: "100%"
                            }}
                          >
                            <Grid2>
                              <PictureAsPdf fontSize="large" />
                            </Grid2>
                          </Grid2>
                        </Grid2>
                        <Grid2
                          sx={{
                            ml: 1
                          }}
                        >
                          <ParagraphBold textAlign="left">
                            {t(isFolioClosed(folio) ? "labels__invoice" : "labels__folio")}{" "}
                            {folio.number}
                          </ParagraphBold>
                          <Paragraph sx={{ color: theme.palette.text.secondary }}>
                            <Box
                              component="span"
                              sx={{
                                mr: 0.5
                              }}
                            >
                              {formatDateTime(
                                folio.createdAt,
                                language,
                                selectedProperty?.details?.timeZone
                              )}
                              ,
                            </Box>
                            <PricePreview price={folio.balance} />
                          </Paragraph>
                        </Grid2>
                      </Grid2>
                    }
                    checked={
                      formik.values.folios.includes(folio.id) ||
                      formik.values.folios.includes(folio.number)
                    }
                    onChange={formik.handleChange}
                  />
                </Box>
              ))}

              <Box
                sx={{
                  mt: 1
                }}
              >
                <MultipleInput
                  value={formik.values.sendTo}
                  error={formik.errors.sendTo?.length ? formik.errors.sendTo.toString() : undefined}
                  setValue={(newValue) => {
                    formik.setFieldValue("sendTo", newValue);
                  }}
                  label={t("labels__send_to")}
                  validateItem={validateEmail}
                />
              </Box>

              <Box
                sx={{
                  mt: 2
                }}
              >
                <Input
                  name="message"
                  value={formik.values.message}
                  error={formik.errors.message}
                  onChange={formik.handleChange}
                  multiline
                  variant="outlined"
                  label={t("labels__message")}
                  minRows={8}
                />
              </Box>

              <Box
                sx={{
                  mt: 2
                }}
              >
                <MultipleInput
                  value={formik.values.copySendTo}
                  error={
                    formik.errors.copySendTo?.length
                      ? formik.errors.copySendTo.toString()
                      : undefined
                  }
                  setValue={(newValue) => {
                    formik.setFieldValue("copySendTo", newValue);
                  }}
                  label={t("labels__copy_send_to")}
                  validateItem={validateEmail}
                />
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  mt: 3
                }}
              >
                <Button
                  type="submit"
                  variant="primary"
                  fullWidth
                  disabled={!formik.values.folios.length}
                >
                  {t("labels__send_invoice")}
                </Button>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  mt: 2
                }}
              >
                <Button variant="secondary" fullWidth onClick={closeModal}>
                  {t("labels__cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      }
    />
  );
};
