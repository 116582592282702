import { Box, Grid2, useTheme } from "@mui/material";
import {
  formatTime,
  Heading2,
  ParagraphSmall,
  TenantContext
} from "@likemagic-tech/sv-magic-library";
import { FC, useCallback, useContext, useMemo } from "react";
import { getUnitType } from "../../../utils/tenant-unit-type";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { AssignmentIndOutlined, EscalatorWarningOutlined, Man } from "@mui/icons-material";
import { HousekeepingCardUnitStatus } from "./housekeeping-card-unit-status";
import { TaskDto, TaskType } from "../../../graphql-tasks/generated/graphql";
import { HousekeepingCardsTypeEnum } from "../housekeeping-cards-floor";
import { useUnitGroupById } from "../../../hooks/use-unit-group-by-id";
import { HousekeepingCardType } from "./housekeeping-card";
import { getI18nSelectedLanguage } from "src/utils/language";

interface HousekeepingCardDetailsProps {
  housekeepingCard: HousekeepingCardType;
  propertyId: string;
  housekeepingCardsType: HousekeepingCardsTypeEnum;
  futureDate: boolean;
}

export const HousekeepingCardDetails: FC<HousekeepingCardDetailsProps> = ({
  housekeepingCard,
  propertyId,
  housekeepingCardsType,
  futureDate
}) => {
  const { t } = useTranslationWrapper();
  const { theme: selectedTheme } = useContext(TenantContext);
  const theme = useTheme();
  const language = getI18nSelectedLanguage();

  const displayUnitName = useMemo(
    () =>
      housekeepingCard?.unit
        ? `${getUnitType(selectedTheme)} ${housekeepingCard?.unit?.name || ""}`
        : t("labels__unit_not_assigned"),
    [selectedTheme, housekeepingCard, t]
  );
  const unitGroup = useUnitGroupById(
    propertyId,
    housekeepingCard?.unit?.pmsUnitGroupId ??
      housekeepingCard?.reservation?.pmsUnitGroupId ??
      undefined
  );

  const task = useMemo(() => {
    switch (housekeepingCardsType) {
      case HousekeepingCardsTypeEnum.UNITS:
        return housekeepingCard?.unit?.housekeepingTasks?.find(
          (task) =>
            task.type === TaskType.DepartureCleaning || task.type === TaskType.StayoverCleaning
        );
      case HousekeepingCardsTypeEnum.RESERVATIONS:
        return housekeepingCard?.reservation?.housekeepingTasks?.find(
          (task) =>
            task.type === TaskType.DepartureCleaning || task.type === TaskType.StayoverCleaning
        );
      default:
        return undefined;
    }
  }, [housekeepingCard?.unit, housekeepingCard?.reservation, housekeepingCardsType]);

  const guestAmountInformation = useCallback((task: TaskDto | undefined) => {
    switch (task?.type) {
      case TaskType.StayoverCleaning:
        return {
          adultsAmount: task?.links?.reservations?.[0].adultsAmount,
          childrenAmount: task?.links?.reservations?.[0].childrenAmount
        };
      case TaskType.DepartureCleaning:
        return {
          adultsAmount: task?.links?.arrivingReservation?.adultsAmount,
          childrenAmount: task?.links?.arrivingReservation?.childrenAmount
        };
      default:
    }
  }, []);

  const departureInformation = useMemo(() => {
    switch (task?.type) {
      case TaskType.DepartureCleaning:
        return {
          departure: task?.links?.departingReservation?.departure
        };
      default:
    }
  }, [task?.links?.departingReservation?.departure, task?.type]);

  return (
    <Box>
      <Grid2
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Grid2>{housekeepingCard && <ParagraphSmall>{unitGroup.name}</ParagraphSmall>}</Grid2>
        <Grid2
          sx={{
            display: "flex"
          }}
        >
          <AssignmentIndOutlined fontSize="small" />
          <ParagraphSmall pl={1}>{t("labels__housekeeping")}</ParagraphSmall>
        </Grid2>
      </Grid2>
      <Grid2
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Heading2 sx={{ color: theme.palette.text.primary }}>{displayUnitName}</Heading2>
        {!futureDate && <HousekeepingCardUnitStatus housekeepingCard={housekeepingCard} />}
      </Grid2>
      <Grid2
        container
        sx={{
          justifyContent: "space-between"
        }}
      >
        <>
          <Grid2 size={{ xs: 6 }}>
            {housekeepingCard?.unit?.floor && (
              <ParagraphSmall>
                {t("labels__floor")} {housekeepingCard?.unit?.floor}
              </ParagraphSmall>
            )}
          </Grid2>
          {guestAmountInformation(task)?.adultsAmount && (
            <Grid2
              size={{ xs: 6 }}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              {guestAmountInformation(task)?.childrenAmount ? (
                <EscalatorWarningOutlined />
              ) : (
                <Man />
              )}
              <ParagraphSmall>
                {guestAmountInformation(task)?.adultsAmount}
                <span />
                {!!guestAmountInformation(task)?.childrenAmount &&
                  `+ ${guestAmountInformation(task)?.adultsAmount}`}
              </ParagraphSmall>
            </Grid2>
          )}
        </>
      </Grid2>
      {departureInformation?.departure && (
        <Grid2 container>
          <ParagraphSmall>
            {t("labels__departure_latest_at", {
              departure: formatTime(departureInformation?.departure, language) ?? ""
            })}
          </ParagraphSmall>
        </Grid2>
      )}
    </Box>
  );
};
