import { Grid2, useTheme } from "@mui/material";
import React, { FC, useContext, useMemo, useState } from "react";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import {
  Checkbox,
  Heading4,
  Input,
  NativeSelect,
  PhoneInput,
  TenantContext,
  useCountries,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { FieldArray, useFormikContext } from "formik";
import WarningIcon from "@mui/icons-material/Warning";
import { ManualWizardAddressForm } from "../../../../components/manual-wizard/manual-wizard-address-form";
import { RegistrationCardFormDataType } from "../../../../../../domain/registration-card";
import { useWizardGenderOptions } from "../../../../../../hooks/use-gender-options";
import { useProperty } from "../../../../../../hooks/use-property";
import { ManualCheckinFrequentFlyerForm } from "./manual-checkin-frequent-flyer-form";
import { SpecialAssistanceModal } from "./special-assistance-modal";

interface ManualCheckinMainGuestFormProps {
  disabled?: boolean;
}

export const ManualCheckinMainGuestForm: FC<ManualCheckinMainGuestFormProps> = ({ disabled }) => {
  const { t, exists } = useTranslationWrapper();
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } =
    useFormikContext<RegistrationCardFormDataType>();
  const { selectedProperty } = useProperty();
  const { genderOptions } = useWizardGenderOptions();
  const { preferredCountries } = useCountries({ propertyId: selectedProperty?.propertyId ?? "" });
  const theme = useTheme();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const { enabledCommunicationChannels } = useContext(TenantContext);
  const [isSpecialAssistanceModalOpen, setIsSpecialAssistanceModalOpen] = useState(false);

  const handleSpecialAssistanceCheckboxClick = (specialAssistance: boolean) => {
    if (!specialAssistance) {
      setIsSpecialAssistanceModalOpen(true);
    } else {
      setFieldValue("mainGuest.emergencyEvacuationHelpNeeded", false);
      setFieldValue("mainGuest.emergencyEvacuationHelpNotes", "");
    }
  };

  const channelOptions = useMemo(
    () =>
      enabledCommunicationChannels.map((value) => ({
        value: value,
        label: t(`labels__guest__communication__channel__${value}`)
      })),
    [t, enabledCommunicationChannels]
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 4, md: 2 }}>
        <NativeSelect
          items={genderOptions}
          id={"mainGuest.gender"}
          name={"mainGuest.gender"}
          value={values.mainGuest.gender}
          label={t("labels__wizard_gender")}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          disabled={disabled}
        />
      </Grid2>
      <Grid2 size={{ xs: 8, md: 4 }}>
        <Input
          id={"mainGuest.firstName"}
          name={"mainGuest.firstName"}
          value={values.mainGuest.firstName || ""}
          label={t("labels__wizard_first_name")}
          error={touched.mainGuest?.firstName && errors.mainGuest?.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <Input
          id={"mainGuest.lastName"}
          name={"mainGuest.lastName"}
          value={values.mainGuest.lastName || ""}
          label={t("labels__wizard_last_name")}
          error={touched.mainGuest?.lastName && errors.mainGuest?.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <Input
          id={"mainGuest.email"}
          name={"mainGuest.email"}
          value={values.mainGuest.email || ""}
          type="email"
          label={t("labels__wizard_email")}
          error={touched.mainGuest?.email && errors.mainGuest?.email}
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled={disabled}
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <PhoneInput
          id={"mainGuest.phone"}
          name={"mainGuest.phone"}
          value={values.mainGuest.phone || ""}
          label={t("labels__wizard_phone")}
          onChange={(newValue: string) => setFieldValue("mainGuest.phone", newValue)}
          onBlur={handleBlur}
          variant="standard"
          error={!!(touched.mainGuest?.phone && errors.mainGuest?.phone)}
          fullWidth
          helperText={touched.mainGuest?.phone && errors.mainGuest?.phone}
          placeholder={t("labels__wizard_phone")}
          autoComplete="nope"
          InputProps={{
            endAdornment: !!(touched.mainGuest?.phone && errors.mainGuest?.phone) && (
              <WarningIcon color="error" />
            )
          }}
          disabled={disabled}
          preferredCountries={preferredCountries}
        />
      </Grid2>
      {features?.licensePlateCheckinWizardEnabled && (
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Input
            id={"mainGuest.licensePlate"}
            name={"mainGuest.licensePlate"}
            value={values.mainGuest.licensePlate || ""}
            type="email"
            label={t("labels__wizard_license_plate")}
            error={touched.mainGuest?.licensePlate && errors.mainGuest?.licensePlate}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="standard"
            autoComplete="nope"
            disabled={disabled}
          />
        </Grid2>
      )}
      <Grid2 size={{ xs: 12, md: 6 }}>
        <Input
          id={"mainGuest.eClubMembershipNumber"}
          name={"mainGuest.eClubMembershipNumber"}
          value={values.mainGuest.eClubMembershipNumber || ""}
          label={t("labels__wizard_e_club_membership_number")}
          error={
            touched.mainGuest?.eClubMembershipNumber && errors.mainGuest?.eClubMembershipNumber
          }
          onChange={handleChange}
          onBlur={handleBlur}
          variant="standard"
          autoComplete="nope"
          disabled
        />
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <NativeSelect
          items={channelOptions}
          onChange={handleChange}
          value={values.mainGuest.preferredCommunicationChannel ?? ""}
          label={t("labels__wizard_communication_channel")}
          id="mainGuest.preferredCommunicationChannel"
          name="mainGuest.preferredCommunicationChannel"
          variant="standard"
          error={
            touched.mainGuest?.preferredCommunicationChannel &&
            errors.mainGuest?.preferredCommunicationChannel
          }
        />
      </Grid2>
      <FieldArray
        name="mainGuest.frequentFlyerPrograms"
        render={(props) => (
          <ManualCheckinFrequentFlyerForm
            push={props.push}
            remove={props.remove}
            form={props.form}
            handleBlur={handleBlur}
            disabled={disabled}
          />
        )}
      />
      <Grid2
        container
        sx={{
          pl: theme.spacing(1.5),
          mt: 2,
          width: "100%"
        }}
      >
        <Grid2 size={{ xs: 12 }}>
          <Checkbox
            id={"mainGuest.emergencyEvacuationHelpNeeded"}
            name={"mainGuest.emergencyEvacuationHelpNeeded"}
            checked={values.mainGuest.emergencyEvacuationHelpNeeded}
            onChange={() => {}}
            onClick={() =>
              handleSpecialAssistanceCheckboxClick(values.mainGuest.emergencyEvacuationHelpNeeded)
            }
            disabled={disabled}
            title={t("labels__special_assistance")}
            text={t("labels__require_special_assistance")}
          />
        </Grid2>
        {isSpecialAssistanceModalOpen && (
          <SpecialAssistanceModal
            onClose={() => setIsSpecialAssistanceModalOpen(false)}
            onSubmit={(inputValue: string) => {
              setFieldValue("mainGuest.emergencyEvacuationHelpNeeded", true);
              setFieldValue("mainGuest.emergencyEvacuationHelpNotes", inputValue);
              setIsSpecialAssistanceModalOpen(false);
            }}
          />
        )}
        {features?.loyaltyProgramEnabled && (
          <Grid2 size={{ xs: 12 }}>
            <Checkbox
              id={"mainGuest.enrolledInLoyaltyProgram"}
              name={"mainGuest.enrolledInLoyaltyProgram"}
              checked={values.mainGuest.enrolledInLoyaltyProgram}
              onChange={handleChange}
              disabled={disabled}
              title={
                exists(`labels__enrolled_loyalty_program_${selectedProperty.propertyId}`)
                  ? t(`labels__enrolled_loyalty_program_${selectedProperty.propertyId}`)
                  : t("labels__enrolled_loyalty_program")
              }
            />
          </Grid2>
        )}
      </Grid2>
      <Grid2
        size={{ xs: 12 }}
        sx={{
          mb: 2,
          mt: 4.25
        }}
      >
        <Heading4>{t("labels__wizard_address")}</Heading4>
      </Grid2>
      <ManualWizardAddressForm
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        genderOptions={genderOptions}
        disabled={disabled}
      />
    </Grid2>
  );
};
